import {makeStyles} from "@mui/styles";
import React, {useContext} from "react";
import styles from "./favoritesStyles";
import {Grid} from "@mui/material";
import {useHistory} from "react-router-dom";
import {getProductImage} from "components/shared/util/imageUtil";
import {isBrowser} from "react-device-detect";
import PlatformButton from "components/platform/platformButton";
import FavoritesContext from "./favoritesContext";

const useStyles = makeStyles(styles);

export default function FavoritesGridItem(props) {
    const {data} = props;
    const classes = useStyles();
    const history = useHistory();
    const context = useContext(FavoritesContext);

    const onItem = () => {
        history.push({
            pathname: `/catalog/item/${data.slug}`,
        })
    }

    const onRemove = () => {
        context.onDeleteFavorite(data.id);
    }

    return (
        <Grid item xs={isBrowser ? 4 : 6}>
            <div className={classes.favoritesGridItem}>
                <div onClick={onItem} style={{width: "100%", textAlign: "center"}}>
                    <img className={classes.favoritesItemImage} src={getProductImage(data)} alt="" />
                </div>
                <div className={classes.favoritesItemDetails}>
                </div>
                <div className={classes.favoritesItemName}>
                    {data.name}
                </div>
                <div className={classes.favoritesItemPrice}>
                    <PlatformButton size="sm" onClick={onRemove}>
                        Remove
                    </PlatformButton>
                </div>
            </div>
        </Grid>
    );
}