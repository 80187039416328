import React, {useContext} from "react";
import PlatformFormContext from "../../components/platform/platformFormContext";
import usePlatformGenericField from "../../components/hook/usePlatformGenericField";
import usePlatformForm from "../../components/hook/usePlatformForm";
import CheckoutContext from "./checkoutContext";

export const CheckoutShippingFormContext = React.createContext({});
export default CheckoutShippingFormContext;

export const CheckoutShippingFormContextProvider = (props) => {
    const {children} = props;
    const context = useContext(PlatformFormContext);
    const {refreshCart} = useContext(CheckoutContext);
    const {value, changeHandler} = usePlatformGenericField({ ...props, unbound: "false", context });

    usePlatformForm(200);

    const onChange = (optionType) => {
        changeHandler(optionType);

        setTimeout(() => {
            refreshCart();
        }, 150);
    }

    return (
        <CheckoutShippingFormContext.Provider
            value={{
                optionType: value,
                setOptionType: onChange
            }}
        >
            <>{children}</>
        </CheckoutShippingFormContext.Provider>
    );
}