import React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {isMobile} from 'react-device-detect';

const BackgroundBox = styled(Box)({
    overflowX: 'hidden', // Prevent horizontal overflow
});

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%', // Take full width of the Grid item
    minHeight: isMobile ? 0 : 325, // Fixed height for consistency
    // Remove margin to prevent extra spacing
}));

const FeatureIcon = styled('img')({
    height: '100px',
    margin: '25px 16px 0 16px',
});

const StyledCardContent = styled(CardContent)({
    textAlign: 'center',
});

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    paddingLeft: theme.spacing(isMobile ? 0 : 20),
    paddingRight: theme.spacing(isMobile ? 0 : 20),
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
}));

const featureData = [
    {
        icon: `${process.env.PUBLIC_URL}/images/ethos/GuaranteedSafety.jpg`,
        title: 'Guaranteed Safety',
        description: 'Full-panel testing for potency and all contaminants.',
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/ethos/ArtisanalQuality.jpg`,
        title: 'Artisanal Quality',
        description:
            'Handcrafted in our GMP-certified facility and infused for product consistency.',
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/ethos/Founded2018.jpg`,
        title: 'Founded 2018',
        description:
            "Boasting five years of pioneering experience in the cannabis industry, we've consistently led the way in innovation.",
    },
    {
        icon: `${process.env.PUBLIC_URL}/images/ethos/EthicallySourced.jpg`,
        title: 'Ethically Sourced',
        description:
            'Grown without chemical pesticides or fertilizers on our Texas farm.',
    },
];

export default function CatalogItemEthos() {
    return (
        <Box>
            <BackgroundBox>
                <StyledGridContainer
                    container
                    spacing={2} // Reduced spacing from 3 to 2
                    justifyContent="center"
                >
                    {featureData.map((feature, index) => (
                        <Grid
                            item
                            key={index}
                            xs={12}
                            sm={6}
                            md={3}
                            sx={{
                                display: 'flex',
                                alignItems: 'stretch', // Make all Grid items stretch to the same height
                            }}
                        >
                            <StyledCard>
                                <FeatureIcon src={feature.icon} alt={feature.title}/>
                                <StyledCardContent>
                                    <Typography variant="h6">{feature.title.toUpperCase()}</Typography>
                                    <Typography variant="body2">{feature.description}</Typography>
                                </StyledCardContent>
                            </StyledCard>
                        </Grid>
                    ))}
                    <img
                        src={`${process.env.PUBLIC_URL}/images/ethos/EthosIcons.png`}
                        alt="Ethos Icons"
                        style={{
                            width: '100%',
                            maxWidth: '400px', // Set a max-width to prevent it from getting too large on larger screens
                            height: 'auto',
                        }}
                    />
                </StyledGridContainer>
            </BackgroundBox>
        </Box>
    );
}
