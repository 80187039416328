import {Facebook, Instagram, Twitter, YouTube} from "@mui/icons-material";

export const SOCIAL = [
    {
        name: 'twitter',
        icon: Twitter,
        link: "https://twitter.com/greenbeltcbd"
    },
    {
        name: 'facebook',
        icon: Facebook,
        link: "https://facebook.com/greenbeltcbd"
    },
    {
        name: 'instagram',
        icon: Instagram,
        link: "https://instagram.com/greenbeltcbd"
    },
    {
        name: 'youtube',
        icon: YouTube,
        link: "https://youtu.be/greenbeltcbd"
    }
];
