import * as React from 'react';
import {useContext} from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import useLayoutOptions from "../../../components/hook/useLayoutOptions";
import OrderReceiptMap from "./orderReceiptMap";
import OrderReceiptDetails from "./orderReceiptDetails";
import OrderReceiptSummary from "./orderReceiptSummary";
import OrderReceiptContext from "./orderReceiptContext";
import PlatformSpace from "../../../components/platform/platformSpace";
import OrderReceiptHeader from "./orderReceiptHeader";
import OrderReceiptControls from "./orderReceiptControls";

export default function OrderReceiptHomeDesktop(props) {
    const {order} = useContext(OrderReceiptContext);

    useLayoutOptions({
        headerComponent: null,
        footerComponent: null
    });

    return (
        <PaneContainer direction="row">
            {(order && order.shippingAddress) &&
                <React.Fragment>
                    <LeftPane>
                        <OrderReceiptHeader/>
                        <PlatformSpace height={30}/>
                        <OrderReceiptMap/>
                        <OrderReceiptDetails/>
                        <PlatformSpace height={30}/>
                        <OrderReceiptControls/>
                    </LeftPane>
                    <RightPane>
                        <OrderReceiptSummary/>
                    </RightPane>
                </React.Fragment>
            }
        </PaneContainer>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    height: "100vw"
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    height: "100vh"
}));