import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CheckoutContextProvider} from "./checkoutContext";
import CheckoutHome from "./checkoutHome";
import {PlatformFormContextProvider} from "../../components/platform/platformFormContext";

export default function CheckoutIndex(props) {
    return (
        <PublicLayout>
            <PlatformFormContextProvider name="checkoutForm">
                <CheckoutContextProvider>
                    <CheckoutHome/>
                </CheckoutContextProvider>
            </PlatformFormContextProvider>
        </PublicLayout>
    );
}