import React from 'react';
import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PlatformMarginBox from "../../platform/platformMarginBox";
import {isMobile} from "react-device-detect";

const StyledImageBox = styled(Box)(({ theme }) => ({
    width: isMobile ? "92vw" : "26vw",
    height: '100%',
    borderRadius: '20px',
    marginLeft: 'auto', // Right-aligns the image
    marginRight: theme.spacing(2),
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    px: 2,
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 255, 0, 0.5)', // Example background color
}));

export default function BlogWithMoodBanner({ title, subtitle, image }) {
    return (
        <PlatformMarginBox>
            <Box flex={1}>
                <Typography variant="h2" gutterBottom
                            sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}>
                    {title}
                </Typography>
                <Typography variant="body1">
                    {subtitle}
                </Typography>
            </Box>
            <StyledImageBox>
                <img
                    src={image}
                    alt="Cannabis leaves"
                    style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                />
                {/*<ImageOverlay>*/}
                {/*    <Typography variant="h5">*/}
                {/*        Uncover New Possibilities*/}
                {/*    </Typography>*/}
                {/*</ImageOverlay>*/}
            </StyledImageBox>
        </PlatformMarginBox>
    );
};
