export function getProductImage(product) {
  if (product === undefined || !product || !product.images || product.images.length === 0) {
    return `${process.env.PUBLIC_URL}/images/pixel.png`;
  }
  return `${process.env.PUBLIC_URL}${product.images[0].imageUrl}`;
}

export function getProductImageWithIndex(product, idx) {
  if (product === undefined || !product || !product.images || product.images.length === 0) {
    return `${process.env.PUBLIC_URL}/images/pixel.png`;
  }
  return `${process.env.PUBLIC_URL}${product.images[idx].imageUrl}`;
}