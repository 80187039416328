import React from 'react';
import {Box, Typography} from '@mui/material';
import PublicLayout from "../../../components/layout/publicLayout";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";

export default function BlogWhatMakesGreenbeltQuality() {
    return (
        <PublicLayout>
            <BlogWithMoodBanner
                title="WHAT MAKES GREENBELT HIGH QUALITY?"
                image={`${process.env.PUBLIC_URL}/images/blog/WhatMakesGreenbeltQuality.jpg`}
            />
            <PlatformSpace height={isMobile ? 30 : 60}/>
            <PlatformMarginBox>
                <Box flex={1}>
                    <Typography variant="body1" gutterBottom>
                        Pick your preferred strain and join us as we delve into what makes Greenbelt's 100% federally legal THC the best in its class.
                        At the core of our success is the Quality Assurance (QA) process, which operates with the precision of a well-orchestrated symphony, ensuring each aspect of our product resonates with excellence. From our top-notch flower to our flavorful gummies to our handcrafted in-house edibles, discover why Greenbelt truly stands out.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >Our Growers</Typography>
                    <Typography variant="body1">
                        We begin at the foundation: Greenbelt's growers, the often overlooked champions of the cannabis industry. We take pride in sourcing our flowers from small-scale American farmers with extensive experience. Greenbelt's growers focus on more than just volume; they've honed their proprietary strains through years of selective breeding, working harmoniously with nature to produce standout cannabis that transcends the average, mass-produced varieties. The magic lies in their selective breeding for high THCa and precise terpene and cannabinoid profiles, including CBD, CBG, CBC, and CBN, enabling us to create our signature moods by aligning with the plant's natural properties.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >Our Flower</Typography>
                    <Typography variant="body1">
                        Greenbelt's flowers set a new standard, far removed from the generic offerings at local shops. These flowers come from the small American farmers we've partnered with, who blend art, science, and passion to consistently yield exceptional cannabis. Once we receive the flowers, our exhaustive QA process kicks in. It starts with a burn test to verify the perfect curing by the growers, producing clean, white ash. Every bud is then scrutinized by hand, with a second round of hand-trimming to ensure that customers receive pure flower, free from unnecessary stems or leaves. We check for aroma and moisture levels, re-curing slightly dry batches to achieve optimal humidity. Batches that are too dry or contain seeds are outright rejected. Only flowers that meet our strict standards are dispatched.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >Our Gummies</Typography>
                    <Typography variant="body1">
                        Transitioning to our delicious gummies, Greenbelt doesn't settle for the typical subpar extracts found elsewhere. Our gummies are crafted from premium extracts that are not only potent but also rich in flavor and free from any bitterness. These extracts are sourced from cannabis processors who prioritize sun-grown, sustainable, and legal cultivation practices without pesticides. Greenbelt works directly with a cGMP-certified manufacturer, employing multiple PhDs to guarantee the chemistry, flavor, consistency, and quality remain impeccable. Our inspection doesn't stop when the gummies reach our facilities. Every attribute, from color and consistency to texture and form, is rigorously examined to meet Greenbelt's exacting standards. And here’s something special: our packaging is all done by hand. No automated machinery here; we treat our products with the care of artisanal crafts, ensuring each gummy shipped meets our high expectations.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >In-house Edibles</Typography>
                    <Typography variant="body1">
                        And let’s not overlook our in-house edibles. Far from the mundane offerings of typical store bakeries, we craft our batches with a personal touch, reminiscent of homemade treats but with an innovative twist. We select our ingredients meticulously, working only with highly trusted suppliers. Our team includes a PhD food scientist and a chocolatier named Andy, who has been perfecting his craft for over 15 years. Crafting Greenbelt’s in-house edibles involves extra care and attention. Imagine butter carefully browned to accentuate its caramel and nutty essences, and dough that matures for 24 hours before baking, allowing the flavors to blend to perfection.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >Third Party Testing</Typography>
                    <Typography variant="body1">
                        Lab Testing: Every product undergoes comprehensive lab testing to ensure it complies with legal standards and is free from harmful elements like mold or heavy metals. This process is vital not only for compliance but for maintaining the highest quality across all Greenbelt products.
                    </Typography>
                    <PlatformSpace height={15}/>
                    <Typography variant="body1">
                        Certificates of Analysis (COAs): Let’s discuss COAs, the definitive measure in the cannabis industry that verifies a product's potency and purity. Issued by accredited labs, COAs detail levels of cannabinoids and screen for pesticides, mold, microbes, and other impurities. This critical step confirms each product not only meets federal standards but is also safe for use. Greenbelt views COAs as essential, reflecting our commitment to transparency and safety.
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 60}/>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}} >Our Quality Tiers</Typography>
                    <Typography variant="body1">
                        Envision a place where your budget and preferences align seamlessly. Greenbelt offers three quality tiers to cater to varying needs.
                    </Typography>
                    <PlatformSpace height={15}/>
                    <Typography variant="body1">
                        Economy Tier: Robust, affordable cannabis. It might lack glamour, but it delivers effectively. Grown in modest outdoor plots or large greenhouses, it's perfect for everyday use.
                    </Typography>
                    <PlatformSpace height={15}/>
                    <Typography variant="body1">
                        Premium Tier: Elevating the standard with larger buds, brighter colors, and more trichomes. Produced in smaller greenhouses, this tier appeals to those seeking a little extra flair in their cannabis.
                    </Typography>
                    <PlatformSpace height={15}/>
                    <Typography variant="body1">
                        Top Shelf Tier: The ultimate in luxury cannabis. Cultivated in highly specialized greenhouses or indoor environments, these buds are laden with trichomes and provide a premium experience, rivaling the finest dispensary selections.
                    </Typography>
                </Box>
            </PlatformMarginBox>
            <PlatformSpace height={isMobile ? 30 : 60}/>
        </PublicLayout>
    );
};