import React from "react";
import {isDesktop, isMobile} from "react-device-detect";
import OrderStatusMobile from "./orderStatusMobile";
import OrderStatusDesktop from "./orderStatusDesktop";

export default function OrderStatus(props) {
    return (
        <React.Fragment>
            {isMobile && <OrderStatusMobile/>}
            {isDesktop && <OrderStatusDesktop/>}
        </React.Fragment>
    );
}
