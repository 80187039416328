import React from "react";
import PublicLayout from "../../components/layout/publicLayout";
import CartItems from "./cartItems";
import CartTotals from "./cartTotals";
import PlatformSpace from "../../components/platform/platformSpace";
import {isMobile} from "react-device-detect";
import {Stack} from "@mui/material";
import CartHeader from "./cartHeader";

export default function CartHome(props) {

    return (
        <PublicLayout>
            <Stack justifyContent="flex-start" alignItems="center">
                <div style={{width: isMobile ? "88vw" : "60vw"}}>
                    <CartHeader/>
                    <PlatformSpace height={50}/>
                    <CartItems />
                    <PlatformSpace height={10}/>
                    <CartTotals/>
                </div>
            </Stack>
            <PlatformSpace height={50}/>
        </PublicLayout>
    );
}