import * as React from 'react';
import {useContext} from 'react';
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import Grid from "@mui/material/Grid";
import {formatPhoneNumber} from "../../../components/util/addressUtil";
import OrderReceiptContext from "./orderReceiptContext";
import formatToUSD from "../../../components/util/moneyUtil";

export default function OrderReceiptDetails(props) {
    const {order} = useContext(OrderReceiptContext);

    return (
        <React.Fragment>
            <TrackWithContainer>
                <div>
                    <Typography variant="body1">
                        Your order is confirmed
                    </Typography>
                </div>
                <PlatformSpace height={5}/>
                <div>
                    <Typography variant="body2" sx={{fontWeight: 500, opacity: 0.7}}>
                        You'll receive a confirmation email with your order number shortly.
                    </Typography>
                </div>
            </TrackWithContainer>
            <PlatformSpace height={30}/>
            <DetailsContainer>
                <div>
                    <Typography variant="body1">
                        Order details
                    </Typography>
                </div>
                <PlatformSpace height={30}/>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body3" sx={{fontWeight: 500}}>
                                Contact information
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <DetailsText>
                                {order.email}
                            </DetailsText>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body3" sx={{fontWeight: 500}}>
                                Payment method
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <DetailsText>
                                ending with **** - <strong>{formatToUSD(order.orderTotalAmount)}</strong>
                            </DetailsText>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body3" sx={{fontWeight: 500}}>
                                Shipping address
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <AddressComponent address={order.shippingAddress}/>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body3" sx={{fontWeight: 500}}>
                                Billing address
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <AddressComponent address={order.billingAddress}/>
                        </div>
                    </Grid>
                </Grid>
            </DetailsContainer>
        </React.Fragment>
    )
}

const AddressComponent = ({ address }) => {
    const formattedName = `${address.firstName} ${address.lastName}`;
    const formattedPhone = formatPhoneNumber(address.phone);

    return (
        <DetailsText>
            {formattedName && <div>{formattedName}</div>}
            {address.street1 && <div>{address.street1}</div>}
            {address.street2 && <div>{address.street2}</div>}
            {address.street3 && <div>{address.street3}</div>}
            {address.city && address.state && address.zip &&
                <div>{`${address.city} ${address.state} ${address.zip}`}</div>}
            {address.country && <div>{"United States"}</div>}
            {formattedPhone && <div>{formattedPhone}</div>}
        </DetailsText>
    );
};

const DetailsText = styled("div")(({ theme }) => ({
    fontWeight: 500,
    opacity: 0.7,
    fontFamily: "Poppins",
    fontSize: "0.9rem"
}));

const TrackWithContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid rgba(59,42,84, 0.3)",
    borderRadius: "0 0 12px 12px",
    padding: 20
}));

const DetailsContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid rgba(59,42,84, 0.3)",
    borderRadius: 12,
    padding: 20
}));