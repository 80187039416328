import React from 'react';
import PublicLayout from "../../../components/layout/publicLayout";
import ContactHome from "./contactHome";

export default function ContactIndex() {

    return (
      <PublicLayout>
          <ContactHome/>
      </PublicLayout>
    );
}