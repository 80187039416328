import React from 'react';
import {Stack, Typography} from '@mui/material';
import {isMobile} from "react-device-detect";
import {styled} from "@mui/styles";
import PlatformMarginBox from "../../components/platform/platformMarginBox";

export default function CheckoutDisclaimer() {
    return (
        <PlatformMarginBox>
            <Stack spacing={2} direction={isMobile ? "column" : "row"}
                   justifyContent="flex-end">
                <Typography variant="body5">
                    <FooterLink variant="body3">
                        The statements made regarding these products have not been evaluated by the Food and
                        Drug Administration. The efficacy of these products has not been confirmed by
                        FDA-approved research. These products are not intended to diagnose, treat, cure or
                        prevent any disease.
                    </FooterLink>
                </Typography>
                <Typography variant="body5">
                    <FooterLink variant="body3">
                        Delta 8 - This product is not available for shipment to the following states: Alaska,
                        Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Hawaii, Idaho, Iowa,
                        Massachusetts, Michigan, Minnesota, Mississippi, Montana, Nevada, New Hampshire, New York,
                        North Dakota, Oregon, Rhode Island, Utah, Vermont, Virginia, Washington, West Virginia
                    </FooterLink>
                </Typography>
                <Typography variant="body5">
                    <FooterLink variant="body3">
                        THCA - This product is not available for shipment to the following states: Arkansas,
                        Hawaii, Idaho, Kansas, Louisiana, Oklahoma, Oregon, Rhode Island, Utah, Vermont
                    </FooterLink>
                </Typography>
            </Stack>
        </PlatformMarginBox>
    );
};

const FooterLink = styled(Typography)(({ theme }) => ({
    fontSize: "0.7rem !important",
    cursor: "pointer",
    fontWeight: 300,
    opacity: 0.4
}));