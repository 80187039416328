export default function toTitleCase(text) {
    if (!text) return '';

    // Handle the special exception for "pre-rolls"
    if (text.toLowerCase() === 'pre-rolls') return 'Pre-rolls';

    text = text.toLowerCase(); // Convert entire text to lowercase
    text = text.replace(/[-_]/g, ' '); // Replacing all "-" and "_" with " "
    text = text.replace(/\b\w/g, (char) => char.toUpperCase()); // Converting first letter of each word to uppercase
    return text;
}

export function truncateAfterNCharacters(str, n, addEllipsis = false) {
    if (!str) return str;
    if (str.length <= n) return str; // String is shorter than the limit, return original string

    let truncated = str.substring(0, n);

    // Find the last space within the truncated string
    let lastSpaceIndex = truncated.lastIndexOf(' ');

    // If a space is found, truncate at that space, otherwise, return the truncated string as is
    if (lastSpaceIndex !== -1) {
        truncated = truncated.substring(0, lastSpaceIndex);
    }

    // Add ellipsis if the third parameter is true
    if (addEllipsis) {
        truncated += '...';
    }

    return truncated;
}

export function convertToHyphenated(str) {
    return str.replace(/\s+/g, '-'); // Replace whitespace with hyphens
}

export function fromCategoryHandle(category) {
    return category.toLowerCase().replace(/_/g, '-');
}

export function toCategoryHandle(categoryHandle) {
    return categoryHandle.replace(/[-\s]/g, '_').toLowerCase();
}

export function splitCreditCardDate(dateString) {
    // Validate the input format
    if (!/^(\d{2})\/(\d{2})$/.test(dateString)) {
        return { error: "Invalid date format. Expected MM/YY." };
    }

    // Extract the month and year using destructuring
    const [month, year] = dateString.split("/");

    return {
        month,
        year,
    };
}