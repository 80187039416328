import {createTheme} from '@mui/material/styles';
import {isMobile} from 'react-device-detect';

const custom = {
  primaryTextColor: "rgba(9,45,33,0.9)",
  primaryTextInverseColor: "rgba(255,255,255,0.9)",
  pageMargins: isMobile ? "0 15px 0 15px" : "0 80px 0 80px",
  pageWidth: "100vw"
};

// Step 1: Create a base theme
const baseTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(118,58,199,1)",
    },
    secondary: {
      main: "rgb(72,8,111)",
    },
    text: {
      main: "rgba(67,66,68,0.9)",
      lightgrey: "rgba(112, 122, 131, 0.85)"
    },
    customColor: {
      light: '#ffa726',
      main: '#fb8c00',
      dark: '#ef6c00',
      contrastText: '#fff',
    },
  },
  custom,
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    }
  },
  shape: {
    borderRadius: 20,
  }
});

// Step 2: Create a new theme using the base theme
const theme = createTheme(baseTheme, {
  typography: {
    allVariants: {
      fontFamily: "BNDimeDisplay !important",
      letterSpacing: '0.03em',
    },
    body0: {
      fontWeight: 700,
      fontSize: "1.2rem",
      color: baseTheme.palette.text.main,
      fontFamily: "Poppins !important",
    },
    body1: {
      fontWeight: 700,
      fontSize: "1.1rem",
      color: baseTheme.palette.text.main,
      fontFamily: "Poppins !important",
    },
    body2: {
      fontWeight: 700,
      fontSize: "1rem",
      color: baseTheme.palette.text.main,
      fontFamily: "Poppins !important",
    },
    body3: {
      fontWeight: 500,
      fontSize: "0.9rem",
      color: baseTheme.palette.text.main,
      fontFamily: "Poppins !important",
    },
    body4: {
      fontWeight: 500,
      fontSize: "0.8rem",
      color: baseTheme.palette.text.main,
      fontFamily: "Poppins !important",
    },
    body5: {
      fontWeight: 500,
      color: baseTheme.palette.text.main,
      fontSize: "0.7rem",
      fontFamily: "Poppins !important",
    },
    h1: {
      fontWeight: 700,
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    h2: {
      fontWeight: 700,
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    h3: {
      fontWeight: 700,
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    h4: {
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    h5: {
      fontWeight: 700,
      fontSize: 32,
      textTransform: "none",
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.06em',
      color: baseTheme.palette.primary.main,
    },
    h6: {
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.04em',
      color: baseTheme.palette.primary.main,
    },
    h7: {
      fontWeight: 600,
      textTransform: "none",
      fontSize: "1.3rem",
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    h8: {
      fontWeight: 600,
      textTransform: "none",
      fontSize: "1.1rem",
      fontFamily: "BNDimeDisplay",
      letterSpacing: '0.03em',
      color: baseTheme.palette.primary.main,
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      color: baseTheme.palette.secondary.main,
      letterSpacing: 0,
      fontSize: "1.1rem"
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          color: "rgb(112, 122, 131) !important",
          fontWeight: 400
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(255, 167, 38, 0.1) !important"
          },
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "20px"
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        container: {
          transitionDuration: 0
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
          color: "black",
          boxShadow: "none",
          "&.Mui-expanded": {
            marginTop: "0 !important"
          },
        }
      }
    },
    PrivateSwitchBase: {
      styleOverrides: {
        input: {
          color: baseTheme.palette.secondary.main, // Customize as needed
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: baseTheme.palette.secondary.main
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "5px !important"
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgb(59,42,84)",
          marginTop: 15,
          marginBottom: 15
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          "&.Mui-expanded": {
            minHeight: "48px !important"
          },
        },
        content: {
          color: baseTheme.palette.secondary.main,
          fontWeight: "600 !important",
          fontSize: "1rem !important",
          margin: 0,
          "&.Mui-expanded": {
            margin: "0 !important",
          },
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: 8,
          fontWeight: 900,
          fontSize: "1rem",
          padding: 8,
          fontFamily: "Poppins !important",
          color: custom.primaryTextInverseColor,
          backgroundColor: baseTheme.palette.secondary.main, // Use baseTheme here as well
          '&:hover': {
            backgroundColor: baseTheme.palette.primary.dark, // Use a darker primary color on hover
          },
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          padding: 0,
          border: "4px solid rgb(41, 31, 55)",
          borderRadius: 12,
          '& .MuiSvgIcon-root': {
            color: baseTheme.palette.secondary.main
          },
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
          color: "black"
        },
        root: {
          marginTop: "0 !important",
          marginBottom: "0 !important"
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "1rem",
          backgroundColor: "rgb(59,42,84)",
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.secondary.main,
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "rgb(172,186,197)"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: baseTheme.palette.primary.main,
            transition: "color .3s ease-out",
          },
          color: "rgb(112, 122, 131)",
          //color: "rgba(227,204,255,0.56)",
          fontWeight: 600,
          fontSize: "1.6rem !important",
          "&.Mui-selected": {
            transition: "color .3s ease-out",
            color: "black",
          },
        },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.secondary.main
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "0 !important",
        },
        root: {
          padding: 0,
          borderRadius: 8,
          backgroundColor: "rgba(15, 81, 53, 0.075)"
        },
        input: {
          borderRadius: 8,
          padding: "10px !important",
          fontWeight: 600,
          fontFamily: "Poppins",
        }
      }
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins"
        }
      }
    }
  },
});

export default theme;
