import * as React from 'react';
import {useContext} from 'react';
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import OrderStatusContext from "./orderStatusContext";
import PlatformTextField from "../../../components/platform/platformTextField";
import PlatformButton from "../../../components/platform/platformButton";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformFormContext from "../../../components/platform/platformFormContext";
import {isMobile} from "react-device-detect";

export default function OrderStatusLookup(props) {
    const {onOrderStatus} = useContext(OrderStatusContext);
    const {setForm} = useContext(PlatformFormContext);

    return (
        <React.Fragment>
            <div style={{margin: "auto", width: "70%"}}>
                <PlatformSpace height={isMobile ? 70 : 100}/>
                <Typography variant="h3" textAlign="center">
                    Find your order
                </Typography>
            </div>
            <PlatformSpace height={30}/>
            <OrderStatusContainer justifyContent="center" alignItems="center">
                <Stack justifyContent="center" alignItems="center" sx={{width: 300}}>
                    <Typography variant="h7" textAlign="center">
                        Look it up with your order number.
                    </Typography>
                    <PlatformSpace height={30}/>
                    <Stack justifyContent="center" alignItems="center" spacing={2} sx={{width: 300}}>
                        <PlatformTextField
                            name="orderNumber"
                            placeholder="Order number"
                            required
                            fullWidth
                        />
                        <PlatformTextField
                            name="email"
                            placeholder="Email address"
                            email
                            required
                            fullWidth
                        />
                        <PlatformSpace height={10}/>
                        <PlatformButton size="lg" onClick={onOrderStatus}>
                            Continue
                        </PlatformButton>
                    </Stack>
                </Stack>
            </OrderStatusContainer>
            <PlatformSpace height={100}/>
        </React.Fragment>
    )
}

const OrderStatusContainer = styled(Stack)(({ theme }) => ({
    paddingRight: 10,
    maxWidth: "100%",
    width: "100%"
}));

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
    height: "100vw"
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    height: "100vh"
}));