const loginStyles = {
    root: {
        backgroundColor: "#c4dc9d"
    },
    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
        textTransform: "none"
    },
    publicMenu: {},
    publicMenuAppBar: {
        backgroundColor: "#c4dc9d", //"#8249dc",//7221f5=purple   21f5c3=green
        color: "black"
    },
    backgroundImage: {
        backgroundImage: "radial-gradient(ellipse at center,rgba(0,0,0,0) 0%,#f3f3f3 90%),url(/assets/558d99b6130752eaf4bb73e4d2b8505epng)"
    },
    publicToolbar: {
        color: "white",
        minHeight: 0,
        height: 65,
        paddingLeft: "15%",
        paddingRight: "10%"
    },
}

export default loginStyles;