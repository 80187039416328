import * as React from 'react';
import {Box, Container, Typography} from '@mui/material';
import PlatformSpace from "../../../components/platform/platformSpace";
import {ORGANIZATION} from "../../../components/constants/organization";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";

export default function TermsOfServiceHome(props) {

    return (
        <PlatformMarginBox>
            <Container sx={{margin: 1}}>
                <Typography variant="h2" gutterBottom>
                    Terms of Service
                </Typography>

                {/* Section 1 - Online Store Terms */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 1 - Online Store Terms
                    </Typography>
                    <Typography paragraph>
                        By agreeing to these Terms of Use, you confirm that you are at least the legal smoking age in your state, city, province, or country, whichever is older. Our Services are intended solely for users who meet this age requirement. Any registration, use, or access of the Services by anyone under this age is unauthorized, unlicensed, and in violation of these Terms of Use. We use a third-party age verification system to verify that users are of legal age, which may include collecting photo IDs. All such data is held for up to 30 days and then destroyed.
                    </Typography>
                </Box>

                {/* Section 2 - General Conditions */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 2 - General Conditions
                    </Typography>
                    <Typography paragraph>
                        We reserve the right to refuse service to anyone for any reason at any time. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Service, or access to the Services or any contact on the website through which the Service is provided, without express written permission by us.
                    </Typography>
                </Box>

                {/* Section 3 - Accuracy, Completeness and Timeliness of Information */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 3 - Accuracy, Completeness, and Timeliness of Information
                    </Typography>
                    <Typography paragraph>
                        We are not responsible if information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions.
                    </Typography>
                </Box>

                {/* Section 4 - Modifications to the Service and Prices */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 4 - Modifications to the Service and Prices
                    </Typography>
                    <Typography paragraph>
                        Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.
                    </Typography>
                </Box>

                {/* Section 5 - Products or Services */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 5 - Products or Services
                    </Typography>
                    <Typography paragraph>
                        Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to our Return Policy.
                    </Typography>
                </Box>

                {/* Section 6 - Accuracy of Billing and Account Information */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 6 - Accuracy of Billing and Account Information
                    </Typography>
                    <Typography paragraph>
                        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order.
                    </Typography>
                </Box>

                {/* Section 7 - Optional Tools */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 7 - Optional Tools
                    </Typography>
                    <Typography paragraph>
                        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                    </Typography>
                </Box>

                {/* Section 8 - Third-Party Links and Service Providers */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 8 - Third-Party Links and Service Providers
                    </Typography>
                    <Typography paragraph>
                        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites.
                    </Typography>
                </Box>

                {/* Section 9 - User Comments, Feedback, and Other Submissions */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 9 - User Comments, Feedback, and Other Submissions
                    </Typography>
                    <Typography paragraph>
                        If, at our request, you send certain specific submissions or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, we may, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are under no obligation to maintain any comments in confidence, to pay compensation for any comments, or to respond to any comments.
                    </Typography>
                </Box>

                {/* Section 10 - Personal Information */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 10 - Personal Information
                    </Typography>
                    <Typography paragraph>
                        Your submission of personal information through the store is governed by our Privacy Policy. For more detail, please review our Privacy Policy.
                    </Typography>
                </Box>

                {/* Section 11 - Errors, Inaccuracies, and Omissions */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 11 - Errors, Inaccuracies, and Omissions
                    </Typography>
                    <Typography paragraph>
                        Occasionally there may be information on our site or in the Services that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times, and availability.
                    </Typography>
                </Box>

                {/* Section 12 - Prohibited Uses */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 12 - Prohibited Uses
                    </Typography>
                    <Typography paragraph>
                        You are prohibited from using the site or its content for any unlawful purpose; to solicit others to perform or participate in any unlawful acts; to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; to infringe upon or violate our intellectual property rights or the intellectual property rights of others.
                    </Typography>
                </Box>

                {/* Section 13 - Disclaimer of Warranties; Limitation of Liability */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 13 - Disclaimer of Warranties; Limitation of Liability
                    </Typography>
                    <Typography paragraph>
                        The Services and all information, content, materials, products and other services included on or otherwise made available to you through the site are provided on an "as is" and "as available" basis. We do not warrant that the Services, information, content, materials, products or other services included on or otherwise made available to you through the site, our servers or electronic communications sent from us are free of viruses or other harmful components.
                    </Typography>
                </Box>

                {/* Section 14 - Indemnification */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 14 - Indemnification
                    </Typography>
                    <Typography paragraph>
                        You agree to indemnify, defend and hold harmless Greenbelt Botanicals and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third-party due to or arising out of your use of the Services or your breach of these Terms of Use, or your violation of any law or the rights of a third-party.
                    </Typography>
                </Box>

                {/* Section 15 - Severability */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 15 - Severability
                    </Typography>
                    <Typography paragraph>
                        In the event that any provision of these Terms of Use is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Use. This shall not affect the validity and enforceability of any other remaining provisions.
                    </Typography>
                </Box>

                {/* Section 16 - Termination */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 16 - Termination
                    </Typography>
                    <Typography paragraph>
                        These Terms of Use are effective unless and until terminated by either you or us. You may terminate these Terms of Use at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. We may also terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services.
                    </Typography>
                </Box>

                {/* Section 17 - Entire Agreement */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 17 - Entire Agreement
                    </Typography>
                    <Typography paragraph>
                        These Terms of Use and any policies or operating rules posted by us on this site or in respect to the Services constitute the entire agreement and understanding between you and us and govern your use of the Services, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use). Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.
                    </Typography>
                </Box>

                {/* Section 18 - Governing Law */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 18 - Governing Law
                    </Typography>
                    <Typography paragraph>
                        These Terms of Use and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Texas, without regard to its conflict of law provisions.
                    </Typography>
                </Box>

                {/* Section 19 - Changes to Terms of Use */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 19 - Changes to Terms of Use
                    </Typography>
                    <Typography paragraph>
                        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Use by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Services following the posting of any changes to these Terms of Use constitutes acceptance of those changes.
                    </Typography>
                </Box>

                {/* Section 20 - Contact Information */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 20 - Contact Information
                    </Typography>
                    <Typography paragraph>
                        Questions about the Terms of Use should be sent to us at {ORGANIZATION.supportEmail}.
                    </Typography>
                </Box>

                {/* Section 21 - Shipping Restrictions */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 21 - Shipping Restrictions
                    </Typography>
                    <Typography paragraph>
                        Some products can’t be shipped to all geographical locations due to warranty issues, manufacturer requirements, legal and other restrictions. You’ll be notified when placing your order if we’re unable to ship specific items to the address you’ve indicated. Please also check the product detail pages for any item-specific shipping restrictions. We reserve the right to cancel any shipment, in its sole discretion, and issue a full refund.
                    </Typography>
                </Box>

                {/* Section 22 - Risk of Loss */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 22 - Risk of Loss
                    </Typography>
                    <Typography paragraph>
                        All items purchased from us are made pursuant to a shipment contract. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier. We are not responsible for items lost in transit, items sent to the wrong address, products seized by customs or governmental agencies nor international fees charged by your country or third parties.
                    </Typography>
                </Box>

                {/* Section 23 - Force Majeure */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 23 - Force Majeure
                    </Typography>
                    <Typography paragraph>
                        You agree not to hold us liable for any disruption of our service or failure to deliver our product for any reason beyond our control, including, but not limited to, acts of God, pandemic, epidemic, change in law, law enforcement action, orders of government, natural disasters, war, insurrection, terrorism, riots, criminal acts, labor shortages, labor strikes (both lawful and unlawful), postal service or courier service disruption, infrastructure disruption, communication failure, material shortages, adverse weather events or disasters, or any other circumstance that may be beyond our control or make performance of your order impossible.
                    </Typography>
                </Box>

                {/* Section 24 - Taxes */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 24 - Taxes
                    </Typography>
                    <Typography paragraph>
                        Greenbelt Botanicals shall automatically charge and withhold the applicable sales tax for orders to be delivered to addresses within the same state. For orders shipped to other states or countries, you are solely responsible for all sales taxes, customs, duties, or other taxes.
                    </Typography>
                </Box>

                {/* Section 25 - Consult a Physician */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 25 - Consult a Physician
                    </Typography>
                    <Typography paragraph>
                        The information found on our website is for informational and educational purposes only. As with any health supplement, we recommend that you consult a physician or doctor before using any of our products. You should always speak with your physician or other healthcare professional before adopting any treatment for a health problem or before using any of our products. If you have or suspect that you have a medical problem, promptly contact your health care provider. Never disregard, avoid, or delay obtaining medical advice from your doctor or other qualified health care provider because of something you have read on our site.
                    </Typography>
                </Box>

                {/* Section 26 - SMS/MMS Mobile Message Marketing Program Terms and Conditions */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 26 - SMS/MMS Mobile Message Marketing Program Terms and Conditions
                    </Typography>
                    <Typography paragraph>
                        Greenbelt Botanicals offers a mobile messaging program, subject to these Mobile Messaging Terms and Conditions. By opting into this Program, you agree to receive autodialed or prerecorded marketing mobile messages at the phone number associated with your opt-in, and you understand that consent is not required to make any purchase. Message and data rates may apply. For support regarding the Program, text “HELP” to the number you received messages from or email us at {ORGANIZATION.supportEmail}. Opt outs must be submitted in accordance with the procedures set forth above.
                    </Typography>
                </Box>
            </Container>
            <PlatformSpace height={80}/>
        </PlatformMarginBox>
    )
}
