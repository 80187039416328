import React from 'react';
import {useHistory} from 'react-router-dom'
import PublicApi from "../../components/layout/_attic/publicApi";
import {storeObject} from "../../components/shared/util/storageUtil";

export default function OAuth2RedirectHome(props) {
    const history = useHistory();

    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
    const token = getUrlParameter('accessToken');

    if (token) {
        localStorage.setItem("token", token);
    }

    PublicApi.getUser((user) => {
        storeObject("user", user);
        history.push("/");
    })

    return <div/>
}