import React, {useContext} from "react";
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../platform/platformSpace";
import Typography from "@mui/material/Typography";
import PublicContext from "./publicContext";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import Grid from "@mui/material/Unstable_Grid2";
import {PlatformFormContextProvider} from "../platform/platformFormContext";
import PublicEmailSubscribe from "./publicEmailSubscribe";
import {SOCIAL} from "../constants/social";

export default function PublicFooter(props) {
    const history = useHistory();
    const {productsByCategory, setShowCart, setShowContact, onCategory} = useContext(PublicContext);

    const onShowContact = () => {
        setShowCart(false);
        setShowContact(true);
    }

    const onOrderStatus = () => {
        history.push("/order/status");
    }

    const onStoreLocator = () => {
        history.push("/store/locator");
    }

    const onFDADisclaimer = () => {
        history.push("/fda-disclaimer");
    }

    const onPrivacyPolicy = () => {
        history.push("/privacy-policy");
    }

    const onTermsOfService = () => {
        history.push("/terms-of-service");
    }

    const onRefundReturns = () => {
        history.push("/refund-and-returns");
    }

    const onClick = (product) => {
        onCategory(product.categoryHandle, product.name);
    }

    return (
        <React.Fragment>
            <FooterContainer>
                <Footer>
                    <Grid container justifyContent="center">
                        <CenteredGrid item md={isMobile ? 12 : 5}>
                            <Typography variant="body0" sx={{color: "#f7f6f2"}}>
                                STAY IN THE LOOP
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Typography variant="body3" sx={{color: "#f7f6f2"}}>
                                Join our mailing list to stay in the loop with our newest product releases, flower drops,
                                and tips and tricks for navigating Greenbelt.co.
                            </Typography>
                            <PlatformSpace height={15}/>
                            <PlatformFormContextProvider name="emailSubscribeForm">
                                <PublicEmailSubscribe/>
                            </PlatformFormContextProvider>
                        </CenteredGrid>
                        {!isMobile &&
                            <Grid item md={1}>
                            </Grid>
                        }
                        <CenteredGrid item md={isMobile ? 12 : 6}>
                            {isMobile && <PlatformSpace height={30}/>}
                            <Typography variant="body0" sx={{color: "#f7f6f2"}}>
                                JOIN THE COMMUNITY
                            </Typography>
                            <PlatformSpace height={15}/>
                            <Stack
                                justifyContent="flex-start"
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                {
                                    SOCIAL.map((social) => {
                                        const Icon = social.icon;
                                        return (
                                            <a
                                                key={social.name}
                                                href={social.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <IconWrapper>
                                                    <Icon sx={{color: "white !important"}} />
                                                </IconWrapper>
                                            </a>
                                        );
                                    })
                                }
                            </Stack>
                        </CenteredGrid>
                    </Grid>
                    <PlatformSpace height={isMobile ? 15 : 60}/>
                    <Divider sx={{borderColor: "#9861ea"}}/>
                    <PlatformSpace height={isMobile ? 15 : 60}/>
                    <Grid container justifyContent="center" spacing={2}>
                        <CenteredGrid item md={isMobile ? 12 : 3}>
                            <Typography variant="body1" sx={{color: "#f7f6f2"}}>
                                GREENBELT BOTANICALS
                            </Typography>
                            <PlatformSpace height={10}/>
                            <Typography variant="body3" sx={{color: "#f7f6f2"}}>
                                Since its inception in 2018, Greenbelt Botanicals has become Austin's premier cannabis
                                dispensary, renowned for its commitment to quality and ethical standards in its premium
                                product offerings.
                            </Typography>
                            <PlatformSpace height={isMobile ? 15 : 60}/>
                            <CenteredGrid item md={isMobile ? 12 : 12} xs={6}>
                                <Stack spacing={0}>
                                    <Typography variant="body1" sx={{fontSize: "0.9rem !important"}} sx={{color: "#f7f6f2"}}>
                                        HELPFUL LINKS
                                    </Typography>
                                    <PlatformSpace height={10}/>
                                    <FooterLink variant="body3" onClick={onStoreLocator}>
                                        Store Locator
                                    </FooterLink>
                                    <FooterLink variant="body3" onClick={onShowContact}>
                                        Contact Us
                                    </FooterLink>
                                    <FooterLink variant="body3" onClick={onRefundReturns}>
                                        Refund & Returns Policy
                                    </FooterLink>
                                    <FooterLink variant="body3" onClick={onOrderStatus}>
                                        Order Status
                                    </FooterLink>
                                </Stack>
                            </CenteredGrid>
                        </CenteredGrid>
                        <CenteredGrid item md={9}>
                            <Grid container justifyContent="flex-start" spacing={2}>
                                {Object.entries(productsByCategory).map(([categoryName, products]) => (
                                    <CenteredGrid item md={isMobile ? 6 : 2} xs={6} key={categoryName}>
                                        <Stack spacing={0}>
                                            <Typography variant="body1" sx={{fontSize: "0.9rem !important"}} sx={{color: "#f7f6f2"}}>
                                                {categoryName.toUpperCase()}
                                            </Typography>
                                            <PlatformSpace height={10}/>
                                            {products.map((product, idx) => (
                                                <FooterLink variant="body3" key={idx} onClick={() => onClick(product)}>
                                                    {product.name}
                                                </FooterLink>
                                            ))}
                                        </Stack>
                                        <PlatformSpace height={10}/>
                                    </CenteredGrid>
                                ))}
                            </Grid>
                        </CenteredGrid>
                    </Grid>
                    <PlatformSpace height={30}/>
                    <Logo src={`${process.env.PUBLIC_URL}/images/logo_bigwhite.png`}/>
                    <PlatformSpace height={20}/>
                    <Stack spacing={2} direction="row"
                           justifyContent="space-between">
                        <div style={{maxWidth: isMobile ? "40vw" : "inherit"}}>
                            <Typography variant="body5" sx={{color: "#f7f6f2"}}>
                                © 2018 - 2024 Greenbelt Botanicals, Inc
                            </Typography>
                        </div>
                        <Stack spacing={2} direction={isMobile ? "column" : "row"}
                               justifyContent="flex-end">
                            <Typography variant="body5">
                                <FooterLink variant="body3" onClick={onFDADisclaimer}>
                                    FDA Disclaimer
                                </FooterLink>
                            </Typography>
                            <Typography variant="body5">
                                <FooterLink variant="body3" onClick={onPrivacyPolicy}>
                                    Privacy Policy
                                </FooterLink>
                            </Typography>
                            <Typography variant="body5">
                                <FooterLink variant="body3" onClick={onTermsOfService}>
                                    Terms of Service
                                </FooterLink>
                            </Typography>
                        </Stack>
                    </Stack>
                    <PlatformSpace height={30}/>
                    <Stack spacing={2} direction={isMobile ? "column" : "row"}
                           justifyContent="flex-end">
                        <Typography variant="body5">
                            <FooterLink variant="body3">
                                The statements made regarding these products have not been evaluated by the Food and
                                Drug Administration. The efficacy of these products has not been confirmed by
                                FDA-approved research. These products are not intended to diagnose, treat, cure or
                                prevent any disease.
                            </FooterLink>
                        </Typography>
                        <Typography variant="body5">
                            <FooterLink variant="body3">
                                Delta 8 - This product is not available for shipment to the following states: Alaska,
                                Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Hawaii, Idaho, Iowa,
                                Massachusetts, Michigan, Minnesota, Mississippi, Montana, Nevada, New Hampshire, New York,
                                North Dakota, Oregon, Rhode Island, Utah, Vermont, Virginia, Washington, West Virginia
                            </FooterLink>
                        </Typography>
                        <Typography variant="body5">
                            <FooterLink variant="body3">
                                THCA - This product is not available for shipment to the following states: Arkansas,
                                Hawaii, Idaho, Kansas, Louisiana, Oklahoma, Oregon, Rhode Island, Utah, Vermont
                            </FooterLink>
                        </Typography>
                    </Stack>
                </Footer>
            </FooterContainer>
        </React.Fragment>
    )
}


const Logo = styled('img')(({ selected }) => ({
    cursor: "pointer",
    paddingRight: 20,
    width: "100%"
}));

const CenteredGrid = styled(Grid)(({ theme }) => ({
    ...(isMobile && {
        textAlign: "center"
    }),
}));

const FooterLink = styled(Typography)(({ theme }) => ({
    fontSize: "0.8rem !important",
    cursor: "pointer",
    color: "#f7f6f2 !important",
    '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
}));

const IconWrapper = styled('div')(({ scrolled }) => ({
    padding: "14px 14px 8px 14px",
    borderRadius: 12,
    backgroundColor: "#9861ea",
}));

const FooterContainer = styled('div')(({ selected }) => ({
    width: "100%",
}));

const Footer = styled('div')(({ theme, scrolled }) => ({
    margin: "auto",
    padding: isMobile ? 15 : 45,
    zIndex: 1,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "100% 100%, 100% 100%, 200% 200%, 200% 200%",
    backgroundPosition: "0% 100%, 0% 0%, 0% 0%, 0% 0%",
    //backgroundColor: "rgba(158,243,158,0.15)"
    backgroundColor: theme.palette.primary.main
    // backgroundImage:
    //     "radial-gradient(circle at bottom center, transparent 50%, rgba(0, 255, 0, 0.1) 100%)," +
    //     "radial-gradient(circle at top center, transparent 50%, white 100%)," +
    //     "radial-gradient(circle at bottom right, transparent 50%, rgba(173, 216, 230, 0.5) 100%)," +
    //     "radial-gradient(circle at -30% 50%, transparent 50%, rgba(0, 255, 0, 0.5) 100%)",
}));