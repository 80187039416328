import * as React from 'react';
import {styled} from "@mui/styles";
import {Divider} from "@mui/material";
import CheckoutDisclaimer from "./checkoutDisclaimer";
import PlatformSpace from "../../components/platform/platformSpace";

export default function CheckoutFooter(props) {
    return (
        <div style={{width: "100%"}}>
            <CartDivider/>
            <PlatformSpace height={100}/>
            <CheckoutDisclaimer/>
            {/*<Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>*/}
            {/*    <Typography variant="body5">*/}
            {/*        Refund policy*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="body5">*/}
            {/*        Shipping policy*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="body5">*/}
            {/*        Privacy policy*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="body5">*/}
            {/*        Terms of service*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}
        </div>
    )
}

const CartDivider = styled(Divider)(({  }) => ({
    borderColor: "rgba(255, 255, 255, 0.1) !important"
}));