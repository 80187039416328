export const STORE_LOCATIONS = [
    {
        name: "West Gate",
        street1: "6800 West Gate Blvd.",
        street2: "Suite #137",
        cityStateZip: "Austin, TX 78745",
        phone: "(512) 505-8082",
        hours1: "Mon-Sat: 10:30am-8:30pm",
        hours2: "Sun: 12pm-6pm",
        image: `${process.env.PUBLIC_URL}/images/stores/wg.jpg`
    },
    {
        name: "South Lamar",
        street1: "1100 S. Lamar Blvd.",
        street2: "Suite #3130",
        cityStateZip: "Austin, TX 78704",
        phone: "(512) 852-8400",
        hours1: "Mon-Sat: 10am-10:30pm",
        hours2: "Sun: 12pm-8pm",
        image: `${process.env.PUBLIC_URL}/images/stores/sl.jpg`
    },
    {
        name: "Bee Cave",
        street1: "6317 Bee Cave Rd.",
        street2: "Suite #365",
        cityStateZip: "Austin, TX 78746",
        phone: "(512) 428-4677",
        hours1: "Mon-Sat: 10am-8:30pm",
        hours2: "Sun: 12pm-6pm",
        image: `${process.env.PUBLIC_URL}/images/stores/bc.jpg`
    },
    {
        name: "Belterra",
        street1: "165 Hargraves Dr.",
        street2: "Suite #120",
        cityStateZip: "Austin, TX 78737",
        phone: "(512) 291-3523",
        hours1: "Tue-Sat: 12pm-6pm",
        hours2: "Sun/Mon: Closed",
        image: `${process.env.PUBLIC_URL}/images/stores/bt.jpg`
    },
]
