import React, {useContext, useEffect, useRef, useState} from "react";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import {LinearProgress, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import OrderStatusContext from "./orderStatusContext";
import {formatPhoneNumber} from "../../../components/util/addressUtil";
import {isDesktop, isMobile} from "react-device-detect";
import formatToUSD from "../../../components/util/moneyUtil";

const stepLabels = ['Order Placed', 'Processing', 'Shipping', 'Out for Delivery', 'Delivered'];
const stepSpacing = [15, 5, 50, 20, 20];  // Spacing percentage for each step

export default function OrderStatusDetails(props) {
    const {order} = useContext(OrderStatusContext);
    const contactInfo = order.customer.email || formatPhoneNumber(order.billingAddress.phone);

    return (
        <React.Fragment>
            <PlatformSpace height={isMobile ? 0 : 70}/>
            <TrackWithContainer>
                <div>
                    <Typography variant="h6" sx={{fontWeight: 500}}>
                        Your order is confirmed
                    </Typography>
                </div>
                <PlatformSpace height={5}/>
                <div>
                    <Typography variant="body2" sx={{fontWeight: 500, opacity: 0.7}}>
                        You'll receive a confirmation email with your order number shortly.
                    </Typography>
                </div>
            </TrackWithContainer>
            {isMobile && <PlatformSpace height={30}/>}
            {isDesktop && <HorizontalEcommerceTimeline currentStep={4} />}
            {isMobile && <VerticalEcommerceTimeline currentStep={1} />}
            <PlatformSpace height={30}/>
            <DetailsContainer>
                <div>
                    <Typography variant="h6" sx={{fontWeight: 500}}>
                        Order details
                    </Typography>
                </div>
                <PlatformSpace height={30}/>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body2" sx={{fontWeight: 500}}>
                                Contact information
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <DetailsText>
                                {contactInfo}
                            </DetailsText>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body2" sx={{fontWeight: 500}}>
                                Payment method
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <DetailsText>
                                ending with **** - <strong>{formatToUSD(order.orderTotalAmount)}</strong>
                            </DetailsText>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body2" sx={{fontWeight: 500}}>
                                Shipping address
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <AddressComponent address={order.shippingAddress}/>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <Typography variant="body2" sx={{fontWeight: 500}}>
                                Billing address
                            </Typography>
                        </div>
                        <PlatformSpace height={5}/>
                        <div>
                            <AddressComponent address={order.billingAddress}/>
                        </div>
                    </Grid>
                </Grid>
            </DetailsContainer>
        </React.Fragment>
    );
}

const AddressComponent = ({ address }) => {
    const formattedName = `${address.firstName} ${address.lastName}`;
    const formattedPhone = formatPhoneNumber(address.phone);

    return (
        <DetailsText>
            {formattedName && <div>{formattedName}</div>}
            {address.street1 && <div>{address.street1}</div>}
            {address.street2 && <div>{address.street2}</div>}
            {address.street3 && <div>{address.street3}</div>}
            {address.city && address.state && address.zip &&
                <div>{`${address.city} ${address.state} ${address.zip}`}</div>}
            {address.country && <div>{"United States"}</div>}
            {formattedPhone && <div>{formattedPhone}</div>}
        </DetailsText>
    );
};

const VerticalEcommerceTimeline = ({ currentStep }) => {
    const [progressHeight, setProgressHeight] = useState(0);
    const progressBarRef = useRef(null);

    useEffect(() => {
        if (progressBarRef.current) {
            const progressBarHeight = progressBarRef.current.offsetHeight;
            const accumulatedHeight = stepSpacing.slice(0, currentStep).reduce((acc, val) => acc + val, 0);

            let fineTuning = 0.0;
            switch (currentStep) {
                case 1:
                    fineTuning = -0.52
                    break;
                case 2:
                    fineTuning = -0.12
                    break;
                case 3:
                    fineTuning = -0.38;
                    break;
                case 4:
                    fineTuning = -0.18
                    break;
            }

            const progressHeight = (accumulatedHeight / 100) * progressBarHeight * (1 + fineTuning);
            setProgressHeight(progressHeight);
        }
    }, [currentStep]);

    return (
        <VerticalTimelineContainer>
            <VerticalLabelContainer ref={progressBarRef}>
                {stepLabels.map((label, index) => (
                    <VerticalLabelBox style={{ flexBasis: `${stepSpacing[index]}%` }} key={index}>
                        <Typography variant="body2" color={currentStep > index + 1 ? 'textSecondary' : 'black'}>
                            {label}
                        </Typography>
                    </VerticalLabelBox>
                ))}
            </VerticalLabelContainer>
            <VerticalProgressContainer>
                <VerticalProgressBar style={{ height: `${progressHeight}px` }} />
            </VerticalProgressContainer>
        </VerticalTimelineContainer>
    );
};

const HorizontalEcommerceTimeline = ({ currentStep }) => {
    const [progressValue, setProgressValue] = useState(0);
    const progressBarRef = useRef(null);
    const labelRefs = useRef([]);

    useEffect(() => {
        if (progressBarRef.current && labelRefs.current.length === stepLabels.length) {
            const progressBarWidth = progressBarRef.current.offsetWidth;
            let accumulatedLeft = 0;

            for (let i = 0; i < currentStep; i++) {
                accumulatedLeft += stepSpacing[i] / 100 * progressBarWidth;
            }

            const targetLabelCenter = accumulatedLeft - (stepSpacing[currentStep - 1] / 100 * progressBarWidth / 2);

            let fineTuning = 0.0;
            switch (currentStep) {
                case 2:
                    fineTuning = -0.01
                    break;
                case 3:
                    fineTuning = -0.03;
                    break;
                case 4:
                    fineTuning = -0.07
                    break;
            }

            const progressValue = ((targetLabelCenter + fineTuning * progressBarWidth) / progressBarWidth) * 100;
            setProgressValue(progressValue);
        }
    }, [currentStep]);

    return (
        <TimelineContainer>
            <ProgressBar variant="determinate" value={progressValue} />
            <LabelContainer ref={progressBarRef}>
                {stepLabels.map((label, index) => (
                    <LabelBox style={{ flexBasis: `${stepSpacing[index]}%` }} ref={el => labelRefs.current[index] = el} key={index}>
                        <Typography variant="body2" color={currentStep > index + 1 ? 'textSecondary' : 'black'}>
                            {label}
                        </Typography>
                    </LabelBox>
                ))}
            </LabelContainer>
        </TimelineContainer>
    );
};

const VerticalTimelineContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: '300px', // Adjust this height to fit your layout
});

const VerticalLabelContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    width: 'auto',
    marginRight: '10px',
});

const VerticalLabelBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
});

const VerticalProgressContainer = styled('div')({
    width: '6px',
    height: '100%',
    backgroundColor: '#EAEAEA', // Background color for the unachieved progress
    position: 'relative',
});

const VerticalProgressBar = styled('div')({
    width: '100%',
    backgroundColor: '#9861ea',
    position: 'absolute',
    top: 0,
});

const TrackWithContainer = styled(Stack)(({ theme }) => ({
    borderRadius: "0 0 12px 12px",
    paddingBottom: isMobile ? 0 : 20
}));

const DetailsText = styled("div")(({ theme }) => ({
    fontWeight: 500,
    opacity: 0.7,
    fontFamily: "Poppins",
    fontSize: "0.9rem"
}));

const DetailsContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid rgba(59,42,84, 0.3)",
    borderRadius: 12,
    padding: 20,
    marginLeft: "auto",
    marginRight: "auto",
}));

const TimelineContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const LabelContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
});

const LabelBox = styled('div')({
    textAlign: 'center',
});

const ProgressBar = styled(LinearProgress)({
    width: '100%',
    height: '6px',
    marginBottom: '10px',
    borderRadius: '3px',
    backgroundColor: '#9861ea',  // Track color
    '& .MuiLinearProgress-bar': {
        //backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))"
        backgroundColor: '#9861ea',  // Progress indicator color
    },
});