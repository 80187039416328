import React, {useContext, useState} from "react";
import {Stack, Typography} from "@mui/material";
import PublicContext from "./publicContext";
import PlatformTextField from "../platform/platformTextField";
import {isMobile} from "react-device-detect";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import PlatformFormContext from "../platform/platformFormContext";

export default function PublicEmailSubscribe() {
    const {onSubscribeEmail} = useContext(PublicContext);
    const {getFormValue, validateForm} = useContext(PlatformFormContext);
    const [subscribed, setSubscribed] = useState(false);

    const onSubscribe = () => {
        if (validateForm()) {
            const email = getFormValue("email");
            onSubscribeEmail(email, () => {
                setSubscribed(true);
            })
        }
    }

    return (
        <React.Fragment>
            {!subscribed &&
                <Stack direction="row" spacing={2}>
                    <PlatformTextField
                        name="email"
                        inverted
                        required
                        email
                        placeholder="Your email address"
                        sx={{width: isMobile ? "100%" : "25vw", backgroundColor: "white"}}
                    />
                    <SignUpButton variant="contained" onClick={onSubscribe}>LET'S GO!</SignUpButton>
                </Stack>
            }
            {subscribed &&
                <Typography variant="h5" gutterBottom sx={{color: "white"}}>
                    YOU DID GREAT!
                </Typography>
            }
        </React.Fragment>
    );
}

const SignUpButton = styled(Button)(({ theme }) => ({
    width: "140px !important",
    color: "white !important"
}));