import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import styles from "./passwordStyles";
import {useHistory} from "react-router-dom";
import PlatformButton from "components/platform/platformButton";
import PlatformSpace from "components/platform/platformSpace";
import {Box, Container, Grid} from "@mui/material";
import PlatformTextField from "components/platform/platformTextField";
import PlatformForm from "components/platform/platformForm";
import PasswordApi from "./passwordApi";
import {Alert} from "@mui/lab";

const useStyles = makeStyles(styles);

export default function PasswordResetForm(props) {
    const classes = useStyles();
    const history = useHistory();
    const {token} = props;
    const formRef = useRef(null);
    const [error, setError] = useState(null);
    const [formCompleted, setFormCompleted] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        PasswordApi.validateToken(token, () => {
        }, (error) => {
            console.log('error', error)
            setMessage("The password reset token provided is invalid.");
            setFormCompleted(true);
            setError(error.data.message);
        })
    }, [])

    const onResetPassword = () => {
        let isValid = formRef.current.validate();
        if (isValid) {
            PasswordApi.resetPassword({
                token: token,
                password: formRef.current.getValue("password"),
                appId: "admin"
            }, (response) => {
                setMessage("Your password has been reset successfully. Please sign in to continue.");
                setFormCompleted(true);
            }, (error) => {
                localStorage.setItem("token", null);
                setError(error.data.message);
            })
        }
    }

    const onSignIn = () => {
        history.push({
            pathname: "/"
        })
    }

    return (
        <div style={{height: "100vh", backgroundColor: "#323436"}}>
            {error && <Alert severity="error">{error}</Alert>}
            <PlatformSpace height={20}/>
            <Grid container>
                <Grid item container justifyContent="center" xs={12}>
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center">
                        <Typography variant="h4" style={{textTransform: "none", color: "black"}}>
                            Reset your password
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <PlatformSpace height={40}/>
            <Container maxWidth="xs" style={{backgroundColor: "white", borderRadius: 5, padding: 20}}>
                <PlatformForm ref={formRef}>
                    {!formCompleted &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PlatformTextField
                                    defaultValue="password"
                                    required
                                    fullWidth
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    label="New Password"
                                    errorText="Password is required"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PlatformButton onClick={onResetPassword} color="inherit" size="lg" fullWidth>
                                    Set password
                                </PlatformButton>
                            </Grid>
                        </Grid>}
                    {formCompleted &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    {message}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <PlatformButton onClick={onSignIn} color="inherit" size="lg" fullWidth>
                                    Sign in
                                </PlatformButton>
                            </Grid>
                        </Grid>}
                </PlatformForm>
            </Container>
        </div>
    );
}