import React, {useContext} from "react";
import {styled} from "@mui/styles";
import {Stack, Typography} from "@mui/material";
import OrderStatusContext from "./orderStatusContext";
import {getCartImageUrl} from "../../../components/util/cart";

export default function OrderStatusSummary(props) {
    const {order} = useContext(OrderStatusContext);

    return (
        <React.Fragment>
            {order &&
                <React.Fragment>
                    {order?.orderItems?.map((orderItem) => {
                        const imageUrl = getCartImageUrl(orderItem);

                        return (
                            <React.Fragment key={orderItem.id}>
                                <Stack justifyContent="space-between" direction="row" alignItems="center" sx={{width: "95%", paddingBottom: 1}}>
                                    <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                                        <Image src={imageUrl} />
                                        <div>
                                            <Typography variant="body4" sx={{color: "black"}}>
                                                {orderItem.name}
                                            </Typography>
                                            <br/>
                                            <Typography variant="body5" sx={{color: "black"}}>
                                                {orderItem.variantName}
                                            </Typography>
                                        </div>
                                    </Stack>
                                    <Typography variant="body5" sx={{color: "black"}}>
                                        x {orderItem.quantity}
                                    </Typography>
                                </Stack>
                            </React.Fragment>
                        )
                    })}
                </React.Fragment>
            }
        </React.Fragment>
    );
}

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none",
    backgroundColor: "black"
}));