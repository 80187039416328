import React from "react";
import {CatalogShopAllContextProvider} from "./catalogShopAllContext";
import CatalogShopAllHome from "./catalogShopAllHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";
import PublicLayout from "../../../components/layout/publicLayout";

export default function CatalogShopAllIndex(props) {
    return (
      <PublicLayout>
          <PlatformFormContextProvider name="catalogShopAllForm">
              <CatalogShopAllContextProvider {...props}>
                  <CatalogShopAllHome/>
              </CatalogShopAllContextProvider>
          </PlatformFormContextProvider>
      </PublicLayout>
    );
}