import React from "react";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import {Stack, Typography} from "@mui/material";
import OrderStatusSummary from "./orderStatusSummary";
import OrderStatusDetails from "./orderStatusDetails";
import OrderStatusControls from "./orderStatusControls";
import {isMobile} from "react-device-detect";

export default function OrderStatusMobile(props) {
    return (
        <React.Fragment>
            <PlatformSpace height={isMobile ? 60 : 100}/>
            <PaneContainer spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="h3" textAlign="center">
                    Details of your order
                </Typography>
                <PlatformSpace height={15}/>
                <OrderStatusDetails/>
                <PlatformSpace height={15}/>
                <OrderStatusSummary/>
                <PlatformSpace height={15}/>
                <OrderStatusControls/>
            </PaneContainer>
            <PlatformSpace height={100}/>
        </React.Fragment>
    );
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "88vw",
}));