import * as React from 'react';
import {useContext} from 'react';
import GridTilePager from "../shared/gridTilePager/gridTilePager";
import RelatedVariantContext from "./relatedVariantContext";

export default function RelatedVariantHome(props) {
    const {variantsWithParent} = useContext(RelatedVariantContext);

    return (
        <GridTilePager
            {...props}
            titleJustification="center"
            title="You may also like..."
            products={variantsWithParent}
        />
    )
}