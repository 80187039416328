import React from "react";
import {PublicContactContextProvider} from "./publicContactContext";
import PublicContactHome from "./publicContactHome";
import {PlatformFormContextProvider} from "../../platform/platformFormContext";

export default function PublicContactIndex(props) {
    return (
        <PlatformFormContextProvider name="contactForm">
            <PublicContactContextProvider>
                <PublicContactHome/>
            </PublicContactContextProvider>
        </PlatformFormContextProvider>
    );
}