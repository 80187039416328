import React, {useContext} from "react";
import {Backdrop} from "@mui/material";
import PublicContext from "./publicContext";
import {isMobile} from "react-device-detect";

export default function PublicBackdrop() {
    const publicContext = useContext(PublicContext);

    return (
        <Backdrop
            open={publicContext.blurBackground}
            transitionDuration={400}
            sx={{
                backgroundColor: "rgba(215,215,215,0.3)",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backdropFilter: `blur(${isMobile ? 0 : 10}px)`,
            }}
        />
    );
}