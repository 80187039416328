import * as React from 'react';
import {useContext} from 'react';
import GridTilePager from "../shared/gridTilePager/gridTilePager";
import BestSellerVariantContext from "./bestSellerVariantContext";

export default function BestSellerVariantHome(props) {
    const {variantsWithParent} = useContext(BestSellerVariantContext);

    return (
        <GridTilePager
            {...props}
            titleJustification="center"
            title="Explore our bestSellers"
            products={variantsWithParent}
        />
    )
}