import React from "react";
import {makeStyles} from "@mui/styles";
import styles from "./loginStyles";
import ExternalLayout from "../shared/externalLayout";
import ExternalMenu from "../shared/externalMenu";
import Divider from "@mui/material/Divider";
import SignupForm from "./loginForm";

const useStyles = makeStyles(styles);

export default function LoginHome() {
  const classes = useStyles();

  return (
      <ExternalLayout>
        <ExternalMenu/>
        <Divider />
        <SignupForm/>
      </ExternalLayout>
  );
}