import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {OrderReceiptContextProvider} from "./orderReceiptContext";
import OrderReceiptHome from "./orderReceiptHome";

export default function OrderReceiptIndex(props) {
    return (
        <PublicLayout>
            <OrderReceiptContextProvider>
                <OrderReceiptHome/>
            </OrderReceiptContextProvider>
        </PublicLayout>
    );
}