import PublicLayout from "../../../components/layout/publicLayout";
import React from "react";
import TermsOfServiceHome from "./termsOfServiceHome";

export default function TermsOfServiceIndex(props) {
    return (
        <PublicLayout>
            <TermsOfServiceHome/>
        </PublicLayout>
    );
}