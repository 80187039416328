import * as React from 'react';
import {useContext, useState} from 'react';
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckoutSummary from "./checkoutSummary";
import CartContext from "../cart/cartContext";
import formatToUSD from "../../components/util/moneyUtil";

export default function CheckoutSummaryMobile({children}) {
    const {cart} = useContext(CartContext);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    }

    return (
        <div>
            <CustomAccordion expanded={expanded} onChange={handleChange}>
                <CustomAccordionSummary
                    expanded={expanded}
                    expandIcon={<ExpandMore />}
                >
                    <Stack justifyContent="space-between"
                           alignItems="center"
                           sx={{width: "100%"}}
                           direction="row"
                           spacing={1}>
                        <OrderSummaryText>
                            Show order summary
                        </OrderSummaryText>
                        <GrandTotalText>
                            {formatToUSD(cart.cartTotalAmount)}
                        </GrandTotalText>
                    </Stack>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <CheckoutSummary width="78vw"/>
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    );
}

const CustomAccordion = styled((props) => (
    <MuiAccordion
        disableGutters
        elevation={0}
        square
        {...props}
    />
))(({ theme, expanded }) => ({
    borderRadius: 12,
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const CustomAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={<ExpandMore/>}
        {...props}
    />
))(({ theme, expanded }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.03) !important',
    flexDirection: 'row-reverse',
    borderRadius: !expanded ? 12 : "12px 12px 0 0 !important",
}));

const OrderSummaryText = styled(Typography)(({ theme }) => ({
    flexShrink: 0,
    color: `${theme.palette.primary.main} !important`,
    paddingLeft: 10
}));

const ExpandMore = styled(ExpandMoreIcon)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const GrandTotalText = styled("div")(({ theme }) => ({
    fontSize: "1.2rem",
    color: "black",
    fontWeight: 600
}));