import React, {useContext} from "react";
import Divider from "@mui/material/Divider";
import {makeStyles, styled} from "@mui/styles";
import styles from "./cartStyles";
import Grid from "@mui/material/Grid";
import {CircularProgress, Stack, Typography} from "@mui/material";
import PlatformSpace from "../../components/platform/platformSpace";
import CartContext from "./cartContext";
import {isBrowser, isMobile} from "react-device-detect";
import {getCartImageUrl} from "../../components/util/cart";
import formatToUSD from "../../components/util/moneyUtil";

const useStyles = makeStyles(styles);

export default function CartItems(props) {
    const classes = useStyles();
    const {cart, emptyCart, deleteCartItem, loading} = useContext(CartContext);

    const onDelete = (cartItem) => {
        deleteCartItem(cartItem);
    }

    return (
        <div className={classes.cartItems}>
            {cart?.cartItems?.map((cartItem) => {
                const imageUrl = getCartImageUrl(cartItem);

                return (
                    <React.Fragment key={cartItem.id}>
                        <PlatformSpace height={10}/>
                        <Grid container spacing={2}>
                            <Grid item xs={isBrowser ? 3 : 12} container justifyContent="center">
                                <ImageOutline>
                                    <ImageContainer>
                                        <Image src={imageUrl} />
                                    </ImageContainer>
                                </ImageOutline>
                            </Grid>
                            <Grid item xs={isBrowser ? 9 : 12}>
                                <Grid container justifyContent="center" alignItems="flex-start">
                                    <Grid item xs={6}>
                                        <Typography variant={isMobile ? "body1" : "body0"} display="block" style={{fontWeight: 600, textTransform: "none"}}>
                                            {cartItem.name?.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant={isMobile ? "body1" : "body0"} display="block" style={{fontWeight: 600, textTransform: "none"}}>
                                            {`QTY. ${cartItem.quantity}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} container justifyContent="flex-end">
                                        <Typography variant={isMobile ? "body1" : "body0"} display="block" style={{fontWeight: 600, textTransform: "none"}}>
                                            {formatToUSD(cartItem.total)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PlatformSpace height={10}/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" display="block" sx={{fontWeight: 500}}>
                                            {cartItem.variantName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} container justifyContent="flex-end">
                                        <Typography variant="body2" sx={{fontWeight: 500}}>
                                            {formatToUSD(cartItem.salePrice !== null ? cartItem.salePrice : cartItem.price)}&nbsp;
                                            {cartItem.salePrice !== null && <SalePrice>{formatToUSD(cartItem.price)}</SalePrice>} ea.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <CartItemLink onClick={() => onDelete(cartItem)}>
                                            Remove
                                        </CartItemLink>
                                    </Grid>
                                    {/*<CartAddon/>*/}
                                    <Grid item xs={12}>
                                        <PlatformSpace height={20}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <PlatformSpace height={30}/>
                                <Divider sx={{opacity: 0.3}}/>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            })}
            {loading &&
                <Stack justifyContent="center" direction="row" alignItems="center" spacing={2}>
                    <CircularProgress color="primary" size="5rem" />
                </Stack>
            }
        </div>
    );
}

function CartAddon(props) {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <PlatformSpace height={20}/>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="body1" display="block">
                    10,000 Pre-rolls
                </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
                <CartItemLink>
                    Add
                </CartItemLink>
            </Grid>
            <Grid item xs={12}>
                <PlatformSpace height={20}/>
            </Grid>
        </React.Fragment>
    )
}

function CartItemLink(props) {
    const { children, onClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.cartItemLink} onClick={onClick}>
            <Typography variant="body3" sx={{fontWeight: 500}}>
                {children}
            </Typography>
        </div>
    )
}

const SalePrice = styled('span')(({ theme }) => ({
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "green",
    textDecoration: "line-through"
}));

const ImageContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    borderRadius: 12,
    transition: ".3s ease-in-out",
    transform: !hover ? "scale(1)" : "scale(1.1)",
    backgroundColor: "black"
}));

const ImageOutline = styled("div")(({ theme }) => ({
    borderRadius: 12,
    overflow: "hidden",
    padding: 5,
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    height: isMobile ? "inherit" : 125,
    width: isMobile ? "80vw" : "inherit",
    borderRadius: 12,
    pointerEvents: "none"
}));