import * as React from 'react';
import {useState} from 'react';
import {Accordion, AccordionDetails, Typography} from "@mui/material";
import {styled} from "@mui/styles";

export default function CatalogFilterControl({alwaysExpanded, title, icon, content}) {
    const [expanded, setExpanded] = useState(alwaysExpanded);
    const TitleIcon = icon;

    const onChange = (event, expanded) => {
        setExpanded(expanded);
    }

    const accordionProps = {
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 0, exit: 0 }
    }

    return (
        <FilterContainer
            onChange={onChange}
            expanded={alwaysExpanded}
            defaultExpanded={alwaysExpanded}
            TransitionProps={accordionProps}>
            <FilterText variant="body3" sx={{fontWeight: 700}}>
                {title?.toUpperCase()}
            </FilterText>
            <AccordionDetails>
                {content}
            </AccordionDetails>
        </FilterContainer>
    )
}

const FilterContainer = styled(Accordion)(({ theme }) => ({
    "&.MuiCollapse-root": {
        backgroundColor: "white !important"
    },
    "&.Mui-expanded": {
        margin: 0
    },
    "&. MuiAccordionSummary-content": {
        fontSize: "1.2rem !important",
    },
    "&:before": {
        backgroundColor: "transparent !important"
    }
}));

const FilterText = styled(Typography)(({ theme }) => ({
    paddingLeft: 10
}));