import React, {forwardRef, useContext} from 'react';
import {makeStyles} from '@mui/styles';
import {Grid} from "@mui/material";
import styles from "./signupStyles";
import PlatformButton from "components/platform/platformButton";
import PlatformTextField from "components/platform/platformTextField";
import PlatformForm from "components/platform/platformForm";
import SignupContext from "./signupContext";

const useStyles = makeStyles(styles);

const SignupFormStep1 = (props, ref) => {
    const classes = useStyles();
    const context = useContext(SignupContext);

    return (
        <PlatformForm ref={ref} onChange={context.setFormStep1}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PlatformTextField
                        defaultValue=""
                        required
                        fullWidth
                        placeholder="First name"
                        name="firstName"
                        label="First name"
                        errorText="First name is required"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PlatformTextField
                        defaultValue=""
                        required
                        fullWidth
                        placeholder="Last name"
                        name="lastName"
                        label="Last name"
                        errorText="Last name is required"
                    />
                </Grid>
                <Grid item xs={12}>
                    <PlatformTextField
                        defaultValue=""
                        required
                        email
                        fullWidth
                        placeholder="Email"
                        name="email"
                        label="Email"
                        errorText="Email is required"
                    />
                </Grid>
                <Grid item xs={12}>
                    <PlatformTextField
                        defaultValue=""
                        required
                        fullWidth
                        type="password"
                        placeholder="Password"
                        name="password"
                        label="Password"
                        errorText="Password is required"
                    />
                </Grid>
                <Grid item xs={12}>
                    <PlatformTextField
                        defaultValue=""
                        required
                        fullWidth
                        placeholder="Phone"
                        name="phone"
                        label="Phone"
                        errorText="Phone is required"
                    />
                </Grid>
                <Grid item xs={12}>
                    <PlatformButton onClick={context.onSignup} color="inherit" size="lg" fullWidth>Sign Up</PlatformButton>
                    {/*<PlatformButton onClick={context.onNextStep} color="inherit" size="lg" fullWidth>Next Step</PlatformButton>*/}
                </Grid>
            </Grid>
        </PlatformForm>
    )
}

export default forwardRef(SignupFormStep1);