import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import PlatformSpace from "../../components/platform/platformSpace";
import { isMobile } from "react-device-detect";

export default function HomeWhatWeDo() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const mainImageWidth = isMobile ? "100%" : "84%"; // 70% * 1.2 = 84% for a 20% increase

    return (
        <BackgroundImageBox>
            <Stack direction={isMobile ? "column" : "row"}>
                <ContentBox>
                    <Text variant={isMobile ? 'h3' : 'h1'}>WHAT<br/>WE DO</Text>
                    <PlatformSpace height={20} />
                    <Typography variant="body1" gutterBottom>
                        We produce premium cannabis products that can be legally delivered directly to your doorstep.
                    </Typography>
                    <PlatformSpace height={20} />
                    {/* Bullet Points */}
                    <BulletPointBox>
                        <CustomIcon src={`${process.env.PUBLIC_URL}/images/whatwedo/Checkbox.png`} alt="Legal Icon" />
                        <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                            100% Federally Legal
                        </Typography>
                    </BulletPointBox>
                    <BulletPointBox>
                        <CustomIcon src={`${process.env.PUBLIC_URL}/images/whatwedo/Texas.png`} alt="American Grown Icon" />
                        <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                            American Grown
                        </Typography>
                    </BulletPointBox>
                    <BulletPointBox>
                        <CustomIcon src={`${process.env.PUBLIC_URL}/images/whatwedo/EyeX.png`} alt="Discreet Packaging Icon" />
                        <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                            Discreet Packaging
                        </Typography>
                    </BulletPointBox>
                    <BulletPointBox>
                        <CustomIcon src={`${process.env.PUBLIC_URL}/images/whatwedo/Star.png`} alt="Since 2018 Icon" />
                        <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                            Since 2018
                        </Typography>
                    </BulletPointBox>
                </ContentBox>
                <ImageBox>
                    <MainImage
                        src={`${process.env.PUBLIC_URL}/images/whatwedo/WhatWeDo.png`}
                        alt="What We Do"
                        width={mainImageWidth} // Dynamically set width
                    />
                </ImageBox>
            </Stack>
        </BackgroundImageBox>
    );
}

// Custom Icon styled component for the images
const CustomIcon = styled('img')(({ theme }) => ({
    width: '24px', // Adjust the size of the icons
    marginRight: theme.spacing(1),
}));

// Bullet point styling with increased spacing
const BulletPointBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2), // Increased spacing between bullet points
}));

// Main image styled component
const MainImage = styled("img")({
    height: "auto",
    marginTop: isMobile ? 30 : 0,
});

const BackgroundImageBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isMobile ? theme.spacing(4) : `0px 20vw 0px 20vw`,
}));

const ImageBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        order: 2, // Put the image on the right side
        width: '50%',
    },
    '& img': {
        width: '100%',
        height: 'auto',
    },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        order: 1, // Put the text on the left side
        width: '50%',
    },
}));

const Text = styled(Typography)(({ theme }) => ({
    lineHeight: 0.9,
    color: theme.palette.primary.main, // Purple color from the reference
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    [theme.breakpoints.up('md')]: {
        fontSize: '5.5rem', // Larger size for bigger screens
    },
}));
