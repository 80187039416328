import {useMemo} from 'react';

function useThumbnailUrls(variant) {
    return useMemo(() => {
        if (variant && variant.images && variant.images.length > 0) {
            return variant.images.map(image => {
                let mediaUrl = image.thumbUrl;
                // Check if the URL is from the product-image bucket
                if (mediaUrl.includes('canna-prod-product-image.s3.us-west-2.amazonaws.com')) {
                    mediaUrl = mediaUrl.replace('canna-prod-product-image.s3.us-west-2.amazonaws.com', 'd1yunjbpzd5bi8.cloudfront.net/product-image');
                }
                // Check if the URL is from the cms-image bucket
                else if (mediaUrl.includes('canna-prod-cms-image.s3.us-west-2.amazonaws.com')) {
                    mediaUrl = mediaUrl.replace('canna-prod-cms-image.s3.us-west-2.amazonaws.com', 'd1yunjbpzd5bi8.cloudfront.net/cms-image');
                }
                return mediaUrl;
            });
        } else {
            return [`${process.env.PUBLIC_URL}/images/comingsoon.jpg`];
        }
    }, [variant]);
}

export default useThumbnailUrls;
