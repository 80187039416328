import React, {useEffect, useRef, useState} from "react";
import {Box, IconButton} from "@mui/material";
import {styled} from "@mui/material/styles";
import {isMobile} from "react-device-detect";

const slides = [
    {
        id: 1,
        image: `${process.env.PUBLIC_URL}/images/slides/NewGummiesChillAndBliss.jpg`, // Replace with your image path
        sticker: `${process.env.PUBLIC_URL}/images/slides/NEW.png`, // Sticker image path
    },
    {
        id: 2,
        image: `${process.env.PUBLIC_URL}/images/slides/Snoozefest2024.jpg`, // Replace with your image path
        sticker: null
    },
];

const HomeSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const autoplayRef = useRef(null);
    const [dragStart, setDragStart] = useState(null);
    const [dragging, setDragging] = useState(false);
    const dragThreshold = 100; // Minimum distance in pixels for a valid drag-to-slide

    // Function to reset autoplay when manually changing slides
    const resetAutoplay = () => {
        clearInterval(autoplayRef.current);
        startAutoplay();
    };

    // Autoplay effect to change slides every 5 seconds
    const startAutoplay = () => {
        autoplayRef.current = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 7500);
    };

    useEffect(() => {
        startAutoplay();
        return () => clearInterval(autoplayRef.current); // Cleanup on unmount
    }, []);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
        resetAutoplay(); // Reset the timer when manually changing the slide
    };

    const handleDragStart = (e) => {
        const startX = isMobile ? e.touches[0].clientX : e.clientX;
        setDragStart(startX);
        setDragging(true);
        clearInterval(autoplayRef.current); // Stop autoplay while dragging
    };

    const handleDragEnd = (e) => {
        if (!dragging) return;

        const endX = isMobile ? e.changedTouches[0].clientX : e.clientX;
        const dragDistance = endX - dragStart;

        if (Math.abs(dragDistance) > dragThreshold) {
            if (dragDistance > 0) {
                // Dragged right (previous slide)
                setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
            } else {
                // Dragged left (next slide)
                setCurrentSlide((prev) => (prev + 1) % slides.length);
            }
        }

        setDragging(false);
        resetAutoplay(); // Restart autoplay after dragging
    };

    return (
        <SliderContainer>
            {/* Slide Display */}
            <ImageContainer
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={(e) => dragging && e.preventDefault()} // Prevents text/image selection during drag
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
            >
                <StyledImage
                    src={slides[currentSlide].image}
                    alt={`Slide ${currentSlide}`}
                />
                {(!isMobile && slides[currentSlide].sticker) &&
                    <StickerImage
                        src={slides[currentSlide].sticker}
                        alt="Sticker"
                    />
                }
            </ImageContainer>

            {/* Custom Pagination Dots */}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                {slides.map((slide, index) => (
                    <DiamondButton
                        key={slide.id}
                        mx={0}
                        isActive={currentSlide === index}
                        onClick={() => handleSlideChange(index)}
                    />
                ))}
            </Box>
        </SliderContainer>
    );
};

// Styled container for the slider
const SliderContainer = styled(Box)(({ theme }) => ({
    width: isMobile ? "88vw" : "93vw",
    maxWidth: isMobile ? "88vw" : "93vw",
    margin: "0 auto",
    position: "relative",
}));

// Image container with border-radius and proper scaling
const ImageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    aspectRatio: "16/9", // Ensures the image container maintains a 16:9 aspect ratio
    overflow: "hidden",
    borderRadius: isMobile ? 20 : 50, // Set the border radius for the image container
    position: "relative",
    width: "100%",
    cursor: "grab", // Cursor changes when dragging
}));

// Styled image to maintain its aspect ratio and fill the container
const StyledImage = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensures the image covers the container without stretching
    objectPosition: "center", // Centers the image within the container
    borderRadius: "inherit", // Ensure image inherits border-radius of container
});

// Sticker image styled component
const StickerImage = styled("img")({
    position: "absolute",
    top: 150, // Adjust top position
    right: 100, // Adjust left position
    width: 250, // Width of the sticker image
    height: "auto", // Height of the sticker image
    zIndex: 2, // Ensure the sticker appears above the main image
});

// Diamond-shaped button for pagination
const DiamondButton = ({ isActive, onClick }) => (
    <IconButton
        onClick={onClick}
        sx={{
            fontSize: "36px",
            width: "48px",
            height: "48px",
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isActive ? "#6f3abe" : "#6f3abe",
            textShadow: !isActive ? "0 0 1px #6f3abe, 0 0 1px #6f3abe" : "none",
            "&:hover": {
                color: "#6f3abe !important", // Add !important to override default styles
                backgroundColor: "transparent !important", // Ensure no background on hover
                outline: "none !important",
            },
        }}
    >
        {isActive ? "✦" : "✧"}
    </IconButton>
);

export default HomeSlider;
