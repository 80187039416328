import React from "react";
import PropTypes from "prop-types";
import styles from "./externalStyles";
import {makeStyles} from "@mui/styles";
import {StickyContainer} from "react-sticky";

const useStyles = makeStyles(styles);

export default function ExternalLayout(props) {
    const { children, noPadding, noCrumbs } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <StickyContainer>
                    {children}
                </StickyContainer>
            </div>
        </React.Fragment>
    );
}

ExternalLayout.propTypes = {
    children: PropTypes.any
}