import * as React from 'react';
import {useContext} from 'react';
import {Divider, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../../components/platform/platformSpace";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import formatToUSD from "../../../components/util/moneyUtil";
import OrderReceiptContext from "./orderReceiptContext";
import {isMobile} from "react-device-detect";
import {getCartImageUrl} from "../../../components/util/cart";

export default function OrderReceiptSummary(props) {
    const {order} = useContext(OrderReceiptContext);

    return (
        <div style={{width: isMobile ? "88vw" : "23vw"}}>
            <PlatformSpace height={10}/>
            {order && <React.Fragment>
                {order?.orderItems?.map((orderItem) => {
                    const imageUrl = getCartImageUrl(orderItem);

                    return (
                        <React.Fragment key={orderItem.id}>
                            <Stack justifyContent="space-between" direction="row" alignItems="center">
                                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                                    <Badge badgeContent={orderItem.quantity} color="primary">
                                        <Image src={imageUrl} />
                                    </Badge>
                                    <div>
                                        <Typography variant="body4" sx={{color: "black"}}>
                                            {orderItem.name}
                                        </Typography>
                                        <br/>
                                        <Typography variant="body5" sx={{color: "black"}}>
                                            {orderItem.variantName}
                                        </Typography>
                                    </div>
                                </Stack>
                                <Typography variant="body5" sx={{color: "black"}}>
                                    {formatToUSD(orderItem.total)}
                                </Typography>
                            </Stack>
                            <PlatformSpace height={30}/>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>}
            <OrderDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Subtotal
                </TotalsText>
                <TotalsText>
                    {formatToUSD(order.baseAmount)}
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Shipping
                </TotalsText>
                <TotalsSubtext>
                    {order.shippingAmount !== 0 && formatToUSD(order.shippingAmount)}
                    {order.shippingAmount === 0 && "Free"}
                </TotalsSubtext>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Taxes
                </TotalsText>
                <TotalsText>
                    {formatToUSD(order.taxAmount)}
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <OrderDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <GrandTotalText>
                    Total
                </GrandTotalText>
                <Stack spacing={2} justifyContent="flex-end" direction="row" alignItems="center">
                    <TotalsSubtext>
                        USD
                    </TotalsSubtext>
                    <GrandTotalText>
                        {formatToUSD(order.orderTotalAmount)}
                    </GrandTotalText>
                </Stack>
            </Stack>
        </div>
    )
}

const OrderDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(0, 0, 0, 0.1) !important"
}));


const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "black",
    opacity: 0.6,
    fontWeight: 300
}));

const ApplyButton = styled(Button)(({ theme }) => ({
    width: "140px !important",
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none",
    backgroundColor: "black"
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "black",
    fontWeight: 400
}));

const GrandTotalText = styled("div")(({ theme }) => ({
    fontSize: "1.2rem",
    color: "black",
    fontWeight: 600
}));