import React, {useContext} from 'react';
import {Box, Container, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {isMobile} from 'react-device-detect';
import PublicContext from './publicContext';
import AppContext from '../../appContext';

export default function PublicPromoBanner({ children }) {
    const { storeWideSale } = useContext(PublicContext);
    const { layoutOptions } = useContext(AppContext);

    // Outer container for the full-width background
    const FullWidthBox = styled(Box)(({ theme }) => ({
        backgroundColor: 'rgba(142,243,142,0.4)',
        padding: theme.spacing(1),
        marginTop: isMobile && layoutOptions.headerComponent ? 135 : theme.spacing(2),
        //marginBottom: isMobile ? 0 : 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }));

    // Centering content within the max-width of 'lg'
    const StyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        maxWidth: '100%',
    }));

    const CenteredContainer = styled(Container)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    }));

    return (
        <React.Fragment>
            {storeWideSale ? (
                <FullWidthBox>
                    <CenteredContainer maxWidth="lg" sx={{ margin: 1 }}>
                        <StyledBox>
                            <Typography variant="body2" component="h2">
                                {storeWideSale.title}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {storeWideSale.description}
                            </Typography>
                        </StyledBox>
                    </CenteredContainer>
                    {/*<IconButton onClick={onClosePromoBanner} aria-label="close">*/}
                    {/*    <CloseIcon />*/}
                    {/*</IconButton>*/}
                </FullWidthBox>
            ) : null}
            {children}
        </React.Fragment>
    );
}
