import React, {useContext, useState} from "react";
import PlatformFormContext from "../../platform/platformFormContext";
import PublicApi from "../publicApi";

const PublicContactContext = React.createContext();
export default PublicContactContext;

export const PublicContactContextProvider = ({children}) => {
    const {getForm, validateForm, resetForm} = useContext(PlatformFormContext);
    const [message, setMessage] = useState();

    const onSubmit = () => {
        if (validateForm()) {
            const payload = getForm();
            PublicApi.createMessage(payload, (data) => {
                setMessage(data);
                resetForm();
            })
        }
    }

    return (
        <PublicContactContext.Provider
            value={{
                message,
                setMessage,
                onSubmit
            }}
        >
            <>{children}</>
        </PublicContactContext.Provider>
    );
};