import React from "react";
import {makeStyles} from "@mui/styles";
import styles from "./signupStyles";
import ExternalLayout from "../shared/externalLayout";
import ExternalMenu from "../shared/externalMenu";
import Divider from "@mui/material/Divider";
import SignupForm from "./signupForm";

const useStyles = makeStyles(styles);

export default function SignupHome() {
    const classes = useStyles();

    return (
        <ExternalLayout>
            <ExternalMenu/>
            <Divider />
            <SignupForm/>
        </ExternalLayout>
    );
}