import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { isMobile } from "react-device-detect";
import PlatformButton from "../../components/platform/platformButton";
import { useHistory } from "react-router-dom";
import PlatformSpace from "../../components/platform/platformSpace";
import {useTheme} from "@mui/material";

export default function HomeFAQ() {
    const history = useHistory();
    const theme = useTheme();

    const onMoreQuestions = () => {
        history.push("/blog/what-is-thca");
    };

    const faqs = [
        {
            question: "How do you create the different effects?",
            answer: "We select different cannabis strains that are known to produce specific effects. Our selection is based on the unique cannabinoid profiles and terpenes, which influence the overall effects."
        },
        {
            question: "Lab testing",
            answer: "Greenbelt subjects all products to extensive lab tests to confirm their purity and strength. Tests include analyses for cannabinoids, terpenes, pesticides, and other impurities."
        },
        {
            question: "Will this show up on a drug test?",
            answer: "THC levels can vary in the body based on usage frequency. There is a possibility that our THC-containing products could be detected in a drug test."
        },
        {
            question: "Getting high: what our cannabis feels like",
            answer: "The effects of our cannabis can differ widely based on the strain and individual biochemistry. Typically, effects range from relaxation and euphoria to heightened energy and creativity."
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 2,
                        gap: 0,
                    }}
                >
                    <Typography variant={isMobile ? 'h3' : 'h1'} gutterBottom sx={{ paddingLeft: 2 }}>
                        FAQs
                    </Typography>
                    {faqs.map((faq, index) => (
                        <Accordion
                            key={index}
                            sx={{
                                borderBottom: `1px solid ${theme.palette.text.lightgrey} !important`,
                                borderRadius: 0, // Optional: rounded corners
                                '&:before': { display: 'none' }, // Remove default MUI accordion divider
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="body1">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <PlatformSpace height={60} />
                    <Container
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: isMobile ? 'center' : 'flex-start',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            gap: 2,
                        }}
                    >
                        <PlatformButton size={'lg'} onClick={onMoreQuestions}>
                            MORE QUESTIONS?
                        </PlatformButton>
                    </Container>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/images/faq/BigBud.jpg`}
                        alt="Cannabis leaves"
                        style={{ width: '80%', height: 'auto', borderRadius: '20px' }}
                    />
                </Box>
            </Box>
        </Container>
    );
}
