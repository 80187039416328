import React from "react";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../components/platform/platformSpace";
import {Stack, Typography} from "@mui/material";
import OrderStatusSummary from "./orderStatusSummary";
import OrderStatusDetails from "./orderStatusDetails";
import OrderStatusControls from "./orderStatusControls";

export default function OrderStatusDesktop(props) {
    return (
        <React.Fragment>
            <PlatformSpace height={100}/>
            <div style={{margin: "auto", width: "70vw"}}>
                <Typography variant="h3" textAlign="center">
                    Details of your order
                </Typography>
            </div>
            <PaneContainer direction="row" spacing={5} justifyContent="flex-start" alignItems="flex-start">
                <LeftPane>
                    <OrderStatusSummary/>
                </LeftPane>
                <RightPane>
                    <OrderStatusDetails/>
                    <PlatformSpace height={30}/>
                    <OrderStatusControls/>
                </RightPane>
            </PaneContainer>
            <PlatformSpace height={100}/>
        </React.Fragment>
    );
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "70vw",
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    width: "25vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    width: "75vw",
}));