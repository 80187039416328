import * as React from 'react';
import {useContext} from 'react';
import PlatformSpace from "../../../components/platform/platformSpace";
import StateMapIndex from "../shared/statemap/stateMapIndex";
import CatalogItemProductFAQ from "./catalogItemProductFAQ";
import CatalogItemCategoryPitch from "./catalogItemCategoryPitch";
import CatalogItemDetail from "./catalogItemDetail";
import CatalogItemMainFAQ from "./catalogItemMainFAQ";
import RelatedVariantIndex from "../related/relatedVariantIndex";
import CatalogItemContext from "./catalogItemContext";
import FavoriteVariantIndex from "../favorite/favoriteVariantIndex";
import {isMobile} from "react-device-detect";
import useLayoutOptions from "../../../components/hook/useLayoutOptions";
import PublicHeader from "../../../components/layout/publicHeader";
import PublicFooter from "../../../components/layout/publicFooter";
import PublicContext from "../../../components/layout/publicContext";
import CatalogItemEthos from "./catalogItemEthos";

export default function CatalogItemHome(props) {
    const {
        variant,
        product,
        mode,
    } = useContext(CatalogItemContext);

    const {showPromoBanner} = useContext(PublicContext);

    useLayoutOptions({
        headerComponent: PublicHeader,
        footerComponent: PublicFooter,
        blur: "2px",
        //topMargin: !showPromoBanner && isMobile ? 100 : 30
        topMargin: isMobile ? 100 : 30
    });

    return (
      <React.Fragment>
          <CatalogItemDetail/>
          <PlatformSpace height={isMobile ? 30 : 100}/>
          <PlatformSpace height={isMobile ? 30 : 100}/>
          {mode !== "modal" &&
            <React.Fragment>
                {/*<CatalogItemDisclaimer/>*/}
                <RelatedVariantIndex categoryHandle={product.categoryHandle} variantId={variant.variantId}/>
                <PlatformSpace height={isMobile ? 30 : 100}/>
                <CatalogItemCategoryPitch/>
                <CatalogItemProductFAQ/>
                <CatalogItemEthos/>
                <PlatformSpace height={isMobile ? 30 : 100}/>
                {/*<CatalogItemEffects/>*/}
                {/*<PlatformSpace height={isMobile ? 30 : 100}/>*/}
                <StateMapIndex/>
                <PlatformSpace height={isMobile ? 30 : 100}/>
                <FavoriteVariantIndex/>
                {/*<PlatformSpace height={isMobile ? 30 : 100}/>*/}
                {/*<CatalogItemHowIsThisLegal/>*/}
                <PlatformSpace height={isMobile ? 30 : 100}/>
                <CatalogItemMainFAQ/>
                <PlatformSpace height={isMobile ? 30 : 100}/>
            </React.Fragment>}
      </React.Fragment>
    )
}