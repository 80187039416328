import React, {useContext} from "react";
import PlatformSpace from "../../../components/platform/platformSpace";
import {styled} from "@mui/styles";
import {useHistory} from "react-router-dom";
import OrderReceiptContext from "./orderReceiptContext";
import {Typography} from "@mui/material";
import {isMobile} from "react-device-detect";

export default function OrderReceiptHeader(props) {
    const {order} = useContext(OrderReceiptContext);
    const history = useHistory();

    return (
        <React.Fragment>
            <Image src={`${process.env.PUBLIC_URL}/images/logo_zs_h.png`} onClick={() => history.push("/")}/>
            <PlatformSpace height={30}/>
            <div>
                <Typography variant="body1" sx={{fontWeight: 500, opacity: 0.7}}>
                    ORDER #{order.orderNumber}
                </Typography>
            </div>
            <div>
                <Typography variant="body1">
                    Thank you {order.billingAddress.firstName}!
                </Typography>
            </div>
        </React.Fragment>
    );
}

const Image = styled('img')(({ selected }) => ({
    cursor: "pointer",
    width: isMobile ? "50%" : "40%"
}));