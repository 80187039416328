import {throttle} from 'lodash';
import {useEffect, useState} from "react";

export default function useThrottledScrollOffset() {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleScroll = throttle(() => {
            setOffset(window.pageYOffset);
        }, 600); // Adjust 100ms to your needs

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel();
        };
    }, []);

    return offset;
}
