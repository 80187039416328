import * as React from 'react';
import {useContext} from 'react';
import {CircularProgress, Stack, Typography, useTheme} from "@mui/material";
import {styled} from "@mui/styles";
import CheckoutSummary from "./checkoutSummary";
import CheckoutStep2Shipping from "./checkoutStep2Shipping";
import CheckoutStep3Payment from "./checkoutStep3Payment";
import CheckoutContext from "./checkoutContext";
import CheckoutStep1Information from "./checkoutStep1Information";
import useLayoutOptions from "../../components/hook/useLayoutOptions";
import PlatformSpace from "../../components/platform/platformSpace";
import CheckoutHeader from "./checkoutHeader";
import CheckoutCrumbs from "./checkoutCrumbs";
import CheckoutFooter from "./checkoutFooter";
import CheckoutControls from "./checkoutControls";
import usePlatformForm from "../../components/hook/usePlatformForm";

export default function CheckoutHomeDesktop(props) {
    const {wizardStep, processing} = useContext(CheckoutContext);
    const theme = useTheme();

    usePlatformForm(200);

    useLayoutOptions({
        headerComponent: null,
        footerComponent: null,
        saleComponent: null
    });

    return (
        <React.Fragment>
            {processing &&
                <PaneContainer justifyContent="center" alignItems="center">
                    <PlatformSpace height={200}/>
                    <Typography variant="h3" textAlign="center">
                        PROCESSING ORDER...
                    </Typography>
                    <PlatformSpace height={30}/>
                    <CircularProgress color="primary" size="5rem" />
                </PaneContainer>}
            {!processing &&
                <PaneContainer direction="row">
                    <LeftPane>
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height: "90vh !important"}}>
                            <div style={{width: "100%"}}>
                                <CheckoutHeader/>
                                <CheckoutCrumbs/>
                                <PlatformSpace height={60}/>
                                {wizardStep === 1 && <CheckoutStep1Information/>}
                                {wizardStep === 2 && <CheckoutStep2Shipping/>}
                                {wizardStep === 3 && <CheckoutStep3Payment/>}
                                <PlatformSpace height={30}/>
                                <CheckoutControls/>
                            </div>
                            <CheckoutFooter/>
                        </Stack>
                    </LeftPane>
                    <RightPane>
                        <CheckoutSummary/>
                    </RightPane>
                </PaneContainer>
            }
        </React.Fragment>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    height: "100vh"
}));