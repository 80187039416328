import * as React from 'react';
import {useState} from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {styled} from "@mui/styles";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {isMobile} from "react-device-detect";


export default function PlatformSliderButtons({tabs, changeHandler}) {
    const theme = useTheme();
    const [value, setValue] = useState("0");

    const onTabChange = (event, newValue) => {
        setValue(newValue);
        if (typeof changeHandler === "function") {
            changeHandler(tabs[newValue], newValue);
        }
    };

    return (
      <div>
          <TabContext value={value}>
              <TabButtons
                onChange={onTabChange}
                aria-label="Top charts"
                centered
                variant="standard"
                TabIndicatorProps={{
                    sx: {
                        borderRadius: "20px",
                        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
                        background: "rgba(255,255,255,0)",
                        height: "100%",
                        opacity: 1
                    }}}
              >
                  {tabs.map((tab, idx) => (
                    <TabButton key={`${tab.label}-${idx}`} label={tab.label} value={String(idx)} isFirst={idx === 0}
                               isLast={idx === tabs.length - 1}/>
                  ))}
              </TabButtons>
          </TabContext>
      </div>
    );
}

const TabButtons = styled(TabList)({
    //backgroundColor: "#f7f6f2", // Light gray background
    borderRadius: 20,
    width: "100%", // Use the full width of the screen
});

const TabText = styled(Typography)(({ theme, hover }) => ({
    fontSize: isMobile ? "0.9rem" : "inherit",
    fontFamily: "Poppins, Arial, sans-serif !important",
    textTransform: "uppercase !important",
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
    "&.MuiTypography-root": {
        fontFamily: "Poppins !important",
    },
    ...(hover && {
        color: `${theme.palette.primary.main} !important`, // Change color on hover
    }),
}));


const TabButton = styled(Tab)(({theme, isFirst, isLast}) => ({
    '&.MuiTab-root': {
        fontSize: isMobile ? "0.9rem !important" : "1.1rem !important",
        fontFamily: "Poppins, Arial, sans-serif !important",
        textTransform: "uppercase !important",
        fontWeight: 700,
        whiteSpace: 'nowrap',
        color: theme.palette.secondary.main,
        minWidth: 120,
        backgroundColor: "white",
        border: "none !important",
        '&:hover': { // Hover state for the tab
            color: "rgba(178,172,172,0.51) !important", // Keep the color white on hover
        }
    },
    '&.MuiTab-root.Mui-selected': {
        color: theme.palette.primary.main,
        borderRadius: 0,
        opacity: 1, // Ensure full opacity
        backgroundColor: "white",
    },
    ...(isFirst && {
        borderRadius: "20px 0 0 20px !important",
    }),
    ...(isLast && {
        borderRadius: "0 20px 20px 0 !important",
    }),
}));