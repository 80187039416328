import * as React from 'react';
import {useEffect, useRef} from 'react';
import {styled} from "@mui/styles";
import {Fade} from "@mui/material";

export default function HomeBackgroundVideo(props) {
    const {offset, video, backgroundOpacity} = props;
    const videoRef = useRef();

    useEffect(() => {
        videoRef.current?.load();
    }, [video]);

    return (
        <React.Fragment>
            <Fade in={true} timeout={2000}>
                <VideoPlayer ref={videoRef} loop muted width={window.innerWidth}>
                    <source src={`${process.env.PUBLIC_URL}${video}`} type="video/mp4"/>
                </VideoPlayer>
            </Fade>
            <VideoOverlay offset={offset}/>
        </React.Fragment>
    )
}

const VideoPlayer = styled('video')(({  }) => ({
    zIndex: -1,
    position: "absolute",
    opacity: "0.2 !important"
}));

const VideoOverlay = styled('div')(({  }) => ({
    width: "100%",
    height: "110%",
    zIndex: 0,
    position: "absolute",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    transition: "background 2s ease-in",
}));