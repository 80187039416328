import {useMemo} from 'react';
import formatToUSD from "../util/moneyUtil";

// Custom hook to calculate prices and discounts
const usePriceCalculation = (variant, quantity) => {
    // Calculate price, sale price, and percent off using useMemo for performance optimization
    const { formattedPrice, formattedSalePrice, percentOff } = useMemo(() => {
        const price = variant?.price * quantity;
        const salePrice = variant?.salePrice >= 0 ? variant.salePrice * quantity : null;

        const formattedPrice = formatToUSD(price);
        const formattedSalePrice = salePrice ? formatToUSD(salePrice) : null;

        const percentOff = salePrice ? Math.round(((price - salePrice) / price) * 100) : null;

        return { formattedPrice, formattedSalePrice, percentOff };
    }, [variant, quantity]);

    return { formattedPrice, formattedSalePrice, percentOff };
};

export default usePriceCalculation;
