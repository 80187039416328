import React from 'react';
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";

const AnnouncementContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: 30,
    marginBottom: 10
}));

export default function PublicAnnouncement(props) {
    return (
        <AnnouncementContainer direction="row" spacing={0} justifyContent="center" alignItems="center">
            <Typography variant="body4" sx={{color: "#f7f6f2"}}>
                Free Shipping Over $99 {!isMobile && "✦ 100 Day Satisfaction Guarantee"}
            </Typography>
        </AnnouncementContainer>
    );
}