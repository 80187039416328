import React from "react";
import {BestSellerVariantContextProvider} from "./bestSellerVariantContext";
import BestSellerVariantHome from "./bestSellerVariantHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";

export default function BestSellerVariantIndex(props) {
    return (
        <PlatformFormContextProvider name="bestSellerVariantForm">
            <BestSellerVariantContextProvider {...props}>
                <BestSellerVariantHome {...props}/>
            </BestSellerVariantContextProvider>
        </PlatformFormContextProvider>
    );
}