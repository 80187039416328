import React from 'react';
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "../platform/platformSpace";
import {STORE_LOCATIONS} from "../constants/stores";

export default function PublicStoreLocator(props) {
    return (
        <React.Fragment>
            <PlatformSpace height={15}/>
            <Stack justifyContent="center" alignItems="center" direction="row" spacing={10}>
                {STORE_LOCATIONS.map((location) => {
                    return (
                        <Stack justifyContent="center" alignItems="center" spacing={0}>
                            <Typography variant="h5">
                                {location.name?.toUpperCase()}
                            </Typography>
                            <PlatformSpace height={15}/>
                            <StoreImage src={location.image}/>
                            <Typography variant="body2">
                                {location.street1}
                            </Typography>
                            <Typography variant="body2">
                                {location.street2}
                            </Typography>
                            <Typography variant="body2">
                                {location.cityStateZip}
                            </Typography>
                            <PlatformSpace height={30}/>
                            <Typography variant="body2">
                                Phone: {location.phone}
                            </Typography>
                            <br/>
                            <Typography variant="body2">
                                {location.hours1}
                            </Typography>
                            <Typography variant="body2">
                                {location.hours2}
                            </Typography>
                            {/*<PlatformSpace height={30}/>*/}
                            {/*<QRCode src={`${process.env.PUBLIC_URL}/images/stores/westgate.png`}/>*/}
                        </Stack>
                    )
                })}
            </Stack>
            <PlatformSpace height={15}/>
        </React.Fragment>
    );
}

const QRCode = styled('img')(({ selected }) => ({
    cursor: "pointer",
    width: 150,
    height: 150,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
}));

const StoreImage = styled('img')(({ selected }) => ({
    cursor: "pointer",
    width: 200,
    height: 200,
    border: "2px solid black",
    marginBottom: 20
}));
