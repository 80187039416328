import * as React from 'react';
import {memo, useContext, useEffect, useRef, useState} from 'react';
import {styled} from "@mui/styles";
import GoogleMapReact from 'google-map-react';
import {Popper, Typography} from "@mui/material";
import axios from "axios";
import {formatAddressForMaps, getFullStateName} from "../../../components/util/addressUtil";
import OrderReceiptContext from "./orderReceiptContext";
import {isMobile} from "react-device-detect";

const OrderReceiptMap = (props) => {
    const {order} = useContext(OrderReceiptContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const addressSingleLine = formatAddressForMaps(order.shippingAddress);
    const stateFullName = getFullStateName(order.shippingAddress.state);

    return (
        <MapContainer>
            <MapOutline>
                <GoogleMapsComponent address={addressSingleLine} setAnchorEl={setAnchorEl} />
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    placement="top"
                >
                    <MapPopper>
                        <div>
                            <Typography variant="body5">
                                Shipping address
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body3">
                                {order.shippingAddress.city}, {stateFullName}
                            </Typography>
                        </div>
                    </MapPopper>
                </Popper>
            </MapOutline>
        </MapContainer>
    )
}

const MapPinComponent = ({ text, setAnchorEl }) => {
    const ref = useRef(null);

    useEffect(() => {
        setAnchorEl(ref.current);
    }, []);  // The empty dependency array makes it similar to componentDidMount

    return (
        <div
            ref={ref}
            style={{
                fontSize: 30
            }}
        >
            📍
        </div>
    );
};

const GoogleMapsComponent = ({ address, setAnchorEl }) => {
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0,
    });

    const defaultZoom = 11;

    useEffect(() => {
        const getCoordinates = async () => {
            try {
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAfDnNvLaJlFF6K4K0E0xgMMjIpquSjCN8`);
                const { lat, lng } = response.data.results[0].geometry.location;
                setCoordinates({ lat, lng });
            } catch (error) {
                console.error('Error fetching coordinates:', error);
            }
        };

        setTimeout(() => {
            getCoordinates();
        }, isMobile ? 350 : 200)
    }, [address]);

    return (
        <GoogleMapReact
            bootstrapURLKeys={{key: "AIzaSyAfDnNvLaJlFF6K4K0E0xgMMjIpquSjCN8"}}
            //defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            center={coordinates}
        >
            <MapPinComponent
                lat={coordinates.lat}
                lng={coordinates.lng}
                setAnchorEl={setAnchorEl}
            />

        </GoogleMapReact>
    );
};

const MapPopper = styled('div')(({ hover }) => ({
    padding: 12, 
    borderRadius: "8px", 
    backgroundColor: "#fff", 
    minWidth: 200, 
    textAlign: "center"
}));

const MapContainer = styled('div')(({ hover }) => ({
    height: 250,
    width: "100%",
    borderRadius: "12px 12px 0 0",
    overflow: "hidden",
    position: "relative"
}));

const MapOutline = styled("div")(({ theme }) => ({
    border: "1px solid rgba(59,42,84, 0.3)",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0
}));

export default memo(OrderReceiptMap);