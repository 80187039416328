import React from 'react';
import {Box, CardContent, CardMedia, Grid, Paper, Typography} from '@mui/material';
import {isMobile} from "react-device-detect";
import {styled} from "@mui/styles";
import MicrobesIcon from '@mui/icons-material/Biotech';
import NoTillIcon from '@mui/icons-material/Grass';
import PlatformSpace from "../../../components/platform/platformSpace";
import {Agriculture} from "@mui/icons-material";

const StyledSection = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
}));

const FeatureGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const StyledCardMedia = styled(CardMedia)({
    width: "100%",
    borderRadius: '20px',
});

const StyledCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const StyledIconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
});

const StyledIcon = styled(Box)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fontSize: '5rem',
    },
}));

const FeatureItem = ({ icon, title, description, imageUrl }) => {
    return (
        <Grid item xs={12} sm={4}>
            <StyledPaper elevation={0}>
                <StyledCardMedia
                    component="img"
                    src={imageUrl || "https://via.placeholder.com/150/CFCFCF/000000?text=+"}
                    alt={title}
                />
                <StyledCardContent>
                    <StyledIconContainer>
                        <StyledIcon>
                            {icon}
                        </StyledIcon>
                    </StyledIconContainer>
                    <Typography variant="body1" component="h5">{title}</Typography>
                    <Typography variant="body3">{description}</Typography>
                </StyledCardContent>
            </StyledPaper>
        </Grid>
    );
};

export default function BlogOurProcessOrganicCultivation() {
    const features = [
        {
            icon: <MicrobesIcon />,
            title: 'Beneficial Microbes',
            imageUrl: `${process.env.PUBLIC_URL}/images/blog/OurProcessImage1.jpg`,
            description: 'Chosen microbes naturally shield our plants from termites and pests without chemical pesticides.'
        },
        {
            icon: <NoTillIcon />,
            title: 'Natural Compost',
            imageUrl: `${process.env.PUBLIC_URL}/images/blog/OurProcessImage3.jpg`,
            description: 'Locally sourced compost is used to provide nutrients instead of synthetic fertilizers.'
        },
        {
            icon: <Agriculture />,
            title: 'Sustainable No-Till Farming',
            imageUrl: `${process.env.PUBLIC_URL}/images/blog/OurProcessImage2.jpg`,
            description: 'This method minimizes erosion and enhances soil biological activity and organic matter.'
        }
    ];

    return (
        <StyledSection sx={{ px: isMobile ? 1 : 10, maxWidth: isMobile ? "100vw" : '100vw', mx: isMobile ? 1 : 'auto' }}>
            <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}  gutterBottom>High-Quality Cannabis Cultivation</Typography>
            <Typography variant="body1" gutterBottom>
                Our goal is to help people stay present, feel grounded, and connect with themselves, starting with our production processes.
            </Typography>
            <PlatformSpace height={isMobile ? 30 : 90} />
            <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}  gutterBottom>Organic & Sustainable Farming</Typography>
            <Typography variant="body1">
                Located just outside Austin, Texas, in the Texas Wine Country, a small, local farm we partner with grows the hemp for our products. This area is renowned for its ample sunlight, mineral-rich sandy loam, and cool nights that support growth.

                Each plant begins its journey in a controlled indoor environment before being transplanted outdoors to thrive.
            </Typography>
            <PlatformSpace height="30" />
            <FeatureGrid container spacing={2}>
                {features.map((feature, index) => (
                    <FeatureItem key={index} {...feature} />
                ))}
            </FeatureGrid>
        </StyledSection>
    );
}
