import React from 'react';
import CatalogFilterDesktop from "./catalogFilterDesktop";
import CatalogFilterMobile from "./catalogFilterMobile";
import {isDesktop, isMobile} from "react-device-detect";

export default function CatalogFilter(props) {
    return (
        <React.Fragment>
            {isDesktop && <CatalogFilterDesktop/>}
            {isMobile && <CatalogFilterMobile/>}
        </React.Fragment>
    );
}