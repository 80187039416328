import React, {useContext} from 'react';
import {Typography} from '@mui/material';
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import CatalogItemContext from "./catalogItemContext";
import {isMobile} from "react-device-detect";


export default function CatalogItemCategoryPitch() {
    const {
        category
    } = useContext(CatalogItemContext);

    return (
        <PlatformMarginBox>
            <Typography variant={isMobile ? "h4" : "h3"} gutterBottom align="center"
                        sx={{fontFamily: "Poppins !important", fontWeight: 800}}>
                {category?.description?.toUpperCase()}
            </Typography>
        </PlatformMarginBox>
    );
}
