import PublicLayout from "../../../components/layout/publicLayout";
import React from "react";
import FdaDisclaimerHome from "./fdaDisclaimerHome";

export default function FdaDisclaimerIndex(props) {
    return (
        <PublicLayout>
            <FdaDisclaimerHome/>
        </PublicLayout>
    );
}