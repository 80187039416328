import React, {useEffect, useState} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import PlatformButton from "../../components/platform/platformButton";
import {useHistory} from "react-router-dom";

const Home100Legal = () => {
    const theme = useTheme();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [scrollPosition, setScrollPosition] = useState(0);
    const [opacity, setOpacity] = useState(1);

    const onShopAll = () => {
        history.push("/catalog");
    };

    const handleScroll = () => {
        const position = window.scrollY;
        const windowHeight = window.innerHeight;

        // Adjust fade out timing to start sooner
        const fadeOutStart = windowHeight * 0.3; // Start fading out at 30% of the viewport
        const fadeOutEnd = windowHeight * 0.8; // Fully fade out by 80% of the viewport

        // Calculate new opacity based on gumdrop position
        const newOpacity = position <= fadeOutStart
            ? 1
            : Math.max(1 - (position - fadeOutStart) / (fadeOutEnd - fadeOutStart), 0);

        setScrollPosition(position);
        setOpacity(newOpacity);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Container>
            <ParallaxWrapper>
                <GumdropImage
                    src={`${process.env.PUBLIC_URL}/images/100pctlegal/GummyTopLeft.png`}
                    alt="Gummy Top Left"
                    style={{ transform: `translateY(${scrollPosition * 0.4}px)`, opacity }}
                    top="15%"
                    left="25%"
                    zIndex={isMobile ? 1 : 3}
                />
                <GumdropImage
                    src={`${process.env.PUBLIC_URL}/images/100pctlegal/GummyTopCenter.png`}
                    alt="Gummy Top Center"
                    style={{ transform: `translateY(${scrollPosition * 0.2}px)`, opacity }}
                    top="10%"
                    left="45%"
                    zIndex={isMobile ? 1 : 3}
                />
                <GumdropImage
                    src={`${process.env.PUBLIC_URL}/images/100pctlegal/GummyTopRight.png`}
                    alt="Gummy Top Right"
                    style={{ transform: `translateY(${scrollPosition * 0.4}px)`, opacity }}
                    top="20%"
                    left="70%"
                    zIndex={isMobile ? 1 : 3}
                />
                <GumdropImage
                    src={`${process.env.PUBLIC_URL}/images/100pctlegal/GummyBottomLeft.png`}
                    alt="Gummy Bottom Left"
                    style={{ transform: `translateY(${scrollPosition * 0.1}px)`, opacity, marginTop: '200px' }}
                    top="85%"
                    left="35%"
                    zIndex={isMobile ? 1 : 3}
                />
                <GumdropImage
                    src={`${process.env.PUBLIC_URL}/images/100pctlegal/GummyBottomRight.png`}
                    alt="Gummy Bottom Right"
                    style={{ transform: `translateY(${scrollPosition * 0.1}px)`, opacity, marginTop: '200px' }}
                    top="90%"
                    left="60%"
                    zIndex={isMobile ? 1 : 3}
                />
            </ParallaxWrapper>

            {/* Centered Purple Text */}
            <TopImageContainer>
                <Text variant={isMobile ? 'h3' : 'h1'}>
                    100% FEDERALLY<br/>LEGAL THC FROM<br/>SMALL U.S. FARMS
                </Text>
            </TopImageContainer>

            {/* New Section: Desktop/Mobile Responsive Text */}
            <TextSection>
                {isMobile ? (
                    <Box>
                        <TextWithBullet>DISCREET DELIVERY</TextWithBullet>
                        <TextWithBullet>NO MED CARD REQUIRED</TextWithBullet>
                        <TextWithBullet>TRUSTED BY 50K PEOPLE</TextWithBullet>
                    </Box>
                ) : (
                    <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "Poppins", color: "#333" }}>
                        DISCREET DELIVERY ✦ NO MED CARD REQUIRED ✦ TRUSTED BY 50K PEOPLE
                    </Typography>
                )}
            </TextSection>

            {/* Bottom Row: Button */}
            <ButtonWrapper>
                <PlatformButton size={'md'} onClick={onShopAll}>
                    SHOP NOW
                </PlatformButton>
            </ButtonWrapper>
        </Container>
    );
};

// Styled components
const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(0, 4),
}));

const TopImageContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    position: "relative",
    zIndex: 2,
}));

const TextSection = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(2),
    textAlign: "center",
}));

const TextWithBullet = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    "&::before": {
        content: '"✦"',
        paddingRight: theme.spacing(1),
        fontSize: "1.2rem",
    },
    marginBottom: theme.spacing(1),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
}));

const Text = styled(Typography)(({ theme }) => ({
    lineHeight: 0.9,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    textAlign: 'center',
    textShadow: '2px 2px 30px rgba(255, 255, 255, 0.5)', // Added text shadow
    [theme.breakpoints.up('md')]: {
        fontSize: '5rem',
    },
}));

const ParallaxWrapper = styled(Box)({
    position: "relative",
    width: "100%",
    height: "0px",
});

const GumdropImage = styled("img")(({ top, left, zIndex }) => ({
    position: "absolute",
    top: top,
    left: left,
    width: "100px",
    height: "auto",
    zIndex: zIndex || 1,
    transition: "transform 0.1s ease-out, opacity 0.5s ease-out",
}));

export default Home100Legal;
