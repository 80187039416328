import axios from 'axios';
import {retrieveString} from "../shared/util/storageUtil";

const downloadPdf = async (pdf) => {
    try {
        const isLocal = !process.env.REACT_APP_HOST || process.env.REACT_APP_HOST === "localhost:5001";
        const protocol = isLocal ? "http" : "https";
        const baseUrl = process.env.REACT_APP_HOST || "localhost:5001";
        const url = `${protocol}://${baseUrl}/media/${pdf?.id}/file`;

        const response = await axios.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${retrieveString("token")}`
            }
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);

        // Create a link and trigger a download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `document-${pdf?.id}.pdf`); // or any other filename
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Clean up the URL Object
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error("Error downloading PDF", error);
    }
};

export default downloadPdf;
