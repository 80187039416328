import {useEffect} from "react";

export function useLockBodyScroll(locked = true) {
    useEffect(() => {
        if (locked) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup method
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [locked]);
}