import React from "react";
import {Typography} from "@mui/material";

export default function FavoritesEmpty(props) {
    return (
        <div>
            <div style={{padding: 80, marginLeft: "auto", marginRight: "auto", textAlign: "center", backgroundColor: "#fbfbfd"}}>
                <Typography variant="h6" style={{textTransform: "none", fontWeight: "bold"}}>
                    You currently don't have any favorites.
                </Typography>
            </div>
        </div>
    );
}