import React, {useState} from 'react';
import {Box, Grid, Link, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {styled} from "@mui/styles";

export default function PublicLearnMenu(props) {
    const theme = useTheme();
    const [imageSrc, setImageSrc] = useState("/images/blog/IsGreenbeltLegal.jpg");
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Define the links and their corresponding images
    const links = [
        {
            url: "/blog/how-is-this-legal",
            label: "Is Greenbelt Legal?",
            img: `/images/blog/IsGreenbeltLegal.jpg`
        },
        {
            url: "/blog/what-is-thca",
            label: "What is THCa?",
            img: `/images/blog/WhatIsTHCA.jpg`
        },
        {
            url: "/blog/our-cannabinoids",
            label: "What Are Cannabinoids?",
            img: `/images/blog/WhatAreCannabinoids.jpg`
        },
        {
            url: "/blog/high-quality",
            label: "What Makes Greenbelt So High Quality?",
            img: `/images/blog/WhatMakesGreenbeltQuality.jpg`
        }
    ];

    return (
        <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
            <LearnSection>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <ImagePaper elevation={isSmallScreen ? 0 : 4}>
                            <Image
                                src={`${process.env.PUBLIC_URL}${imageSrc}`}
                                alt="Cannabis plant"
                            />
                        </ImagePaper>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <LearnLinks>
                            <Typography variant="h8" gutterBottom sx={{
                                fontFamily: "Poppins !important",
                                fontWeight: 900,
                                color: theme.palette.secondary.main,
                            }}>
                                LEARN
                            </Typography>
                            {links.map((link, index) => (
                                <StyledLink
                                    key={index}
                                    href={link.url}
                                    onMouseOver={() => setImageSrc(link.img)}
                                    onMouseOut={() => setImageSrc("/images/hh/IsGreenbeltLegal.jpg")}
                                >
                                    {link.label}
                                </StyledLink>
                            ))}
                        </LearnLinks>
                    </Grid>
                </Grid>
            </LearnSection>
        </Stack>
    );
}

const LearnSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    width: "90vw"
}));

const ImagePaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: '100%',           // Takes up full width of the grid
    aspectRatio: '1 / 1',    // Ensures a perfect square aspect ratio
    position: 'relative',    // Necessary for absolute positioning of the image
}));

const Image = styled('img')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',      // Ensures the image covers the entire square and maintains aspect ratio
    objectPosition: 'center',// Centers the image
}));

const LearnLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontFamily: "Poppins !important",
    fontWeight: "bold",
    fontSize: "2rem",
    cursor: "pointer !important", // Ensure cursor is pointer
    textDecoration: "none !important",
    '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.main
    },
    marginTop: "15px !important"
}));