import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import PublicHeaderTab from "./publicHeaderTab";
import {useHistory, useParams} from "react-router-dom";
import {useAppContext} from "../../appContext";

export default function PublicHeaderTabs({offset}) {
    const history = useHistory();
    const {category} = useParams();
    const {tabIndex, setTabIndex, categories} = useAppContext();

    const onClick = (category, idx) => {
        setTabIndex(idx);
        history.push(`/catalog/${category.handle.toLowerCase()}`);
    };

    useEffect(() => {
        const matchedCategory = categories?.findIndex(cat => cat.handle.toLowerCase() === category?.toLowerCase());
        if (matchedCategory !== -1) {
            setTabIndex(matchedCategory);
        }
    }, [category, categories, setTabIndex]);

    return (
        <Grid container justifyContent="center" spacing={{ xs: 0, md: 1 }}>
            {categories?.map((category, idx) => (
                <Grid item key={idx}>
                    <PublicHeaderTab key={idx} text={category.name} onClick={() => onClick(category, idx)}
                                     selected={tabIndex === idx}/>
                </Grid>
            ))}
        </Grid>
    );
}