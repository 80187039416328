import React, {useContext, useState} from "react";
import OrderStatusApi from "./orderStatusApi";
import PlatformFormContext from "../../../components/platform/platformFormContext";

export const OrderStatusContext = React.createContext(null);
export default OrderStatusContext;

export const OrderStatusContextProvider = ({children}) => {
    const [order, setOrder] = useState(null);
    const [processing, setProcessing] = useState(false);
    const {validateForm, getForm} = useContext(PlatformFormContext);

    const onOrderStatus = () => {
        if (validateForm()) {
            setProcessing(true);
            OrderStatusApi.getOrderStatus(getForm(), (data) => {
                setOrder(data);
                setProcessing(false);
            })
        }
    }

    return (
        <OrderStatusContext.Provider
            value={{
                order,
                onOrderStatus,
                processing,
                setProcessing
            }}
        >
            <>{children}</>
        </OrderStatusContext.Provider>
    );
}