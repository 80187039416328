import React, {useContext} from "react";
import CheckoutContext from "./checkoutContext";
import {Stack} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import PlatformFormContext from "../../components/platform/platformFormContext";
import useScrollToTop from "../../components/hook/useScrollToTop";
import {useHistory} from "react-router-dom";
import PlatformButton from "../../components/platform/platformButton";
import {isMobile} from "react-device-detect";

const STEPS = [{
    nextButton: "Continue to shipping",
    backButton: "Return to cart",
    backUrl: "/"
},{
    nextButton: "Continue to payment",
    backButton: "Return to information",
},{
    nextButton: "Pay now",
    backButton: "Return to shipping",
}]

export default function CheckoutControls(props) {
    const {wizardStep, setWizardStep, onCheckout, processing, onWizardStep} = useContext(CheckoutContext);
    const {validateForm} = useContext(PlatformFormContext);
    const history = useHistory();

    if (!isMobile) {
        useScrollToTop(wizardStep);
    } else {
        setWizardStep(3);
    }

    const onBack = () => {
        if (wizardStep === 1) {
            history.push(STEPS[0].backUrl);
        } else if (validateForm() && wizardStep > 1) {
            setWizardStep(wizardStep - 1);
        }
    }

    const onNext = () => {
        if (validateForm(isMobile)) {
            if (wizardStep < 3) {
                onWizardStep(wizardStep + 1);
            } else {
                onCheckout();
            }
        }
    }

    return (
        <Stack justifyContent={isMobile ? "center" : "space-between"} direction="row" alignItems="center" spacing={2}>
            {!isMobile &&
                <CartLink onClick={onBack}>
                    <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={1}>
                        <BackArrow/>
                        <div>{STEPS[wizardStep - 1]?.backButton}</div>
                    </Stack>
                </CartLink>
            }
            <PlatformButton size="lg" onClick={onNext} sx={{width: isMobile ? "100%" : "50%"}} disabled={processing}>
                {STEPS[wizardStep - 1]?.nextButton}
            </PlatformButton>
        </Stack>
    );
}

const BackArrow = styled(ArrowBackIos)(({ theme }) => ({
    fontSize: "0.8rem !important",
    color: "#5492D9FF !important",
}));

const CartLink = styled("div")(({ theme }) => ({
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#5492D9FF",
    backgroundImage: "none !important",
    fontSize: "0.9rem",
    cursor: "pointer",
    textDecoration: "underline"
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    height: 65,
    maxWidth: 250,
    backgroundImage: "none !important",
}));