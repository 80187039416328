import {createTheme} from "@mui/material/styles";
import {isBrowser, isMobile} from "react-device-detect";

const theme = createTheme();

const homeStyles = {
    root: {
        padding: 0
    },
    favoritesFilterMobile: {
        textAlign: "center",
        padding: 10,
        borderRight: "1px solid rgba(0, 0, 0, .15)",
        borderBottom: "1px solid rgba(0, 0, 0, .15)",
        cursor: "pointer",
        minHeight: "5vh"
    },
    favoritesGridItem: {
        padding: 20,
        borderRight: "1px solid rgba(0, 0, 0, .15)",
        borderBottom: "1px solid rgba(0, 0, 0, .15)",
        cursor: "pointer",
        height: isBrowser ? "85vh" : "40vh",
        minHeight: isBrowser ? "75vh" : "40vh",
        maxHeight: isBrowser ? "75vh" : "40vh",
    },
    favoritesItemImage: {
        height: isMobile ? 150 : 400
    },
    favoritesItemDetails: {
        fontSize: 13,
        opacity: 0.47,
        textAlign: "center",
        padding: "5px 0 5px 0",
    },
    favoritesItemPrice: {
        fontSize: 17,
        textAlign: "center",
        padding: "5px 0 5px 0"
    },
    favoritesItemName: {
        fontSize: 20,
        fontWeight: 400,
        textAlign: "center",
        padding: "5px 0 5px 0",
        minHeight: isMobile ? "10vh" : 0,
    }
}

export default homeStyles;
