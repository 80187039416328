const externalStyles = {
    root: {
        //backgroundColor: "rgba(92,219,148,0.5)",
        //backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg_hex.png)`,
        backgroundImage: `radial-gradient(ellipse at center,rgba(0,0,0,0) 0%,#f3f3f3 90%),url(${process.env.PUBLIC_URL}/images/bg_hex.png)`,
        backgroundColor: "#ededef",//"#7221f5"
    },
    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
        textTransform: "none",
        cursor: "pointer"
    },
    publicMenu: {

    },
    publicMenuAppBar: {
        backgroundColor: "white",
      //  backgroundColor: "#efef", //"#78c298",//7221f5=purple   21f5c3=green
        color: "black"
    },
    publicToolbar: {
        color: "black",
        minHeight: 0,
        height: 75,
        paddingLeft: "5%",
        paddingRight: "5%"
    },
};

export default externalStyles;