import {useContext, useEffect} from 'react';
import PlatformFormContext from "../platform/platformFormContext";

export default function usePlatformForm(timeout) {
    const context = useContext(PlatformFormContext);
    const {form} = context;

    useEffect(() => {
        form.current && form.current.reloadForm(timeout);
    }, [form]);

    return context;
}