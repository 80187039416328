import React from "react";

export const HomeContext = React.createContext({});
export default HomeContext;

export const HomeContextProvider = ({children}) => {


    return (
        <HomeContext.Provider
            value={{
            }}
        >
            <>{children}</>
        </HomeContext.Provider>
    );
}