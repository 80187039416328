import API from "api";

export default {

    getRandomRelatedVariants(categoryHandle, variantId, successCallback, errorCallback) {
        if (!categoryHandle || !variantId) {
            return;
        }

        API.GET({
            url: `/index/products/variants/related/random?categoryHandle=${categoryHandle}&variantId=${variantId}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    }
}
