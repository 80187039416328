import PublicLayout from "components/layout/publicLayout";
import React from "react";
import LocatorHome from "./locatorHome";

export default function LocatorIndex(props) {
    return (
        <PublicLayout>
            <LocatorHome/>
        </PublicLayout>
    );
}