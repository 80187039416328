import React from 'react';
import {makeStyles} from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styles from "./externalStyles";
import {useHistory} from "react-router-dom";
import PlatformButton from "components/platform/platformButton";
import PlatformLink from "components/platform/platformLink";

const useStyles = makeStyles(styles);

export default function ExternalMenu() {
    const classes = useStyles();
    const history = useHistory();

    const onHome = () => {
        history.push({
            pathname: "/",
        })
    }

    const onSignUp = () => {
        history.push({
            pathname: "/signup",
        })
    }

    const onSignIn = () => {
        history.push({
            pathname: "/signin",
        })
    }

    return (
        <div className={classes.publicMenu}>
            <AppBar
                classes={{
                    root: classes.publicMenuAppBar,
                }}
                elevation={0}
                position="static">
                <Toolbar classes={{root: classes.publicToolbar}}>
                    <IconButton style={{display: "none"}} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <img height={50} alt="qcsc" src={`${process.env.PUBLIC_URL}/images/logo_gb2.png`} onClick={onHome}/>
                    </div>
                    <PlatformLink onClick={onSignIn}>Sign in</PlatformLink>
                    <PlatformButton onClick={onSignUp} color="inherit">Sign up</PlatformButton>
                </Toolbar>
            </AppBar>
        </div>
    );
}
