import React, {useContext, useRef, useState} from 'react';
import {Chip, Link, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import {truncateAfterNCharacters} from "../../../../components/util/stringUtil";
import PlatformCartSplitButton from "../../../../components/platform/platformCartSplitButton";
import {useHistory, useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";
import useImageUrl from "../../../../components/hook/useImageUrl";
import formatToUSD from "../../../../components/util/moneyUtil";
import CartContext from "../../../cart/cartContext";
import IconButton from "@mui/material/IconButton";
import AppContext from "../../../../appContext";

export default function GridTile({product, mode = "tile", showBorders = true}) {
    const history = useHistory();
    const {category} = useParams();
    const containerRef = useRef(null);
    const [hover, setHover] = useState(false);
    const [hoverAlert, setHoverAlert] = useState(false);
    const {addToCart, buyItNow} = useContext(CartContext);
    const {onOpenGridItem} = useContext(AppContext);
    const variant = product.variant;
    const isSoldOut = false;//product.soldOut === true;

    // useEffect(() => {
    //     const unlisten = history.listen((location) => {
    //         if (location.pathname === `/catalog/${category}/product/${product.productId}/variant/${variant.variantId || product.variantId}/${variant.slug}`) {
    //             history.go(0);
    //         }
    //     });
    //
    //     return () => {
    //         unlisten();
    //     };
    // }, [history, category, product.productId, variant.variantId, variant.slug]);

    const onAddToCart = (productId, variantId, callback) => {
        const payload = {
            "productId": productId,
            "variantId": variantId,
            "quantity": 1
        }
        addToCart(payload, callback);
    }

    const onBuyItNow = (productId, variantId, callback) => {
        const payload = {
            "productId": productId,
            "variantId": variantId,
            "quantity": 1
        }
        buyItNow(payload, callback);
    }

    const onDetail = () => {
        history.push({
            pathname: `/catalog/${category}/product/${product.productId}/variant/${variant.variantId || product.variantId}/${variant.slug}`,
        });
    };

    const onMouseIn = () => setHover(true);
    const onMouseOut = () => setHover(false);
    const onMouseInAlert = () => setHoverAlert(true);
    const onMouseOutAlert = () => setHoverAlert(false);

    const onAddItemToCart = (callback) => {
        if (variant.alwaysShow === true) {
            onDetail();
        } else {
            onAddToCart(variant.productId, variant.variantId, callback);
        }
    }

    const onBuyItemNow = (callback) => {
        if (variant.alwaysShow === true) {
            onDetail();
        } else {
            onBuyItNow(variant.productId, variant.variantId, callback);
        }
    }

    const imageUrl = useImageUrl(variant, 0);

    let price, salePrice, percentOff;

    if (product.priceLow <= 0) {
        // Check if the variant has a sale price
        if (variant.salePrice && variant.salePrice >= 0) {
            salePrice = formatToUSD(variant.salePrice);
            percentOff = Math.round(((variant.price - variant.salePrice) / variant.price) * 100);
        }
        price = formatToUSD(variant.price);
    } else {
        // Check if there's a price range
        price = product.priceLow !== product.priceHigh
            ? `${formatToUSD(product.priceLow)} - ${formatToUSD(product.priceHigh)}`
            : formatToUSD(product.priceLow);
    }

    const generateVariantNameAsTitle = (product, variant) => {
        if (!product || !product.variantOptions || !variant) {
            return;
        }

        if (product.aggregated) {
            // Remove items from variantOptionValues where the name matches and isAggregated is false
            variant.variantOptionValues = variant.variantOptionValues.filter(value => {
                // Find the corresponding product option by name
                const matchingOption = product.variantOptions.find(option => option.name === value.name);

                // Keep the value only if no matching option or if isAggregated is true
                return matchingOption ? matchingOption.isAggregated : true;
            });
        }

        const variantOptionsMap = new Map();
        // Create a map of displayName to sortOrder from variantOptions
        product.variantOptions.forEach(option => {
            variantOptionsMap.set(option.displayName, option.sortOrder);
        });

        // Sort variantOptionValues based on the sortOrder from the map
        const sortedOptions = variant.variantOptionValues.sort((a, b) => {
            return variantOptionsMap.get(a.label) - variantOptionsMap.get(b.label);
        });

        // Concatenate the values of the first three options
        const optionValues = sortedOptions.slice(0, 3).map(option => option.value);

        const truncateIdx = isMobile ? 16 : 18;

        // Format the values for display
        if (optionValues.length === 0) {
            return <React.Fragment/>;
        } else if (optionValues.length === 1) {
            return (
                <React.Fragment>
                    <Title>{truncateAfterNCharacters(optionValues[0], truncateIdx)}</Title>
                    <Title>&nbsp;</Title>
                </React.Fragment>
            );
        } else if (optionValues.length === 2) {
            return (
                <React.Fragment>
                    <Title>{truncateAfterNCharacters(optionValues[0], truncateIdx)}</Title>
                    <Title><OptionChip size="small" label={optionValues[1]}/></Title>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Title>{truncateAfterNCharacters(optionValues[0], truncateIdx)}</Title>
                    <Title>
                        <OptionChip size="small" label={optionValues[1]}/>&nbsp;
                        <OptionChip size="small" label={optionValues[2]}/>
                    </Title>
                </React.Fragment>
            );
        }
    }

    const onAddItemToCartDirectly = () => {
        const payload = {
            productId: product.productId,
            variantId: product.variant.variantId || product.variantId,
            quantity: 1
        };
        addToCart(payload);
    };

    const titleBlock = generateVariantNameAsTitle(product, variant);

    const onOpenCatalogItem = () => {
        onOpenGridItem(product, variant);
    }

    const onGoToProduct = () => {
        history.push(`/catalog/${category}/product/${product.productId}/variant/${variant.variantId || product.variantId}/${variant.slug}`)
    }

    return (
        <TileContainer ref={containerRef} onMouseEnter={onMouseIn} onMouseLeave={onMouseOut} mode={mode}
                       hover={+hover} showBorders={showBorders}>
            <Link to={`/catalog/${category}/product/${product.productId}/variant/${variant.variantId || product.variantId}/${variant.slug}`}>
                <ImageContainer mode={mode} hover={+hover}>
                    <Image src={imageUrl} onClick={onDetail} />
                    {isSoldOut && <SoldOutBanner>Sold Out</SoldOutBanner>}
                    {variant.salePrice !== null && (
                        <OnSaleStickerBanner imageUrl={`${process.env.PUBLIC_URL}/images/catalog/SaleSticker.png`} text={`${percentOff}% OFF!`} />
                    )}
                    <PlusButton onClick={onOpenCatalogItem}>
                        <Add sx={{ color: "white", fontSize: 40 }} />
                    </PlusButton>
                </ImageContainer>
            </Link>
            <ContentContainer showBorders={showBorders}>
                <Stack justifyContent="center" alignItems="center" onClick={onGoToProduct}>
                    <Line1>{truncateAfterNCharacters(product.productName, isMobile ? 22 : 26)}</Line1>
                    <Line2>{titleBlock}</Line2>
                    {salePrice ? (
                        <SubTitle>
                            {salePrice}&nbsp;<SalePrice>{price}</SalePrice>
                        </SubTitle>
                    ) : (
                        <SubTitle>{price}</SubTitle>
                    )}
                </Stack>
                {(!isSoldOut && variant.alwaysShow !== true && !isMobile) &&
                    <PlatformCartSplitButton
                        cartHandler={onAddItemToCart}
                        buyNowHandler={onBuyItemNow}
                        height={35}
                        tiled
                        absolute
                        containerRef={containerRef}
                        hover={+hover}
                        hoverHandler={(hover) => setHover(hover)}
                    />
                }
            </ContentContainer>
        </TileContainer>
    );
}

const OnSaleStickerBanner = ({ imageUrl, text }) => {
    return (
        <StickerContainer>
            <StickerImage src={imageUrl} alt="On Sale Sticker" />
            <StickerText>{text}</StickerText>
        </StickerContainer>
    );
};

// Styled container for the sticker banner
const StickerContainer = styled('div')({
    position: 'absolute',
    top: 40,  // Adjust to position the banner at the top left
    left: -5,  // Adjust to position the banner to the left
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(-25deg)',
    transformOrigin: 'top left', // Adjust this to rotate around the top-left corner
    zIndex: 2, // Make sure it appears above the main content
});

// Styled image for the sticker background
const StickerImage = styled('img')({
    width: 100,  // Set appropriate width for the sticker
    height: 'auto',  // Maintain aspect ratio
    display: 'block',
});

// Styled text inside the sticker
const StickerText = styled('div')(({ theme }) => ({
    position: 'absolute',
    fontWeight: 600,
    color: 'white',  // Adjust based on the sticker image for readability
    textTransform: 'uppercase',
    fontSize: '1em',
    textAlign: 'center',
}));

const OptionChip = styled(Chip)(({theme}) => ({
    //backgroundColor: "rgb(170,224,170) !important",
    backgroundColor: "rgba(255, 215, 157, 1)"
}));

const AlertsButton = styled(Button)(({ selected, hoverAlert }) => ({
    textAlign: "center",
    cursor: "pointer",
    backdropFilter: "blur(20px)",
    position: "absolute !important",
    width: "50px !important",
    height: "50px !important",
    minWidth: "0 !important",
    padding: "0 !important",
    right: 10,
    top: 10,
    borderRadius: "50% !important",
    background: "rgba(255, 255, 255, 0.2) !important",
    ...(hoverAlert && {
        background: "rgba(255, 255, 255, 1) !important",
    }),
}));

// Adjust styles based on the mode
const TileContainer = styled('div')(({ theme, mode, hover, showBorders }) => ({
    zIndex: 2,
    position: "relative",
    cursor: "pointer",
    boxShadow: "rgba(183, 132, 255, 0.15) 0px 6px 25px 0px",
    borderRadius: 20,
    width: isMobile ? "100%" : 250, // TODO check this on landscape
    overflow: "hidden",
    transition: ".3s ease-in-out",
    border: `${showBorders ? '1px' : 0} solid ${theme.palette.text.main}`,
    //margin: mode === 'compact' ? '5px' : '5px',  // Adjust margins based on mode
    ...(mode === "tile" && {
        transform: !hover ? "translateY(0rem)" : "translateY(-0.4rem)",
    }),
}));

const SoldOutBanner = styled('div')(() => ({
    position: 'absolute',
    bottom: 100,
    right: -40,
    //backgroundColor: 'red',
    textTransform: "uppercase",
    backgroundImage: "linear-gradient(to top, rgb(41, 31, 55), rgb(41, 31, 55), rgb(2, 2, 3))",
    color: 'white',
    padding: '10px 20px', // Increased padding
    width: 200, // You can set a specific width if you want
    fontSize: '1em', // Larger font size
    fontWeight: 600,
    transform: 'rotate(-45deg)',
    transformOrigin: 'bottom right', // Adjust this if needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
}));

const OnSaleBanner = styled('div')(({theme}) => ({
    position: 'absolute',
    top: 100, // Adjust to move the banner to the top
    left: -40, // Adjust to move the banner to the left
    textTransform: "uppercase",
    backgroundColor: "rgba(170,224,170,0.3) !important",
    color: "rgb(22,108,75)",
    padding: '5px 5px', // Increased padding
    width: 200, // You can set a specific width if you want
    fontSize: '1em', // Larger font size
    fontWeight: 600,
    transform: 'rotate(-45deg)',
    transformOrigin: 'top left', // Changed to adjust the rotation point
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
}));

const ImageContainer = styled('div')(({ mode, hover }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px 20px 0 0",
    overflow: "hidden",
    width: "100%", // Full width to contain the image and button
    height: 225, // Fixed height
    transition: ".3s ease-in-out",
}));

const PlusButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: isMobile ? -40 : -90,
    bottom: -70,
    zIndex: 10, // Ensure it's above other elements
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.secondary.main} !important`, // Use palette.secondary.main for background color
    '&:hover': {
        backgroundColor: `${theme.palette.secondary.main} !important`, // Darken on hover
    },
    borderRadius: '50%', // Make it circular
    padding: '10px', // Adjust padding to increase the clickable area
    width: '48px', // Fixed width
    height: '48px', // Fixed height to maintain the circle shape
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const Image = styled('img')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "20px 20px 0 0",
}));

const ContentContainer = styled("div")(({ theme, showBorders }) => ({
    //backgroundImage: "linear-gradient(to top, rgb(233, 237, 235), rgb(205, 215, 209))",
    borderTop: `${showBorders ? '1px' : 0} solid ${theme.palette.secondary.main}`,
    //backgroundColor: "#f7f6f2",
    width: "100%",
    height: 130,
    paddingTop: 10,
    position: "relative",
    zIndex: 6001
}));

const Title = styled('div')(({ theme }) => ({
    fontWeight: 600,
    textAlign: "center",
    fontSize: isMobile ? "1rem" : "1rem",
    color: theme.custom.primaryTextColor,
    marginBottom: 5,
    marginTop: 5
}));

const SalePrice = styled('span')(({ theme }) => ({
    fontWeight: 400,
    fontSize: isMobile ? "1rem" : "1.2rem",
    color: "green",
    textDecoration: "line-through"
}));

const SubTitle = styled('div')(({ theme }) => ({
    fontSize: isMobile ? "1rem" : "1.2rem",
    color: theme.custom.primaryTextColor,
    fontWeight: 900,
    marginBottom: 2,
}));

const Line1 = styled('div')(({ theme }) => ({
    fontWeight: 900,
    textTransform: "uppercase",
    fontSize: isMobile ? "0.8rem" : "0.9rem",
    color: "rgb(100, 109, 117)",
}));

const Line2 = styled('div')(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: isMobile ? "0.8rem" : "1rem",
    color: "rgb(100, 109, 117)",
}));