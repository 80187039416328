import PublicLayout from "components/layout/publicLayout";
import React from "react";
import CatalogHome from "./catalogHome";
import {CatalogContextProvider} from "./catalogContext";
import {PlatformFormContextProvider} from "../../components/platform/platformFormContext";

export default function CatalogIndex(props) {
    return (
        <PublicLayout>
            <PlatformFormContextProvider name="catalogForm">
                <CatalogContextProvider>
                    <CatalogHome/>
                </CatalogContextProvider>
            </PlatformFormContextProvider>
        </PublicLayout>
    );
}