import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import styles from "./cartStyles";
import {Alert, Typography} from "@mui/material";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformBox from "../../components/platform/platformBox";
import {isMobile} from "react-device-detect";
import Grid from "@mui/material/Grid";
import PlatformButton from "../../components/platform/platformButton";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function CartHeader(props) {
    const [cartError, setCartError] = useState("");
    const history = useHistory();
    const classes = useStyles();

    const onCompleteReservation = () => {
        history.push("/checkout")
    }

    return (
        <React.Fragment>
            <Typography variant="h3" textAlign="center">
                REVIEW YOUR ORDER
            </Typography>
            <PlatformSpace height={15}/>
            {isMobile &&
              <Grid container justifyContent="center">
                  <PlatformButton size="lg" onClick={onCompleteReservation}>
                      Checkout
                  </PlatformButton>
              </Grid>
            }
            {/*<Typography variant="body1" display="block" style={{fontWeight: "500"}}>*/}
            {/*    Pickup and delivery options are available.*/}
            {/*</Typography>*/}
            {/*{customer.active &&*/}
            {/*    <div className={classes.cartStatus}>*/}
            {/*        <PlatformBox*/}
            {/*            horizontalAlign="center"*/}
            {/*            verticalAlign="middle">*/}
            {/*            <Typography variant="h6" display="block" style={{fontWeight: "500", textTransform: "none"}}>*/}
            {/*                Your reservation is valid until <Moment format="MM/DD/yyyy" add={{days: 7}}>{new Date()}</Moment>.*/}
            {/*            </Typography>*/}
            {/*        </PlatformBox>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{!customer.active &&*/}
            {/*    <div className={classes.cartStatusDisabled}>*/}
            {/*        <PlatformBox*/}
            {/*            horizontalAlign="center"*/}
            {/*            verticalAlign="middle">*/}
            {/*            <Alert severity="warning">Your account is not enabled for purchasing. Please contact QCSC for further support.</Alert>*/}
            {/*        </PlatformBox>*/}
            {/*    </div>*/}
            {/*}*/}
            {cartError &&
                <div className={classes.cartStatusDisabled}>
                    <PlatformBox
                        horizontalAlign="center"
                        verticalAlign="middle">
                        <Alert severity="error">{cartError}</Alert>
                    </PlatformBox>
                </div>
            }
        </React.Fragment>
    );
}