import * as React from 'react';
import {useContext} from 'react';
import GridTilePager from "../shared/gridTilePager/gridTilePager";
import FavoriteVariantContext from "./favoriteVariantContext";
import {styled} from "@mui/material/styles";
import {Box, Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import PlatformButton from "../../../components/platform/platformButton";
import PlatformSpace from "../../../components/platform/platformSpace";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    maxWidth: 325
}));

export default function FavoriteVariantHome({ showButton = false }) { // showButton prop, default to false
    const {variantsWithParent} = useContext(FavoriteVariantContext);
    const history = useHistory();

    const onShopAll = () => {
        history.push("/catalog")
    }

    return (
        <Box
            flex={1}
            display="flex"            // Use flex layout
            flexDirection="column"    // Stack children vertically
            alignItems="center"       // Center horizontally
            justifyContent="center"   // Center vertically
            sx={{ height: '100%' }}   // Ensure the Box takes full height of its container
        >
            <GridTilePager
                showBorders
                titleJustification="center"
                title="EXPLORE OUR FAVORITES"
                products={variantsWithParent}
            />
            <PlatformSpace height="15"/>
            {showButton && (  // Conditionally render the button based on showButton prop
                <PlatformButton size="lg" onClick={onShopAll}>
                    SHOP ALL PRODUCTS
                </PlatformButton>
            )}
        </Box>
    );
}
