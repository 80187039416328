import React, {useEffect, useState} from "react";
import BestSellerVariantApi from "./bestSellerVariantApi";

export const BestSellerVariantContext = React.createContext({});
export default BestSellerVariantContext;

export const BestSellerVariantContextProvider = ({ categoryHandle, children }) => {
    const [variantsWithParent, setVariantsWithParent] = useState([]);

    useEffect(() => {
        if (categoryHandle) {
            BestSellerVariantApi.getRandomBestSellerVariants(categoryHandle, (data) => {
                setVariantsWithParent(data);
            }, (error) => {
                console.error('Failed to fetch data:', error);
            });
        }
    }, [categoryHandle]);

    return (
      <BestSellerVariantContext.Provider value={{
          variantsWithParent
      }}>
          {children}
      </BestSellerVariantContext.Provider>
    );
}
