import API from "api";

export default {

    checkout(request, successCallback, errorCallback) {
        API.POST({
            url: `/checkout`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getShippingRates(request, successCallback, errorCallback) {
        API.POST({
            url: `/checkout/shipping-rates`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    validateAddress(request, successCallback, errorCallback) {
        API.POST({
            url: `/address/validate`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}