import React, {useContext, useMemo, useState} from "react";
import {Drawer, ListItemText, MenuItem, MenuList, Stack, Typography} from "@mui/material";
import {styled, ThemeProvider} from "@mui/styles";
import {
    ArrowBackIos,
    ArrowForwardIos,
    Close,
    EmojiPeopleOutlined,
    HomeOutlined,
    LocalOfferOutlined,
    ShareLocationOutlined,
    ShoppingCartOutlined,
    StorefrontOutlined
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import PublicContext from "./publicContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {createTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import {toCategoryHandle} from "../util/stringUtil";
import PlatformSpace from "../platform/platformSpace";
import PublicAnnouncement from "./publicAnnouncement";
import Badge from "@mui/material/Badge";
import {calculateTotalCartItems} from "../util/cart";
import CartContext from "../../views/cart/cartContext";

export default function PublicHeaderMobile(props) {
    const {offset} = props;
    const history = useHistory();
    const [activeCategory, setActiveCategory] = useState("Home");
    const {cart} = useContext(CartContext);
    const {
        setMobileMenuOpen,
        mobileMenuOpen,
        onCategory,
        productsByCategory,
        setShowCart,
        setShowContact,
        setMobileSearchOpen
    } = useContext(PublicContext);

    const onShowCart = () => {
        setShowCart(true);
        setShowContact(false);
    }

    const onSearchOpen = () => {
        setMobileSearchOpen(true);
    }

    const onMenuOpen = () => {
        setMobileMenuOpen(true);
        setActiveCategory("Home");
    }

    const onMenuClose = () => {
        setMobileMenuOpen(false);
    }

    const onClick = (link) => {
        history.push(link);
    }

    const onProduct = (product) => {
        onCategory(product.categoryHandle, product.name);
        onMenuClose();
    }

    const onCategoryClick = (categoryName) => {
        setActiveCategory(categoryName);
    }

    const onProductCategory = (categoryName) => {
        onCategory(toCategoryHandle(categoryName), null);
        onMenuClose();
    }

    const onHome = () => {
        history.push("/");
        onMenuClose();
    }

    const customDrawerTheme = createTheme({
        transitions: {
            duration: {
                enteringScreen: 300, // Faster transition when entering
                leavingScreen: 100,  // Keep the original transition when leaving or adjust as necessary
            }
        }
    });

    const totalCartItems = useMemo(() => calculateTotalCartItems(cart), [cart]);

    return (
        <HeaderContainer>
            <Header
                position="fixed"
                open={mobileMenuOpen}
                scrolled={+(offset > 10)}
                elevation={0}>
                <PublicAnnouncement/>
                {!mobileMenuOpen &&
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onMenuOpen}
                            edge="start"
                        >
                            <MenuIcon sx={{fontSize: "1.8rem"}}/>
                        </IconButton>
                        <Stack justifyContent="flex-end"
                               alignItems="flex-end"
                               direction="row"
                               spacing={1}>
                            <IconWrapper mobileMenuOpen={mobileMenuOpen} onClick={onSearchOpen}>
                                <SearchIcon/>
                            </IconWrapper>
                        </Stack>
                        <Image onClick={onHome} src={`${process.env.PUBLIC_URL}/images/logo_zs_h.png`}/>
                        <Badge badgeContent={totalCartItems} color="primary">
                            <IconWrapper mobileMenuOpen={mobileMenuOpen} onClick={onShowCart}>
                                <ShoppingCartOutlined/>
                            </IconWrapper>
                        </Badge>
                    </Toolbar>
                }
                <ThemeProvider theme={customDrawerTheme}>
                    <FastTransitionDrawer
                        anchor="left"
                        open={mobileMenuOpen}
                        onClose={onMenuClose}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onMenuClose}
                                edge="start"
                                sx={{mr: 0.3}}
                            >
                                <Close sx={{fontSize: "1.8rem"}}/>
                            </IconButton>
                            <Image onClick={onHome} src={`${process.env.PUBLIC_URL}/images/logo_zs_h.png`}/>
                            <Stack justifyContent="flex-end"
                                   alignItems="flex-end"
                                   direction="row"
                                   sx={{width: "100%"}}
                                   spacing={1}>
                                <IconWrapper mobileMenuOpen={mobileMenuOpen} onClick={onSearchOpen}>
                                    <SearchIcon/>
                                </IconWrapper>
                                <Badge badgeContent={totalCartItems} color="primary">
                                    <IconWrapper mobileMenuOpen={mobileMenuOpen} onClick={onShowCart}>
                                        <ShoppingCartOutlined/>
                                    </IconWrapper>
                                </Badge>
                            </Stack>
                        </Toolbar>
                        <PlatformSpace height={15}/>
                        {activeCategory === "Home" &&
                            <MenuList style={{minWidth: "100vw"}}>
                                <MenuItem onClick={() => onClick("/")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            Home
                                        </Typography>
                                    </ListItemText>
                                    <Typography variant="body1">
                                        <HomeOutlined/>
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => onClick("/catalog")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            Shop All
                                        </Typography>
                                    </ListItemText>
                                    <Typography variant="body1">
                                        <LocalOfferOutlined/>
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => onCategoryClick("Learn")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            Learn
                                        </Typography>
                                    </ListItemText>
                                    <Typography variant="body1">
                                        <ArrowForwardIos fontSize="1.2rem"/>
                                    </Typography>
                                </MenuItem>
                                <Divider/>
                                {Object.entries(productsByCategory).map(([categoryName, products]) => (
                                    <MenuItem onClick={() => onCategoryClick(categoryName)}>
                                        <ListItemText>
                                            <Typography variant="body1">
                                                {categoryName}
                                            </Typography>
                                        </ListItemText>
                                        <Typography variant="body1">
                                            <ArrowForwardIos fontSize="1.2rem"/>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        }
                        {activeCategory === "Learn" &&
                            <MenuList style={{minWidth: "100vw"}}>
                                <MenuItem onClick={() => onClick("/blog/how-is-this-legal")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            Is Greenbelt Legal?
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => onClick("/blog/what-is-thca")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            What is THCa?
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => onClick("/blog/our-cannabinoids")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            What Are Cannabinoids?
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => onClick("/blog/high-quality")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            What Makes Greenbelt So High Quality?
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => onClick("/blog/our-process")}>
                                    <ListItemText>
                                        <Typography variant="body1">
                                            The Greenbelt Process
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </MenuList>
                        }
                        {Object.entries(productsByCategory).map(([categoryName, products]) => {
                            return (
                                activeCategory === categoryName &&
                                <MenuList style={{minWidth: "100vw"}}>
                                    <MenuItem onClick={() => onCategoryClick("Home")}>
                                        <ArrowBackIos fontSize="1.2rem"/>
                                        {categoryName}
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem onClick={() => onProductCategory(categoryName)}>
                                        Show All
                                    </MenuItem>
                                    {products.map((product, idx) => (
                                        <MenuItem onClick={() => onProduct(product)}>
                                            <ListItemText>
                                                <Typography variant="body1">
                                                    {product.name}
                                                </Typography>
                                            </ListItemText>
                                            <Typography variant="body1">
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            )
                        })}
                        {activeCategory === "Home" &&
                            <React.Fragment>
                                <Divider/>
                                <MenuList style={{minWidth: "100vw"}}>
                                    <MenuItem onClick={() => onClick("/order/status")}>
                                        <ListItemText>
                                            <Typography variant="body1">
                                                Order Status
                                            </Typography>
                                        </ListItemText>
                                        <Typography variant="body1">
                                            <ShareLocationOutlined/>
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => onClick("/store/locator")}>
                                        <ListItemText>
                                            <Typography variant="body1">
                                                Store Locator
                                            </Typography>
                                        </ListItemText>
                                        <Typography variant="body1">
                                            <StorefrontOutlined/>
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => onClick("/contact")}>
                                        <ListItemText>
                                            <Typography variant="body1">
                                                Contact Us
                                            </Typography>
                                        </ListItemText>
                                        <Typography variant="body1">
                                            <EmojiPeopleOutlined/>
                                        </Typography>
                                    </MenuItem>
                                </MenuList>
                            </React.Fragment>
                        }
                    </FastTransitionDrawer>
                </ThemeProvider>
                {/* Leave this for physical devices */}
                <PlatformSpace height={40}/>
            </Header>
        </HeaderContainer>
    )
}

const FastTransitionDrawer = styled(Drawer)(({theme}) => ({
    '& .MuiDrawer-paper': {
        paddingTop: 10,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for entering
        }),
    },
    '& .MuiDrawer-paperAnchorLeft:not(.MuiDrawer-paperAnchorDockedLeft)': {
        transform: 'translateX(-100%)',
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
        borderRight: 'none',
        transition: theme.transitions.create('border', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for leaving
        }),
    },
}));

const IconWrapper = styled('div')(({mobileMenuOpen}) => ({
    padding: "13px 13px 4px 13px",
    borderRadius: 12,
    backgroundColor: "rgba(15, 81, 53, 0.075)",
    ...(mobileMenuOpen && {
        backgroundColor: "white",
        border: "1.1px solid rgba(0, 0, 0, 0.1)"
    }),
}));

const Image = styled('img')(({selected}) => ({
    cursor: "pointer",
    maxHeight: 28,
    marginLeft: 10,
    marginRight: 10
}));

const HeaderContainer = styled("div")(({theme}) => ({
    //padding: theme.custom.pageMargins,
}));

const Header = styled(AppBar)(({scrolled, theme}) => ({
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    width: "auto",
    boxShadow: "none !important",
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    transition: "background 0.25s ease-out",
    zIndex: 5000,
    ...(scrolled && {
        boxShadow: "none !important",
        backgroundColor: "white !important",
        //backgroundColor: "rgb(142,243,142)", // minty and nice best so far
        //backgroundColor: "rgb(125,245,125)", really nice electric green
    }),
}));