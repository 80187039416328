import React from "react";
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";
import {isMobile} from "react-device-detect";

const HomeMadeInTexas = () => {
    return (
        <Container>
            <MainImage
                src={`${process.env.PUBLIC_URL}/images/madeintexas/MadeInTexas.png`} // Replace with the path to your purple text image
                alt="Purple Text"
            />
        </Container>
    );
};

// Styled components
const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(0, 4), // Added white space on the sides
}));

const MainImage = styled("img")({
    width: isMobile ? "80vw" : "20vw",
    height: "auto",
});


export default HomeMadeInTexas;