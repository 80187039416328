import React from "react";
import {useLocation} from "react-router-dom";

export const OrderReceiptContext = React.createContext(null);
export default OrderReceiptContext;

export const OrderReceiptContextProvider = ({children}) => {
    const location = useLocation();
    const { order } = location.state;

    console.log('order', order)

    return (
        <OrderReceiptContext.Provider
            value={{
                order
            }}
        >
            <>{children}</>
        </OrderReceiptContext.Provider>
    );
}
/*
{
    "id": "666e4afe3d6e7848a774abb1",
    "orgId": "65ef4967b20d0b08b7840809",
    "storeId": "65ef4968b20d0b08b784080d",
    "orderNumber": 1009,
    "orderTotalAmount": 129.86,
    "discountAmount": 0,
    "baseAmount": 119.96,
    "taxAmount": 9.8967,
    "shippingAmount": 0,
    "fulfillmentStatus": null,
    "paymentStatus": "PAID",
    "shipping": {
        "shipmentId": "0",
        "serviceToken": "free_shipping",
        "amount": 0
    },
    "couponId": null,
    "billingAddress": {
        "id": null,
        "orgId": null,
        "storeId": null,
        "street1": "1001 Southern Drive",
        "street2": null,
        "address3": null,
        "state": "TX",
        "city": "Buda",
        "zip": "78610",
        "country": "US",
        "firstName": "Paul",
        "lastName": "Zain",
        "email": null,
        "phone": ""
    },
    "shippingAddress": {
        "id": null,
        "orgId": null,
        "storeId": null,
        "street1": "1001 Southern Drive",
        "street2": null,
        "address3": null,
        "state": "TX",
        "city": "Buda",
        "zip": "78610",
        "country": "US",
        "firstName": "Paul",
        "lastName": "Zain",
        "email": null,
        "phone": ""
    },
    "email": "paulzain@gmail.com",
    "phone": null,
    "orderItems": [
        {
            "id": "666e4afe3d6e7848a774abb0",
            "orgId": null,
            "storeId": null,
            "customerId": null,
            "variantId": "65ef49e0b20d0b08b7840c8e",
            "variantName": "Cherry Lifted Limeade Delta9/THCV",
            "name": "Happy Fruit Gummies",
            "productId": "65ef49e0b20d0b08b7840c8c",
            "categoryId": "65ef49dbb20d0b08b784082c",
            "quantity": 4,
            "price": 29.99,
            "total": 119.96,
            "images": [
                {
                    "id": "65f3c19b653efd00ebcb0453",
                    "mediaUrl": "//canna-prod-product-image.s3.us-west-2.amazonaws.com/image/65ef4967b20d0b08b7840809/e9c27e9d-ca25-4d67-ba58-50148e3cc018.png",
                    "thumbUrl": "//canna-prod-product-image.s3.us-west-2.amazonaws.com/image/65ef4967b20d0b08b7840809/b10dae3d-a250-48ed-aba2-28fe660568f6_t.png",
                    "fileName": "Happy Fruit Gummies04.png",
                    "order": 0
                }
            ],
            "discount": null,
            "optionValues": [
                {
                    "id": "bd20432e-af4b-405a-8028-24b5da805848",
                    "optionId": "889d6802-b46a-4ad5-afcc-d4b284bbe363",
                    "isDefault": true,
                    "isFixed": false,
                    "label": "Flavor",
                    "name": "Flavor",
                    "sortOrder": 0,
                    "value": "Cherry Lifted Limeade D9/THCV"
                }
            ],
            "sku": "850025442510",
            "createdAt": "2024-06-16T02:16:30.729435Z",
            "updatedAt": "2024-06-16T02:16:30.729435Z",
            "createdBy": "anonymousUser",
            "lastModifiedBy": "anonymousUser"
        }
    ],
    "customer": {
        "id": "666e3c493d6e7848a774aba9",
        "orgId": "65ef4967b20d0b08b7840809",
        "storeId": "65ef4968b20d0b08b784080d",
        "userId": "8a087a6b5aa7da2b7bae3a67bcd176583bfb5a5c9de95eedca16e3706b863dc510b3c2a6d5665c9a8f130425dc37db095c9cf4d678cff673b942fac871399465",
        "firstName": "Paul",
        "lastName": "Zain",
        "organizationName": null,
        "email": "paulzain@gmail.com",
        "score": null,
        "addresses": null,
        "phone": "",
        "customerFeatures": null,
        "ltv": 85.82,
        "orderCount": 2,
        "active": true,
        "createdAt": "2024-06-16T01:13:45.282Z",
        "updatedAt": "2024-06-16T01:16:13.349Z",
        "createdBy": "anonymousUser",
        "lastModifiedBy": "anonymousUser"
    },
    "orderShipments": null,
    "createdAt": "2024-06-16T02:16:30.794944Z",
    "updatedAt": "2024-06-16T02:16:30.794944Z",
    "createdBy": "anonymousUser",
    "lastModifiedBy": "anonymousUser"
}
 */