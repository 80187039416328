import * as React from 'react';
import {useContext} from 'react';
import {Stack} from "@mui/material";
import CheckoutShippingFormContext from "./checkoutShippingFormContext";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import formatToUSD from "../../components/util/moneyUtil";
import {styled} from "@mui/styles";
import PlatformSpace from "../../components/platform/platformSpace";

export default function CheckoutShippingForm(props) {
    const {name, rate} = props;
    const {optionType, setOptionType} = useContext(CheckoutShippingFormContext);

    const onChange = () => {
        setOptionType(name);
    }

    const checked = name === optionType;

    return (
        <React.Fragment>
            <ShippingContainer onClick={onChange}>
                <Stack sx={{cursor: "pointer"}} justifyContent="flex-start" direction="row" alignItems="center" spacing={2}>
                    <Radio checked={checked}/>
                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
                        <div>
                            <AttributeTitle>{rate.providerName} {rate.serviceName}</AttributeTitle>
                            <AttributeValue>{rate.durationTerms}</AttributeValue>
                        </div>
                        <Typography variant="body2">
                            {formatToUSD(rate.amount)}
                        </Typography>
                    </Stack>
                </Stack>
            </ShippingContainer>
            <PlatformSpace height={5}/>
        </React.Fragment>
    )
}

const ShippingContainer = styled('div')(({ theme }) => ({
    backgroundColor: "rgba(179, 113, 224,.05)",
    border: "1px solid #1565c0",
    padding: 15,
    borderRadius: 12,
    width: "100%",
    cursor: "pointer"
}));

const AttributeTitle = styled('div')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "0.8rem",
    color: "rgba(64,64,64,1)",
    textTransform: "uppercase",
}));

const AttributeValue = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "0.9rem",
    color: "rgba(0,0,0,0.85)",
}));