import PublicLayout from "components/layout/publicLayout";
import React from "react";
import HomeHome from "./homeHome";
import {HomeContextProvider} from "./homeContext";

export default function HomeIndex(props) {
    return (
        <PublicLayout>
            <HomeContextProvider>
                <HomeHome/>
            </HomeContextProvider>
        </PublicLayout>
    );
}