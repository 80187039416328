import * as React from 'react';
import {useContext} from 'react';
import OrderStatusContext from "./orderStatusContext";
import OrderStatusLookup from "./orderStatusLookup";
import PlatformSpace from "../../../components/platform/platformSpace";
import {CircularProgress, Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import OrderStatus from "./orderStatus";

export default function OrderStatusHome(props) {
    const {order, processing} = useContext(OrderStatusContext);

    return (
        <React.Fragment>
            {processing &&
                <PaneContainer justifyContent="center" alignItems="center">
                    <PlatformSpace height={200}/>
                    <Typography variant="h3" textAlign="center">
                        Looking up order...
                    </Typography>
                    <PlatformSpace height={30}/>
                    <CircularProgress color="inherit" size="5rem" />
                    <PlatformSpace height={200}/>
                </PaneContainer>}
            {!processing &&
                <React.Fragment>
                    {!order && <OrderStatusLookup/>}
                    {order && <OrderStatus/>}
                </React.Fragment>}
        </React.Fragment>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "100vw",
}));