import React, {useEffect, useState} from "react";
import FavoriteVariantApi from "./favoriteVariantApi";

export const FavoriteVariantContext = React.createContext({});
export default FavoriteVariantContext;

export const FavoriteVariantContextProvider = ({ children }) => {
    const [variantsWithParent, setVariantsWithParent] = useState([]);

    useEffect(() => {
        FavoriteVariantApi.getRandomFavoriteVariants((data) => {
            setVariantsWithParent(data);
        }, (error) => {
            console.error('Failed to fetch data:', error);
        });
    }, []); // Depend on categoryHandle and variantId

    return (
      <FavoriteVariantContext.Provider value={{
          variantsWithParent
      }}>
          {children}
      </FavoriteVariantContext.Provider>
    );
}
