import React from 'react';
import {isMobile} from "react-device-detect";
import PublicLayout from "../../../components/layout/publicLayout";
import PlatformSpace from "../../../components/platform/platformSpace";
import BlogOurProcessTrimmingAndProcessing from "./blogOurProcessTrimmingAndProcessing";
import BlogOurProcessCraftingAndIngredients from "./blogOurProcessCraftingAndIngredients";
import BlogOurProcessLabTesting from "./blogOurProcessLabTesting";
import BlogOurProcessOrganicCultivation from "./blogOurProcessOrganicCultivation";
import HomeProductFeature from "../../home/homeProductFeature";
import BlogWithMoodBanner from "../../../components/shared/blog/blogWithMoodBanner";

export default function BlogOurProcessIndex() {
    return (
        <PublicLayout>
            <BlogWithMoodBanner
                title="THE GREENBELT PROCESS"
                image={`${process.env.PUBLIC_URL}/images/blog/OurProcessToTheLight.jpg`}
            />
            <BlogOurProcessOrganicCultivation />
            <PlatformSpace height={isMobile ? 30 : 90} />
            <BlogOurProcessTrimmingAndProcessing />
            <PlatformSpace height={isMobile ? 30 : 90} />
            <BlogOurProcessCraftingAndIngredients />
            <PlatformSpace height={isMobile ? 30 : 90} />
            <BlogOurProcessLabTesting />
            <PlatformSpace height={isMobile ? 30 : 90} />
            <HomeProductFeature
                title="SHIPPING & DELIVERY"
                description="Cannabis products can deteriorate when exposed to light and high temperatures over time. To maintain their freshness, we transport our products in refrigerated trucks to our climate-controlled warehouse immediately after manufacturing. For delivery, we exclusively use the U.S. Postal Service. As a federal agency, USPS understands the legal status of hemp, ensuring your shipment arrives untampered and in perfect condition."
                imageUrl={`${process.env.PUBLIC_URL}/images/blog/OurProcessDelivery.jpg`}
                imagePosition="right"
            />
            <PlatformSpace height={isMobile ? 30 : 90} />
        </PublicLayout>
    );
}
