import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Link, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {styled} from "@mui/styles";
import PublicMegaMenuContext from "./megaMenu/publicMegaMenuContext";
import AppContext from "../../appContext";
import _ from "lodash";

export default function PublicShopSimpleMenu(props) {
    const theme = useTheme();
    const { hideAnchor } = useContext(PublicMegaMenuContext);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const {categories} = useContext(AppContext);

    useEffect(() => {
        if (!_.isEmpty(categories)) {
            setHoveredCategory('All Products');
        }
    }, [categories]);

    const handleMouseEnter = (category) => {
        setHoveredCategory(category);
    };

    const handleMouseLeave = () => {
        // Leave a picture so it looks nice
        //setHoveredCategory(null);
    };

    return (
      <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
          <LearnSection>
              <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                      {(hoveredCategory && hoveredCategory !== 'All Products') &&
                          <ImagePaper elevation={isSmallScreen ? 0 : 4}>
                              <Image
                                src={hoveredCategory?.tileImage?.mediaUrl}
                                alt={hoveredCategory?.name || 'Default'}
                              />
                          </ImagePaper>
                      }
                      {(hoveredCategory && hoveredCategory === 'All Products') &&
                          <ImagePaper elevation={isSmallScreen ? 0 : 4}>
                              <Image
                                  src={`${process.env.PUBLIC_URL}/images/category/shop2.jpg`}
                                  alt={hoveredCategory?.name || 'Default'}
                              />
                          </ImagePaper>
                      }
                  </Grid>
                  <Grid item xs={12} md={8}>
                      <LearnLinks>
                          <Typography variant="h8" gutterBottom sx={{
                              fontFamily: "Poppins !important",
                              fontWeight: 900,
                              color: theme.palette.secondary.main,
                          }}>
                              SHOP THC
                          </Typography>
                          <StyledLink
                            href="/catalog"
                            gutterBottom
                            onMouseEnter={() => handleMouseEnter('All Products')}
                            onMouseLeave={handleMouseLeave}
                          >
                              All Products
                          </StyledLink>
                          {categories?.map((category) => (
                              <Box key={category.name} sx={{ display: 'inline-block', width: 'auto' }}>
                                  <StyledLink
                                      href={`/catalog/${category.name.toLowerCase()}`}
                                      gutterBottom
                                      onMouseEnter={() => handleMouseEnter(category)}
                                      onMouseLeave={handleMouseLeave}
                                  >
                                      {category.name}
                                  </StyledLink>
                              </Box>
                          ))}
                      </LearnLinks>
                  </Grid>
              </Grid>
          </LearnSection>
      </Stack>
    );
}

const LearnSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    width: "90vw"
}));

const ImagePaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: '100%',           // Takes up full width of the grid
    aspectRatio: '1 / 1',    // Ensures a perfect square aspect ratio
    position: 'relative',    // Necessary for absolute positioning of the image
}));

const Image = styled('img')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover',      // Ensures the image covers the entire square and maintains aspect ratio
    objectPosition: 'center',// Centers the image
}));

const LearnLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontFamily: "Poppins !important",
    fontWeight: "bold",
    fontSize: "1.3rem",
    cursor: "pointer !important",
    textDecoration: "none !important",
    textTransform: "uppercase !important",
    display: "inline-block", // Ensures link only takes up as much space as the text
    '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.main,
    },
    marginTop: "3px !important"
}));