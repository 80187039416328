import {isMobile} from "react-device-detect";

const cartStyles = {
    rootInner: {
        margin: "20px 50px 50px 50px"
    },
    cartHeader: {

    },
    cartStatus: {
        borderRadius: 10,
        backgroundColor: "#f8f8f8",
        height: 60,
    },
    cartStatusDisabled: {
        borderRadius: 10,
        height: 60,
    },
    cartItems: {

    },
    cartItemLink: {
        cursor: "pointer",
        fontSize: 10,
        color: "#06c",
        padding: "15px 0 5px 0"
    },
    cartEmpty: {
        width: "100vw",
        padding: isMobile ? 10 : 100,
        textAlign: "center",
        backgroundColor: "#fbfbfd"
    },
    cartImage: {
        backgroundPosition: "center",
        objectFit: "cover",
        width: "85%",
        height: "100%",
        paddingBottom: 10
    }
};

export default cartStyles;
