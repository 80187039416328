import * as React from 'react';
import {useContext} from 'react';
import {Alert, Chip, Divider, Stack, Tooltip} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {HelpOutline} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import formatToUSD from "../../components/util/moneyUtil";
import CartContext from "../cart/cartContext";
import {isMobile} from "react-device-detect";
import CheckoutContext from "./checkoutContext";
import {getCartImageUrl} from "../../components/util/cart";
import PlatformBox from "../../components/platform/platformBox";

export default function CheckoutSummary({width}) {
    const {cart, shippingAmount} = useContext(CartContext);
    const {wizardStep, couponError, discount, onClearDiscount, onUpdateDiscount} = useContext(CheckoutContext);

    const onApplyDiscount = () => {
        onUpdateDiscount();
    }

    const onRemoveDiscount = () => {
        onClearDiscount();
    }

    return (
        <div style={{width: isMobile ? width || "88vw" : width || "23vw"}}>
            <PlatformSpace height={10}/>
            {cart && <React.Fragment>
                {cart?.cartItems?.map((cartItem) => {
                    const imageUrl = getCartImageUrl(cartItem);
                    return (
                        <React.Fragment key={cartItem.id}>
                            <Stack justifyContent="space-between" direction="row" alignItems="center">
                                <Stack spacing={2} justifyContent="flex-start" direction="row" alignItems="center">
                                    <Badge badgeContent={cartItem.quantity} color="primary">
                                        <Image src={imageUrl} />
                                    </Badge>
                                    <div>
                                        <Typography variant="body4" sx={{color: "black"}}>
                                            {cartItem.name}
                                        </Typography>
                                        <br/>
                                        <Typography variant="body5" sx={{color: "black"}}>
                                            {cartItem.variantName}
                                        </Typography>
                                    </div>
                                </Stack>
                                <Typography variant="body5" sx={{color: "black"}}>
                                    {formatToUSD(cartItem.total)}
                                </Typography>
                            </Stack>
                            <PlatformSpace height={30}/>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>}
            <SummaryDivider/>
            <PlatformSpace height={isMobile ? 15 : 30}/>
            <Stack direction="row" spacing={2} justifyContent="space-between" >
                <PlatformTextField name="couponCode" placeholder="Discount code" fullWidth disableAutoComplete/>
                <ApplyButton variant="contained" onClick={onApplyDiscount}>APPLY</ApplyButton>
            </Stack>
            {couponError && <PlatformBox horizontalAlign="center" verticalAlign="middle">
                <Alert severity="warning">
                    {couponError}
                </Alert>
            </PlatformBox>}
            <PlatformSpace height={30}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Subtotal
                </TotalsText>
                <TotalsText>
                    {formatToUSD(cart.baseAmount)}
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            {(discount || cart.isFreeShipping) &&
                <Stack justifyContent="space-between" direction="row" alignItems="center">
                    <TotalsText>
                        Discount
                    </TotalsText>
                    <TotalsText>
                        {!cart.isFreeShipping && <Chip label={`${discount.name} -${formatToUSD(discount?.amount)}`} variant="outlined" onDelete={onRemoveDiscount} />}
                        {cart.isFreeShipping === true && <Chip label="Free Shipping" variant="outlined" onDelete={onRemoveDiscount} />}
                    </TotalsText>
                </Stack>
            }
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Shipping
                    <Tooltip title="Delete">
                        <IconButton>
                            <HelpOutline sx={{fontSize: "1rem"}} />
                        </IconButton>
                    </Tooltip>
                </TotalsText>
                <TotalsSubtext>
                    {wizardStep === 1 && "Calculated at next step"}
                    {(wizardStep !== 1 && cart.serviceToken !== "free_shipping" && shippingAmount > 0 && shippingAmount) && formatToUSD(shippingAmount)}
                    {wizardStep !== 1 && cart.serviceToken !== "free_shipping" && shippingAmount === 0 && "-"}
                    {(wizardStep !== 1 && cart.serviceToken === "free_shipping") && "Free"}
                </TotalsSubtext>
            </Stack>
            <PlatformSpace height={5}/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <TotalsText>
                    Estimated taxes
                    <Tooltip title="Delete">
                        <IconButton>
                            <HelpOutline sx={{fontSize: "1rem"}} />
                        </IconButton>
                    </Tooltip>
                </TotalsText>
                <TotalsText>
                    {(!cart.taxAmount || cart.taxAmount === 0) ? "$0.00" : formatToUSD(cart?.taxAmount)}
                </TotalsText>
            </Stack>
            <PlatformSpace height={5}/>
            <SummaryDivider/>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
                <GrandTotalText>
                    Total
                </GrandTotalText>
                <Stack spacing={2} justifyContent="flex-end" direction="row" alignItems="center">
                    <TotalsSubtext>
                        USD
                    </TotalsSubtext>
                    <GrandTotalText>
                        {formatToUSD(cart.cartTotalAmount)}
                    </GrandTotalText>
                </Stack>
            </Stack>
        </div>
    )
}

const SummaryDivider = styled(Divider)(({ fullWidth }) => ({
    borderColor: "rgba(0, 0, 0, 0.1) !important"
}));

const TotalsSubtext = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "black",
    opacity: 0.6,
    fontWeight: 300
}));

const ApplyButton = styled(Button)(({ theme }) => ({
    width: "140px !important",
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    width: 75,
    height: 75,
    borderRadius: "12px",
    pointerEvents: "none",
    backgroundColor: "black"
}));

const CouponErrorText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "darkred",
    fontWeight: 600,
}));

const TotalsText = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    color: "black",
    fontWeight: 400
}));

const GrandTotalText = styled("div")(({ theme }) => ({
    fontSize: "1.2rem",
    color: "black",
    fontWeight: 600
}));