import React from 'react';
import {Box} from '@mui/material';
import {keyframes, styled} from '@mui/material/styles';
import {isMobile} from "react-device-detect";

// Define the scrolling animation
const scrollAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Container that holds the scrolling content
const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden', // Hide the overflow to create the ticker effect
    padding: theme.spacing(2, 0),
}));

// Box that will move with the animation
const LogoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap', // Prevent line breaks to make the logos scroll continuously
    animation: `${scrollAnimation} 30s linear infinite`, // Apply the animation for infinite scrolling
}));

// Styling for individual logos
const StyledLogo = styled('img')(({ theme }) => ({
    height: isMobile ? "100%" : 50,
    margin: theme.spacing(1),
    maxWidth: '100%',
    marginRight: 75
}));

export default function HomeAsSeenIn() {
    return (
        <LogoContainer>
            <LogoBox>
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 1" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 2" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 3" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 4" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 5" />
                {/* Repeat logos to create the continuous scrolling effect */}
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 1" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 2" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 3" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 4" />
                <StyledLogo src={`${process.env.PUBLIC_URL}/images/asseen/AsSeenIn.png`} alt="As Seen In 5" />
            </LogoBox>
        </LogoContainer>
    );
}
