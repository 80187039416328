import React from "react";

export default function PlatformBox(props) {
    const {horizontalAlign, verticalAlign, height, width, children} = props;

    return (
        <div style={{display: "table", width: width || "100%"}}>
            <div style={{display: "table-cell", verticalAlign: verticalAlign, textAlign: horizontalAlign, height: height || 60}}>
                {children}
            </div>
        </div>
    );
};