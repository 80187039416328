import API from "api";

export default {

    createPasswordReset(request, successCallback, errorCallback) {
        API.POST({
            url: `/password/reset`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    resetPassword(request, successCallback, errorCallback) {
        API.PUT({
            url: `/password/reset`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    validateToken(token, successCallback, errorCallback) {
        API.GET({
            url: `/password/validate/${token}`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

}