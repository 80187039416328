import React from "react";
import cx from "classnames";
import {makeStyles} from "@mui/styles";

const PlatformLabel = (props) => {
  const classes = useStyles();
  const { children, disabled } = props;

  let combinedClassName = cx({
    [classes.disabled]: disabled,
    [classes.label]: true
  });

  return (
    <div className={classes.labelWrapper}>
      <label className={combinedClassName}>{children}</label>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.5
  },
  label: {
    color: "rgb(51, 51, 51)",
    fontWeight: 400,
    fontSize: 16
  },
  labelWrapper: {
    verticalAlign: "bottom",
    display: "table-cell"
  }
}));

export default PlatformLabel;