import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import styles from "./passwordStyles";
import ExternalLayout from "../shared/externalLayout";
import ExternalMenu from "../shared/externalMenu";
import PasswordResetForm from "./passwordResetForm";
import PasswordRequestForm from "./passwordRequestForm";
import {useParams} from "react-router-dom"

const useStyles = makeStyles(styles);

export default function PasswordHome() {
    const classes = useStyles();
    const params = useParams()
    const [mode, setMode] = useState(null);

    useEffect(() => {
        if (params.token) {
            setMode("reset");
        } else {
            setMode("request");
        }
    }, []);

    return (
        <ExternalLayout>
            <ExternalMenu/>
            {mode === "request" && <PasswordRequestForm/>}
            {mode === "reset" && <PasswordResetForm token={params.token}/>}
        </ExternalLayout>
    );
}