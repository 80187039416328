import {useEffect, useState} from "react";

export default function usePlatformGenericField(
    {context, name, groupBy, defaultValue, unbound, required}) {

    const [value, setValue] = useState(defaultValue || "");
    const [valid, setValid] = useState(true);

    useEffect(() => {
        if (context && context.addField) {
            context.addField(name, {
                name: name,
                groupBy: groupBy,
                unbound: unbound,
                setValue: setFormValue,
                setDefaultValue: setDefaultValue,
                validate: onValidate,
                changeHandler: changeHandler
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
    }, []);

    useEffect(() => {
        if (context != null) {
            setValue(defaultValue || "");
        }
    }, [defaultValue]);

    // This causes an infinite loop
    // useEffect(() => {
    //     if (context && context.updateField) {
    //         context.updateField(name, groupBy, { unbound: unbound });
    //         if (unbound === "true") {
    //             context.removeField(name, groupBy);
    //         }
    //     }
    // }, [context, name, unbound]);

    const setFormValue = (value) => {
        if (value !== null && value !== undefined) {
            setValue(value);
        }
    };

    const setDefaultValue = (defaultValue) => {
        if (context != null) {
            context.form.current.setValue(name, defaultValue, groupBy);
        }
        setValue(defaultValue || "");
    };

    const onValidate = () => {
        const valueToCheck = context.form.current.getValue(name, groupBy);
        const unboundToCheck = context.form.current.isUnbound(name, groupBy);

        if (unboundToCheck === "true") {
            setValid(true);
            return true;
        }

        if (!required) {
            setValid(true);
            return true;
        }

        if (!valueToCheck) {
            setValid(false);
            return false;
        }

        return true;
    };

    const changeHandler = (value) => {
        setValue(value);

        if (context != null) {
            context.form.current.onChange(name, value, false, groupBy);
        }
    };

    const blurHandler = (event) => {
        if (context != null) {
            context.form.current.onBlur(name, event);
        }
    };

    return { value, valid, setFormValue, setDefaultValue, onValidate, blurHandler, changeHandler };
}