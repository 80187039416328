import React from "react";
import PlatformSpace from "../../components/platform/platformSpace";
import {styled} from "@mui/styles";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";

export default function CheckoutHeader(props) {
    const history = useHistory();

    return (
        <React.Fragment>
            <Image src={`${process.env.PUBLIC_URL}/images/logo_zs_h.png`} onClick={() => history.push("/")}/>
            <PlatformSpace height={10}/>
        </React.Fragment>
    );
}

const Image = styled('img')(({ selected }) => ({
    cursor: "pointer",
    width: isMobile ? "50%" : "40%"
}));