import GridTile from "./shared/gridTile/gridTile";
import {Stack} from "@mui/material";
import React, {useContext} from 'react';
import PlatformSpace from "../../components/platform/platformSpace";
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";
import CatalogTableChips from "./catalogTableChips";
import {FilterList} from "@mui/icons-material";
import CatalogContext from "./catalogContext";
import PlatformSearchField2 from "../../components/platform/platformSearchField2";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export default function CatalogTable(props) {
    const {
        setMobileFilterOpen,
        products,
        displayedCategory,
        onFilterProducts,
        resetFilters,
        searchFieldRef
    } = useContext(CatalogContext);

    const onMobileFilter = () => {
        setMobileFilterOpen(true);
    }

    return (
        <CatalogTableContainer justifyContent="flex-start" alignItems={isMobile ? "center" : "flex-start"}>
            <CatalogTableChips/>
            {isMobile &&
                <Stack sx={{width: "100%"}} spacing={1} justifyContent="flex-start" direction="row" alignItems="flex-start">
                    <IconWrapper onClick={onMobileFilter}>
                        <FilterList/>
                    </IconWrapper>
                    <PlatformSearchField2
                        ref={searchFieldRef}
                        placeholder="Search name, dosage, and flavor"
                        changeHandler={onFilterProducts}
                        fullWidth
                    />
                </Stack>
            }
            <PlatformSpace heigh={10}/>
            <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={3} spacing={2}>
                {products.map((product, idx) => {
                    return (
                        <Grid item key={`${product.variant.variantId}-${idx}`} md={isMobile ? 6 : null} xs={isMobile ? 6 : null}>
                            <GridTile product={product}/>
                        </Grid>
                    )
                })}
            </Grid>
            {products?.length === 0 &&
                <React.Fragment>
                    <PlatformSpace height={20}/>
                    <Grid container justifyContent="center" alignItems="center" rowSpacing={3} spacing={2}>
                        <Grid item>
                            <i>No results found, try resetting your filters</i>
                        </Grid>
                        <Grid item>
                            <ResetButton variant="contained" onClick={resetFilters}>
                                Reset filters
                            </ResetButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </CatalogTableContainer>
    );
}

const ResetButton = styled(Button)(({theme}) => ({
    height: 35,
    width: isMobile ? "35vw !important" : "200px !important",
    backgroundImage: "none !important",
    color: "rgba(0, 0, 0, 0.8) !important",
    border: "2px solid rgba(0, 0, 0, 0.8) !important", // Adds a blue outline
    backgroundColor: "transparent !important" // Removes the colored background
}));

const IconWrapper = styled('div')(({ menuMobileOpen }) => ({
    padding: "9px 9px 4px 9px",
    borderRadius: 12,
    backgroundColor: "rgba(15, 81, 53, 0.075)",
    ...(menuMobileOpen && {
        backgroundColor: "white",
        border: "1.1px solid rgba(0, 0, 0, 0.1)"
    }),
}));

const CatalogTableContainer = styled(Stack)(({ theme }) => ({
    paddingRight: 10,
    minHeight: "70vh",
    maxWidth: "95%",
    width: "95%",
    ...(isMobile && {
        paddingRight: 0,
        minHeight: "70vh",
        maxWidth: "100%",
        width: "100%",
    }),
}));