import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlatformMarginBox from "../../platform/platformMarginBox";
import { isMobile } from "react-device-detect";

const StyledImageBox = styled(Box)(({ theme }) => ({
    width: isMobile ? "95vw" : "100vw",
    height: '30vh',  // Keeps the container at 30% of the viewport height
    borderRadius: '20px',
    position: 'relative',
    overflow: 'hidden',  // Add this to clip any overflow
}));

const ImageOverlay = styled(Box)(({ theme, justifyText = 'flex-start' }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: justifyText,
    justifyContent: 'center',
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

export default function BlogWithFullMoodBanner({ title, subtitle, image, justifyText }) {
    return (
        <PlatformMarginBox>
            <StyledImageBox>
                <img
                    src={image}
                    alt="Cannabis leaves"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',  // Maintain aspect ratio and fill the container
                        borderRadius: '20px',
                    }}
                />
                <ImageOverlay justifyText={justifyText}>
                    <Box sx={{ width: isMobile ? "80%" : "65%", margin: isMobile ? 3 : 5 }}>
                        <Typography variant={isMobile ? "subtitle2" : "h6"}
                                    sx={{color: "white", fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}>
                            {subtitle?.toUpperCase()}
                        </Typography>
                        <Typography variant={isMobile ? "h5" : "h3"} gutterBottom
                                    sx={{color: "white", fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}>
                            {title?.toUpperCase()}
                        </Typography>
                    </Box>
                </ImageOverlay>
            </StyledImageBox>
        </PlatformMarginBox>
    );
}
