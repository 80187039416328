import React, {useRef, useState} from "react";
import {CircularProgress, Slide} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Bolt} from "@mui/icons-material";
import {styled} from "@mui/styles";
import PlatformCartSplitButtonWrapper from "./platformCartSplitButtonWrapper";
import {isMobile} from "react-device-detect";

export default function PlatformCartSplitButton(props) {
    const {
        containerRef, hoverHandler = () => {}, cartHandler, buyNowHandler, disabled, hover, absolute, tiled,
        height, width, addToCartText = "ADD TO CART"
    } = props;
    const innerRef = containerRef || useRef();
    const [buyNow, setBuyNow] = useState(false);
    const [innerHover, setInnerHover] = useState(false);
    const [loading, setLoading] = useState(false);

    const onBuyNowEnter = () => {
        setBuyNow(true);
        setInnerHover(false);
        hoverHandler(false);
    };

    const onBuyNowLeave = () => {
        setInnerHover(true);
        hoverHandler(true);
        setBuyNow(false);
    };

    const onMouseIn = () => setInnerHover(true);

    const onMouseOut = () => {
        if (loading !== true) {
            setInnerHover(false);
        }
    };
    const activeIn = loading === true || (hover !== null ? hover : innerHover);

    const onHandleCart = () => {
        setLoading(true);
        cartHandler(() => {
            setLoading(false);
            setInnerHover(false);
        });
    }

    const onHandleBuyNow = () => {
        setLoading(true);
        buyNowHandler(() => {
            setLoading(false);
            setInnerHover(false);
        });
    }

    return (
        <PlatformCartSplitButtonWrapper relative={!absolute} width={width}>
            <SplitButtonContainer ref={containerRef || innerRef} buyNow={+buyNow} absolute={+absolute}
                                  onMouseEnter={onMouseIn}
                                  onMouseLeave={onMouseOut}>
                {!buyNow &&
                    <Slide timeout={!absolute ? 0 : 200} direction="up" in={activeIn || !absolute}
                           container={innerRef.current}>
                        <SplitButton disabled={loading === true || disabled === true} height={height} width={width}
                                     tiled={+tiled} onClick={onHandleCart}>
                            {loading ? <CircularProgress size={24} color="primary"/> : addToCartText}
                        </SplitButton>
                    </Slide>
                }
                {!buyNow &&
                    <Slide timeout={!absolute ? 0 : 200} direction="up" in={activeIn || !absolute}
                           container={innerRef.current}>
                        <BuyItNowIconButtonContainer height={height}>
                            <BuyItNowIconButton disabled={loading === true || disabled === true}
                                                onMouseEnter={onBuyNowEnter} height={height}>
                                <Bolty/>
                            </BuyItNowIconButton>
                        </BuyItNowIconButtonContainer>
                    </Slide>
                }
                {buyNow &&
                    <Slide timeout={200} direction="left" in={buyNow} container={innerRef.current}>
                        <BuyItNowButton onClick={onHandleBuyNow} tiled={tiled} height={height} width={width}
                                        disabled={disabled === true}
                                        onMouseLeave={onBuyNowLeave} startIcon={<Bolty/>}>
                            {loading ? <CircularProgress size={24} color="primary"/> : "BUY NOW"}
                        </BuyItNowButton>
                    </Slide>
                }
            </SplitButtonContainer>
        </PlatformCartSplitButtonWrapper>
    );
}

const Bolty = styled(Bolt)(({height}) => ({
    color: "white !important"
}));

const BuyItNowButton = styled(Button)(({width, height, tiled}) => ({
    fontSize: "1rem !important",
    fontWeight: 600,
    backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))",
    display: "flex",
    height: height ? `${height}px !important` : "inherit",
    width: width ? width : "inherit",
    color: "white !important",
    ...(tiled && {
        borderRadius: "0 0 12px 12px !important",
    }),
    zIndex: 10
}));

const BuyItNowIconButtonContainer = styled('div')(({height}) => ({
    position: "absolute",
    right: 0,
    bottom: 0,
    borderLeft: isMobile ? "none" : "1px solid rgb(59,42,84)",
    height: height ? `${height}px !important` : "inherit",
    zIndex: 9
}));

const BuyItNowIconButton = styled(IconButton)(({height}) => ({
    height: height ? `${height}px !important` : "inherit",
    width: 50,
    zIndex: 12
}));

const SplitButton = styled(Button)(({tiled, height, width}) => ({
    fontSize: "1rem !important",
    height: height ? `${height}px !important` : "inherit",
    width: width ? width : "inherit",
    fontWeight: 600,
    backgroundImage: "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))",
    color: "white !important",
    ...(tiled && {
        borderRadius: "0 0 12px 12px !important",
    }),
    zIndex: 1
}));

const SplitButtonContainer = styled('div')(({buyNow, absolute, width}) => ({
    overflow: "hidden !important",
    width: width ? `${width}px !important` : "inherit",
    backgroundImage: (absolute && buyNow || !absolute) ? "linear-gradient(to right bottom, rgb(252, 60, 172), rgb(104, 44, 231))" : "none",
    position: "absolute",
    ...(absolute && {
        bottom: "0 !important",
    }),
    ...(!absolute && {
        borderRadius: 12,
    }),
    zIndex: 2
}));