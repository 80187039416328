import React, {useEffect, useState} from 'react';
import {Box, IconButton, InputAdornment, Modal, TextField, Typography} from '@mui/material';
import {styled} from '@mui/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlatformSpace from '../platform/platformSpace';
import {isMobile} from "react-device-detect";

const DEBUG = false;

export default function PublicPopup() {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [phoneSubmitted, setPhoneSubmitted] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [open, setOpen] = useState(false); // Initially not open

    // Check if age gate is false and start timer for showing popup
    useEffect(() => {
        const checkAgeGate = setInterval(() => {
            const ageGatePassed = Boolean(localStorage.getItem('ageGate')) === true;

            if (ageGatePassed) {
                clearInterval(checkAgeGate); // Stop checking once age gate is passed

                // Show popup after 7 seconds if not previously closed
                const isClosed = DEBUG ? false : localStorage.getItem('popupClosed');
                if (!isClosed) {
                    const timer = setTimeout(() => {
                        setOpen(true);
                    }, DEBUG ? 1000 : 7000);
                    return () => clearTimeout(timer); // Clean up the timer
                }
            }
        }, 1000); // Check every second

        return () => clearInterval(checkAgeGate); // Clean up interval on component unmount
    }, []);

    // Automatically close modal 3 seconds after both email and phone have been submitted
    useEffect(() => {
        if (emailSubmitted && phoneSubmitted) {
            const timer = setTimeout(() => {
                handleClose();
            }, 3000); // Close after 3 seconds
            return () => clearTimeout(timer); // Clean up the timer if needed
        }
    }, [emailSubmitted, phoneSubmitted]);

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('popupClosed', 'true'); // Prevent popup from reopening
    };

    // Validate email using regex
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(email));
    };

    // Validate phone number using regex (only digits allowed, min 10 digits)
    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10,15}$/; // Phone number must be 10-15 digits
        setIsPhoneValid(phoneRegex.test(phone));
    };

    // Handle email submission process
    const handleEmailSubmit = () => {
        if (isEmailValid) {
            setEmailSubmitted(true);
            console.log('Email signed up:', email);
            // Add your email sign-up process here
        }
    };

    // Handle phone number submission process
    const handlePhoneSubmit = () => {
        if (isPhoneValid) {
            setPhoneSubmitted(true);
            console.log('Phone number signed up:', phone);
            // Add your phone number sign-up process here
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ zIndex: 10001 }}
            closeAfterTransition
            BackdropProps={{
                timeout: 800, // Adjust the timeout to match the transition duration
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-opaque white overlay
                    backdropFilter: 'blur(20px)', // Apply blur effect
                    transition: 'backdrop-filter 0.8s ease-out, background-color 0.8s ease-out', // Adjust transition duration
                },
            }}
        >
            <ModalBox
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/popup/ZBow.png)`,
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        color: 'white',
                    }}
                >
                    ✕
                </IconButton>
                <PlatformSpace height={15} />
                {/* Title */}
                <ModalTitle
                    variant="h1"
                    sx={{
                        lineHeight: '0.9em',
                        fontSize: isMobile ? '2.4rem' : '3.3rem',
                        fontWeight: 'bold',
                        letterSpacing: '0.04em',
                    }}
                >
                    GET THE LOWDOWN
                    <br />
                    ON THE DOWNLOW
                </ModalTitle>
                <PlatformSpace height={15} />
                {/* Subtitle */}
                <ModalSubtitle variant="body1">
                    {isMobile ? (
                        <React.Fragment>
                            SIGN UP FOR EMAIL OR SMS NOTIFICATIONS TO GET EXCLUSIVE OFFERS & UPDATES
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            SIGN UP FOR EMAIL OR SMS NOTIFICATIONS
                            <br />
                            TO GET EXCLUSIVE OFFERS & UPDATES
                        </React.Fragment>
                    )}
                </ModalSubtitle>
                <PlatformSpace height={15} />
                {/* Email and Phone Input (side by side) */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2,
                        mb: 2,
                        flexDirection: { xs: 'column', sm: 'row' },
                        maxWidth: '600px',
                        mx: 'auto',
                    }}
                >
                    {/* Email Input Subcontainer */}
                    <InputContainer>
                        {emailSubmitted ? (
                            <CheckCircleOutlineIcon sx={{ color: 'white', fontSize: '30px' }} />
                        ) : (
                            <CustomTextField
                                variant="standard"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/popup/Envelope.png`}
                                                alt="Email"
                                                style={{ height: '20px' }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleEmailSubmit} disabled={!isEmailValid}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/popup/Arrow.png`}
                                                    alt="Arrow"
                                                    style={{ height: '20px' }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: false,
                                }}
                            />
                        )}
                    </InputContainer>

                    {/* Phone Input Subcontainer */}
                    <InputContainer>
                        {phoneSubmitted ? (
                            <CheckCircleOutlineIcon sx={{ color: 'white', fontSize: '30px' }} />
                        ) : (
                            <CustomTextField
                                variant="standard"
                                placeholder="Enter Phone #"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                    validatePhone(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/popup/Phone.png`}
                                                alt="Phone"
                                                style={{ height: '20px' }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handlePhoneSubmit} disabled={!isPhoneValid}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/popup/Arrow.png`}
                                                    alt="Arrow"
                                                    style={{ height: '20px' }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: false,
                                }}
                            />
                        )}
                    </InputContainer>
                </Box>
            </ModalBox>
        </Modal>
    );
}

// Styled Components
const ModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: isMobile ? "100%" : '812px',
    height: isMobile ? "auto" : '444px',
    borderRadius: '25px',
    padding: '30px 10px',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const InputContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    minWidth: 300,
    height: isMobile ? '36px' : '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid transparent',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: '80%',
    '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
        borderBottomWidth: '2px',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
        borderBottomWidth: '2px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: 'white',
        borderBottomWidth: '2px',
    },
    // Style the input text color to white
    '& .MuiInputBase-input': {
        color: 'white', // Set the input text color to white
    },
    // Style the placeholder text color and make sure opacity is set to 1
    '& .MuiInputBase-input::placeholder': {
        color: 'white', // Ensure the placeholder text is fully white
        opacity: 1, // Set opacity to 1 to avoid any transparency
    },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
    color: '#6931D4',
}));

const ModalSubtitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: '#333',
}));
