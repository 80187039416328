import React, {useContext} from 'react';
import {styled} from '@mui/styles';
import CatalogContext from "./catalogContext";
import {Chip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export default function CatalogTableChips(props) {
    const {filters, toggleFilter, setMobileFilterOpen} = useContext(CatalogContext);

    const onDeleteFilter = (category, value) => {
        toggleFilter(category, value);
    }

    const onMobileFilter = () => {
        setMobileFilterOpen(true);
    }

    return (
        <React.Fragment>
            {filters?.length > 0 &&
                <React.Fragment>
                    <Grid sx={{minHeight: 50}} container justifyContent="center" alignItems="center" rowSpacing={2} spacing={1}>
                        {Object.keys(filters).map((key) => {
                            return filters[key].map((value, idx) => {
                                return (
                                    <Grid item key={`${key}-${value}-${idx}`}>
                                        <FilterChip
                                            label={value}
                                            onClick={onMobileFilter}
                                            onDelete={() => onDeleteFilter(key, value)}
                                        />
                                    </Grid>
                                );
                            });
                        })}
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

const FilterChip = styled(Chip)(({ theme }) => ({
    fontSize: "0.8rem !important",
    fontFamily: "Poppins !important",
    color: theme.custom.primaryTextColor,
    fontWeight: 700
}));