import React from "react";
import PublicHeaderDesktop from "./publicHeaderDesktop";
import PublicHeaderMobile from "./publicHeaderMobile";
import {isDesktop, isMobile} from "react-device-detect";

export default function PublicHeader({offset}) {

    return (
        <React.Fragment>
            {isDesktop && <PublicHeaderDesktop offset={offset}/>}
            {isMobile && <PublicHeaderMobile offset={offset}/>}
        </React.Fragment>
    )
}