import React from 'react';
import {styled} from "@mui/styles";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function PublicMenuButton({width, href, children}) {
    const history = useHistory();

    const goToLink = () => {
        history.push(href);
    }

    return (
        <StyledButton
            onClick={goToLink}
            width={width}
            variant="text"
        >
            {children.toUpperCase()}
        </StyledButton>
    );
}

const StyledButton = styled(Button)(({theme, width}) => ({
    backgroundImage: "inherit !important",
    backgroundColor: "inherit !important",
    width: width ? `${width}px !important` : "inherit",
    textTransform: "none",
    cursor: "pointer",
    height: 100,
    fontWeight: 600,
    fontSize: "1.1rem !important",
    fontFamily: "Poppins, sans-serif !important", // Use Poppins font
    color: `${theme.palette.secondary.main} !important`, // Set text color to theme secondary color
    "&:hover": {
        borderRadius: 0,
        color: `${theme.palette.primary.main} !important`, // Set text color to theme secondary color
    },
}));
