import React, {useContext} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Link, Stack} from "@mui/material";
import PublicContext from "../../../components/layout/publicContext";
import {isMobile} from "react-device-detect";
import {ORGANIZATION} from "../../../components/constants/organization";
import {styled} from "@mui/styles";

export default function CatalogItemMainFAQ() {
    const {onContact} = useContext(PublicContext);

    const faqs = [
        {
            question: "How do you create the different effects?",
            answer: "Different cannabis strains can induce different effects. We curate our selection based on the cannabinoid profiles and terpenes which contribute to the overall effect."
        },
        {
            question: "Lab testing",
            answer: "All our products undergo rigorous lab testing to ensure purity and potency. We test for cannabinoid content, terpenes, pesticides, and contaminants."
        },
        {
            question: "Will this show up on a drug test?",
            answer: "THC can remain in your system for varying periods depending on usage. It is possible for our products containing THC to show up on a drug test."
        },
        {
            question: "Getting high: what our cannabis feels like",
            answer: "The experience can vary greatly depending on the strain and your personal biochemistry. Generally, it can range from feeling relaxed and euphoric to energized and creative."
        },
    ];

    return (
      <Container maxWidth="lg">
          <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
            }}
          >
              <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
              >
                  <Typography variant={isMobile ? 'h3' : 'h1'} gutterBottom sx={{ paddingLeft: 2 }}>
                      FAQs
                  </Typography>
                  {faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body4">
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
              <Box
                sx={{
                    flex: 1,
                    display: { xs: 'none', md: 'block' },
                }}
              >
                  <Stack
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        direction: "column",
                        alignItems: 'left',
                        padding: '16px',
                        gap: 5,
                    }}
                  >
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/PhoneIcon.jpg`}
                              alt="Call Icon"
                              width="50"
                              height="50"
                          />
                          <Box>
                              <Typography variant="body1">
                                  CALL US TOLL FREE
                              </Typography>
                              <Typography variant="body2">
                                  {ORGANIZATION.supportHours}<br />
                                  {ORGANIZATION.supportPhone}
                              </Typography>
                          </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/EmailIcon.jpg`}
                              alt="Email Icon"
                              width="50"
                              height="50"
                          />
                          <Box>
                              <Typography variant="body1">
                                  EMAIL
                              </Typography>
                              <Typography variant="body2">
                                  <Link href={`mailto:${ORGANIZATION.supportEmail}`} color="inherit" underline="none">
                                      {ORGANIZATION.supportEmail}
                                  </Link>
                              </Typography>
                          </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/ChatIcon.jpg`}
                              alt="Chat Icon"
                              width="55"
                              height="55"
                          />
                          <Box>
                              <Typography variant="body1">
                                  SEND A MESSAGE
                              </Typography>
                              <Typography variant="body2">
                                  <Link href="#" color="inherit" onClick={onContact}>
                                      Click here to connect with us.
                                  </Link>
                              </Typography>
                          </Box>
                      </Box>
                  </Stack>
              </Box>
          </Box>
      </Container>
    );
}

// Define a styled component for the images, bounding them by width or height without distortion
const StyledImage = styled('img')({
    marginRight: '2rem', // Space between image and text
    objectFit: 'contain',
});