import React from "react";
import {FavoriteVariantContextProvider} from "./favoriteVariantContext";
import FavoriteVariantHome from "./favoriteVariantHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";

export default function FavoriteVariantIndex(props) {
    return (
        <PlatformFormContextProvider name="favoriteVariantForm">
            <FavoriteVariantContextProvider {...props}>
                <FavoriteVariantHome {...props}/>
            </FavoriteVariantContextProvider>
        </PlatformFormContextProvider>
    );
}