import React from "react";
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";
import PlatformButton from "../../components/platform/platformButton";

export default function HomeFarmToShop(props) {
    const history = useHistory();

    const onShopAll = () => {
        history.push("/blog/our-process")
    }

    return (
        <Stack justifyContent="center" alignItems="center" spacing={3}>
            <TitleText variant={isMobile ? 'h3' : 'h1'} textAlign="center">
                FARM TO SHOP
            </TitleText>
            <SubText variant="body1" gutterBottom>
                Quality starts with the finest ingredients.
                Discover how we bring our products from a small
                locally-owned farm directly to your doorstep by
                delving into our meticulous process.
            </SubText>
            <PlatformButton size={'md'} onClick={onShopAll}>
                OUR PROCESS
            </PlatformButton>
            <MainImage
                src={`${process.env.PUBLIC_URL}/images/farmtoshop/FarmBudTruck.png`} // Replace with the path to your purple text image
                alt="Purple Text"
            />
        </Stack>
    );
};

const MainImage = styled("img")({
    width: isMobile ? "93%" : "40%",
    height: "auto",
});

const TitleText = styled(Typography)(({ theme }) => ({
    lineHeight: 0.9,
    width: isMobile ? "93%" : "100%",
    color: theme.palette.primary.main, // Purple color from the reference
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    [theme.breakpoints.up('md')]: {
        fontSize: '5rem', // Larger size for bigger screens
    },
}));

const SubText = styled(Typography)(({ theme }) => ({
    width: isMobile ? "93%" : "40%",
    textAlign: "center"
}));
