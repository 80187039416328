import React from "react";
import PropTypes from "prop-types";
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';

export default function PlatformButton(props) {
    const { children, fullWidth, size, onClick, variant, ...allProps } = props;

    // Determine the styles based on size prop
    let sizeStyles = {};
    if (size === "sm") {
        sizeStyles = smallButtonStyles;
    } else if (size === "lg") {
        sizeStyles = largeButtonStyles;
    } else if (size === "md") {
        sizeStyles = mediumButtonStyles;
    } else {
        sizeStyles = defaultButtonStyles;
    }

    return (
        <ColorButton
            {...allProps}
            onClick={onClick}
            disableElevation
            fullWidth={fullWidth}
            variant={variant}
            sx={sizeStyles}
        >
            {children?.toUpperCase()}
        </ColorButton>
    );
}

// Styled ColorButton with variant-specific styles for outlined and contained
const ColorButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    borderRadius: 12,
    fontSize: "1.1rem",
    letterSpacing: '0.03em',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,  // Default for contained variant
    '&:hover': {
        backgroundColor: theme.palette.primary.main,  // Hover for contained variant
    },
    '&.MuiButton-outlined': {
        color: theme.palette.secondary.main,  // Text color for outlined
        backgroundColor: 'transparent',       // Transparent background for outlined
        border: `3px solid ${theme.palette.secondary.main}`,  // Border for outlined
    },
    '&.MuiButton-outlined:hover': {
        backgroundColor: theme.palette.secondary.main,   // Light background on hover for outlined
        borderColor: 'transparent',      // Border color change on hover for outlined
        color: 'white',
    },
}));

// Default styles for platform button
const defaultButtonStyles = {
    height: 40,
};

// Small button styles
const smallButtonStyles = {
    ...defaultButtonStyles,
    width: 120,
};

// Medium button styles
const mediumButtonStyles = {
    width: 200,
    height: 50,
};

// Large button styles
const largeButtonStyles = {
    width: 300,
    height: 55,
};

PlatformButton.propTypes = {
    children: PropTypes.any,
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    variant: PropTypes.oneOf(["contained", "outlined"]),
    onClick: PropTypes.func,
};

PlatformButton.defaultProps = {
    variant: "contained",
};
