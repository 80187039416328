import API from "api";

export default {

    getFavorites(successCallback, errorCallback) {
        API.GET({
            url: `/products/favorites`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    deleteFavorite(productId, successCallback, errorCallback) {
        API.DELETE({
            url: `/products/favorites/${productId}`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}
