import React from 'react';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {useHistory} from "react-router-dom";

// Styled component for the full-width image
const FullWidthImage = styled('img')({
    width: '100vw',
    height: 'auto',
    cursor: 'pointer',
    display: 'block',
    margin: 0,
    padding: 0,
});

export default function HomeFullWidthImage({ link, image }) {
    const history = useHistory();

    const handleClick = () => {
        history.push(link);  // Redirect to the specified link when the image is clicked
    };

    return (
        <Box onClick={handleClick}>
            <FullWidthImage src={`${process.env.PUBLIC_URL}/images/${image}`} alt="" />
        </Box>
    );
}