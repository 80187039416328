import {useEffect, useState} from "react";

export default function usePlatformTextField(
    {context, name, groupBy, defaultValue, unbound, required, email, type, min, max, emailOrPhone, creditCard,
        creditCardDate, cvv, numberStep = 1, fieldRef}) {

    const [value, setValue] = useState(defaultValue || "");
    const [valid, setValid] = useState(true);

    useEffect(() => {
        if (context && context.addField) {
            context.addField(name, {
                name: name,
                groupBy: groupBy,
                unbound: unbound,
                offScreen: false,
                setValue: setFormValue,
                validate: onValidate,
                onScrollIntoView,
                setDefaultValue,
                changeHandler
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
        return () => {
            if (context && context.updateField) {
                context.updateField(name, groupBy, { offScreen: true });
            }
        };
    }, []);

    useEffect(() => {
        if (context && context.updateField) {
            context.updateField(name, groupBy, { unbound: unbound });
            if (unbound === "true") {
                context.removeField(name, groupBy);
            }
        }
    }, [context, name, unbound]);

    useEffect(() => {
        if (context != null) {
            setValue(defaultValue || "");
        }
    }, [defaultValue]);

    const setFormValue = (value) => {
        if (value !== null && value !== undefined) {
            setValue(value);
        }
    };

    const setDefaultValue = (defaultValue) => {
        if (context != null) {
            context.form.current.setValue(name, defaultValue, groupBy);
        }
        setValue(defaultValue || "");
    };

    const onValidate = () => {
        const valueToCheck = context.form.current.getValue(name, groupBy);
        const unboundToCheck = context.form.current.isUnbound(name, groupBy);
        const offScreenToCheck = context.form.current.isOffScreen(name, groupBy);

        if (offScreenToCheck) {
            return true;
        }

        if (unboundToCheck === "true") {
            setValid(true);
            return true;
        }

        if (!required) {
            setValid(true);
            return true;
        }

        if (!valueToCheck) {
            setValid(false);
            return false;
        }

        if (creditCard) {
            let isValid = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/.test(valueToCheck);
            setValid(isValid);
            return isValid;
        }

        if (creditCardDate) {
            const parts = valueToCheck.split("/");

            // Check if both parts (month and year) are present
            if (parts.length !== 2) {
                setValid(false);
                return false;
            }

            const [month, year] = parts.map(part => parseInt(part, 10));
            // Validate the month and year
            if (isNaN(month) || isNaN(year) || month < 1 || month > 12 || year < 0 || year > 99) {
                setValid(false);
                return false;
            }

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear() % 100;
            const currentMonth = currentDate.getMonth() + 1;
            if (year < currentYear || (year === currentYear && month < currentMonth)) {
                setValid(false);
                return false;
            }
        }

        if (cvv) {
            let isValid = /^[0-9]{3}$/.test(valueToCheck);
            setValid(isValid);
            return isValid;
        }

        if (emailOrPhone) {
            let isValid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(valueToCheck) ||
                /^(?:\+?1[-.●]?[-●.]?)?\(?[2-9][0-9]{2}\)?[-.●]?[2-9][0-9]{2}[-.●]?[0-9]{4}$/.test(valueToCheck);
            setValid(isValid);
            return isValid;
        }

        if (email) {
            let isValid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(valueToCheck);
            setValid(isValid);
            return isValid;
        }

        setValid(true);
        return true;
    };

    const onScrollIntoView = () => {
        if (!value) {
            const viewportHeight = window.innerHeight;
            const elementPosition = fieldRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - (viewportHeight / 2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            // Ensure focus is set after scrolling completes
            setTimeout(() => {
                fieldRef.current.focus();
            }, 500); // Adjust the timeout as needed
        }
    };

    const changeHandler = (event) => {
        let value = event.target.value;

        if (type === "number" && isNaN(value)) {
            return;
        }

        if (type === "number" && value) {
            if (max && parseFloat(value) > max) {
                value = max;
            }
            if (min && parseFloat(value) < min) {
                value = min;
            }
        }

        setValue(value);

        if (context != null) {
            context.form.current.onChange(name, value, false, groupBy);
        }
    };

    const blurHandler = (event) => {
        if (context != null) {
            context.form.current.onBlur(name, event);
        }
    };

    const onIncrement = (event) => {
        let newValue = parseFloat(value) + numberStep;

        while (newValue % numberStep !== 0) {
            newValue--;
        }

        newValue = String(newValue);

        setValue(newValue);

        if (context != null) {
            context.form.current.onChange(name, newValue, null, groupBy);
        }

        setValid(true);

        return newValue;
    }

    const onDecrement = (event) => {
        let newValue = value;

        if ((value - numberStep) >= 0) {
            newValue = parseFloat(value) - numberStep;
            while (newValue % numberStep !== 0) {
                newValue++;
            }
            newValue = String(newValue);
        }

        newValue = newValue === "0" ? "1" : newValue;

        setValue(newValue);

        if (context != null) {
            context.form.current.onChange(name, newValue, null, groupBy);
        }

        setValid(true);

        return newValue;
    }

    return { value, valid, setFormValue, setDefaultValue, onValidate, onIncrement, onDecrement, blurHandler, changeHandler };
}