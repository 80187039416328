import * as React from 'react';
import {isDesktop, isMobile} from "react-device-detect";
import OrderReceiptHomeMobile from "./orderReceiptHomeMobile";
import OrderReceiptHomeDesktop from "./orderReceiptHomeDesktop";

export default function OrderReceiptHome(props) {

    return (
        <React.Fragment>
            {isMobile && <OrderReceiptHomeMobile/>}
            {isDesktop && <OrderReceiptHomeDesktop/>}
        </React.Fragment>
    )
}