import React, {useCallback, useContext, useEffect, useReducer, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import CartContext from "../cart/cartContext";
import CatalogAPI from "./catalogApi";
import toTitleCase, {toCategoryHandle} from "../../components/util/stringUtil";
import {debounce} from "lodash";

export const CatalogContext = React.createContext({});
export default CatalogContext;

const filterReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_FILTER':
            const {category, value} = action.payload;
            const existingValues = state[category] || [];
            const newValues = existingValues.includes(value)
                ? existingValues.filter((v) => v !== value)
                : [...existingValues, value];
            if (newValues.length === 0) {
                const newState = { ...state };
                delete newState[category];
                return newState;
            }
            return { ...state, [category]: newValues };
        case 'RESET_FILTERS':
            return Object.fromEntries(Object.keys(state).map(key => [key, []]));
        case 'SET_FILTERS':
            return action.payload;
        default:
            return state;
    }
};

export const CatalogContextProvider = ({children}) => {
    const {category} = useParams();
    const {addToCart, buyItNow} = useContext(CartContext);
    const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
    const [filters, dispatch] = useReducer(filterReducer, {});
    const [lastUrlFilters, setLastUrlFilters] = useState(null);
    const [products, setProducts] = useState([]);
    const [filterOptionsSelected, setFilterOptionsSelected] = useState({});
    const [filterOptionsAll, setFilterOptionsAll] = useState({});
    const [displayedCategory, setDisplayedCategory] = useState(null);
    const [fadeIn, setFadeIn] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const url = new URL(window.location.href);
    const [filterQuery, setFilterQuery] = useState(null);
    const searchFieldRef = useRef(null);

    useEffect(() => {
        if (url.search.length <= 0) {
            applyFilters(true);
        }
    }, [category]);

    useEffect(() => {
        updateFiltersFromUrl();
    }, [location.search]);

    useEffect(() => {
        updateUrlFromFilters();
        if (lastUrlFilters === null) {
            if (url.search.length <= 0) {
                applyFilters(false);
            }
        } else {
            applyFilters(false);
        }
    }, [filters]);

    const delayedQuery = useCallback(debounce(() => applyFilters(false), 800), [filterQuery]);

    useEffect(() => {
        if (filterQuery !== null) {
            delayedQuery();
        }
        return delayedQuery.cancel;
    }, [filterQuery, delayedQuery]);

    const onFilterProducts = (filterQuery) => {
        setFilterQuery(filterQuery);
    }

    const onAddToCart = (productId, variantId, callback) => {
        const payload = {
            "productId": productId,
            "variantId": variantId,
            "quantity": 1
        }
        addToCart(payload, callback);
    }

    const onBuyItNow = (productId, variantId, callback) => {
        const payload = {
            "productId": productId,
            "variantId": variantId,
            "quantity": 1
        }
        buyItNow(payload, callback);
    }

    const filtersToQueryString = (filters) => {
        return Object.entries(filters)
            .filter(([key, values]) => values.length > 0)
            .map(([key, values]) => `${key}=${values.join(",")}`)
            .join("&");
    };

    const queryStringToFilters = (search) => {
        const searchParams = new URLSearchParams(search);
        const filters = {};
        for (const [key, value] of searchParams.entries()) {
            const values = value.split(",").filter(v => v.trim() !== ''); // Ignore empty strings
            if (values.length > 0) filters[key] = values;
        }
        return filters;
    };

    const updateFiltersFromUrl = () => {
        const urlFilters = queryStringToFilters(location.search);
        if (JSON.stringify(urlFilters) !== JSON.stringify(lastUrlFilters)) {
            dispatch({type: 'SET_FILTERS', payload: urlFilters});
            setLastUrlFilters(urlFilters);
        }
    };

    const updateUrlFromFilters = () => {
        if (JSON.stringify(filters) !== JSON.stringify(lastUrlFilters)) {
            const queryString = filtersToQueryString(filters);
            history.replace({ pathname: location.pathname, search: queryString });
            setLastUrlFilters(filters);
        }
    };

    const clearSearchField = () => {
        if (searchFieldRef.current) {
            searchFieldRef.current.clearTextField();
        }
    };

    const applyFilters = (useFade) => {
        if (useFade) {
            setFadeIn(false);
        }
        CatalogAPI.filterProductsByCategoryHandle(toCategoryHandle(category), filters, filterQuery, (data) => {
            setFilterOptionsSelected(data.availableFilterOptions);
            setFilterOptionsAll(data.allFilterOptions);
            let filteredProducts = data.variants;
            if (filterQuery) {
                const keywords = filterQuery.split(' ');
                filteredProducts = data.variants.filter(variant =>
                    keywords.every(keyword => variant.variant.name.toLowerCase().includes(keyword.toLowerCase()))
                );
            }
            setProducts(filteredProducts);
            setDisplayedCategory(toTitleCase(category));
            if (useFade) {
                setFadeIn(true);
            }
        });
    };

    const toggleFilter = (category, value) => {
        dispatch({type: 'TOGGLE_FILTER', payload: {category, value}});
    };

    const resetFilters = () => {
        dispatch({type: 'RESET_FILTERS'});
        setFilterQuery(null);
        clearSearchField();
    };

    return (
        <CatalogContext.Provider
            value={{
                filters,
                resetFilters,
                toggleFilter,
                onAddToCart,
                onBuyItNow,
                onFilterProducts,
                searchFieldRef,
                fadeIn,
                products,
                filterOptionsSelected,
                filterOptionsAll,
                displayedCategory,
                mobileFilterOpen,
                setMobileFilterOpen,
            }}
        >
            {children}
        </CatalogContext.Provider>
    );
};
