import * as React from 'react';
import {useContext, useState} from 'react';
import useLayoutOptions from "../../components/hook/useLayoutOptions";
import PublicHeader from "../../components/layout/publicHeader";
import PublicFooter from "../../components/layout/publicFooter";
import HomeBackgroundVideo from "./homeBackgroundVideo";
import HomeFAQ from "./homeFAQ";
import HomeWhatWeDo from "./homeWhatWeDo";
import HomeBestSellers from "./homeBestSellers";
import HomeTrendingNow from "./homeTrendingNow";
import HomeAsSeenIn from "./homeAsSeenIn";
import HomeEthos from "./homeEthos";
import HomeSpacer from "./homeSpacer";
import FavoriteVariantIndex from "../catalog/favorite/favoriteVariantIndex";
import {isMobile} from "react-device-detect";
import PublicContext from "../../components/layout/publicContext";
import HomeSlider from "./homeSlider";
import PlatformSpace from "../../components/platform/platformSpace";
import Home100Legal from "./home100Legal";
import HomeMadeInTexas from "./homeMadeInTexas";
import HomeFarmToShop from "./homeFarmToShop";
import HomeFullWidthImage from "./homeFullWidthImage";

export default function HomeHome(props) {
    const { showPromoBanner } = useContext(PublicContext);

    const [slide, setSlide] = useState(0);
    //const {onLayoutOptions} = useContext(AppContext);
    const isBeta = Boolean(localStorage.getItem("beta"));

    useLayoutOptions({
        backgroundComponent: HomeBackgroundVideo,
        headerComponent: PublicHeader,
        footerComponent: PublicFooter,
        video: SLIDES[slide].video,
        blur: "10px",
        //topMargin: !showPromoBanner && isMobile ? 70 : 0
        topMargin: isMobile ? 70 : 0
    });

    // const onChange = (slide) => {
    //     setSlide(slide);
    //     onLayoutOptions({
    //         backgroundComponent: HomeBackgroundVideo,
    //         video: SLIDES[slide].video,
    //         backgroundOpacity: 0.2,
    //         blur: "10px",
    //         topMargin: 70
    //     });
    // }

    return (
        <React.Fragment>
            {isMobile && <HomeSpacer/>}
            <PlatformSpace heigh={15}/>
            <HomeSlider/>
            {/*{!isBeta && <HomeBeta/>}*/}
            {/*<HomeFeature2/>*/}
            <HomeSpacer/>
            <Home100Legal/>
            <HomeSpacer/>
            <FavoriteVariantIndex showButton={true}/>
            <HomeSpacer/>
            <HomeAsSeenIn/>
            <HomeSpacer/>
            <HomeWhatWeDo/>
            <HomeSpacer/>
            <HomeMadeInTexas/>
            <HomeSpacer/>
            <HomeEthos/>
            <HomeSpacer/>
            <HomeBestSellers/>
            <HomeSpacer/>
            <HomeFarmToShop/>
            <HomeSpacer/>
            <HomeTrendingNow/>
            <HomeSpacer/>
            {/*{isMobile ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <HomeFullWidthImage image="productpromo/WyldTHCLeftSide.jpg" link="/catalog/gummies?Brand=Wyld%20Gummies"/>*/}
            {/*        <HomeFullWidthImage image="productpromo/WyldTHCRightSide.jpg" link="/catalog/gummies?Brand=Wyld%20Gummies"/>*/}
            {/*    </React.Fragment>*/}
            {/*) : (*/}
            {/*    <HomeFullWidthImage image="productpromo/WyldTHC.jpg" link="/catalog/gummies?Brand=Wyld%20Gummies"/>*/}
            {/*)}*/}
            {/*{isMobile ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <HomeFullWidthImage image="productpromo/CANNRightSide.jpg" link="/catalog/gummies?Brand=Social%20Tonic"/>*/}
            {/*        <HomeFullWidthImage image="productpromo/CANNLeftSide.jpg" link="/catalog/gummies?Brand=Social%20Tonic"/>*/}
            {/*    </React.Fragment>*/}
            {/*) : (*/}
            {/*    <HomeFullWidthImage image="productpromo/CANN.jpg" link="/catalog/beverages?Brand=Social%20Tonic"/>*/}
            {/*)}*/}
            {/*{isMobile ? (*/}
            {/*    <HomeFullWidthImage image="ourstory/OurStoryMobile.jpg" link="about-us"/>*/}
            {/*) : (*/}
            {/*    <HomeFullWidthImage image="ourstory/OurStory.jpg" link="about-us"/>*/}
            {/*)}*/}
            {/*<HomeProductFeatures/>*/}
            {/*<HomeSpacer/>*/}
            {/*<HomeAboutUs/>*/}
            <HomeSpacer/>
            <FavoriteVariantIndex showButton={true}/>
            {/*<HomeSpacer/>*/}
            {/*<HomeLearnAboutGreenbelt/>*/}
            <HomeSpacer/>
            <HomeFAQ/>
            <HomeSpacer/>
            <HomeFullWidthImage image="home/AssHat.jpg" link="about-us"/>
            {/*<HomeBenefitsBar/>*/}
            {/*<HomeUglyCategories/>*/}
        </React.Fragment>
    )
}

const SLIDES = [
    {
        title: "Wedding Cake",
        image: "slides/thca_kushmintz.png",
        link: "catalog/flower?Brand=THCA%20Flower"
    },
    {
        title: "Wedding Cake",
        image: "slides/thca_kushmintz.png",
        link: "catalog/flower?Brand=THCA%20Flower"
    }
]