import React, { useContext } from 'react';
import CatalogFilterControl from './catalogFilterControl';
import CatalogCheckboxGroupFilter from './catalogCheckboxGroupFilter';
import { styled } from '@mui/styles';
import CatalogContext from "./catalogContext";
import Button from "@mui/material/Button";
import PlatformSpace from "../../components/platform/platformSpace";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/material";

export default function CatalogFilterDesktop({ handleClose }) {
    const { resetFilters, filterOptionsAll } = useContext(CatalogContext);
    const allKeys = Object.keys(filterOptionsAll);

    return (
        <React.Fragment>
            <Filter style={{ minWidth: 275, overflowY: 'hidden' }}>
                <ResetButton variant="contained" onClick={resetFilters}>
                    RESET FILTER
                </ResetButton>
                <PlatformSpace height={isMobile ? 0 : 30} />
                {allKeys && allKeys.length > 0 ? (
                    allKeys.map((key, idx) => {
                        return (
                            <CatalogFilterControl
                                key={idx}
                                title={key}
                                alwaysExpanded={true}
                                content={<CatalogCheckboxGroupFilter disableSearch={true} category={key} />}
                            />
                        );
                    })
                ) : (
                    <div>No filters available</div>
                )}

                <PlatformSpace height={isMobile ? 0 : 30} />
                <Stack justifyContent="flex-start" direction="row" alignItems="flex-start" spacing={1}>
                    {isMobile &&
                        <SaveButton variant="contained" onClick={handleClose}>
                            Save & close
                        </SaveButton>
                    }
                    <ResetButton variant="contained" onClick={resetFilters}>
                        RESET FILTER
                    </ResetButton>
                </Stack>
            </Filter>
        </React.Fragment>
    );
}

const Filter = styled('div')(({ selected }) => ({
    paddingLeft: 20,
}));

const SaveButton = styled(Button)(({ theme }) => ({
    height: 35,
    width: "45vw !important",
    backgroundImage: "none !important",
    color: "rgba(0, 0, 0, 0.8) !important",
    border: "2px solid rgba(0, 0, 0, 0.8) !important",
    backgroundColor: "rgba(0, 0, 0, 0.075) !important"
}));

const ResetButton = styled(Button)(({ theme }) => ({
    height: 35,
    width: isMobile ? "35vw !important" : "150px !important",
    backgroundImage: "none !important",
    color: "rgba(0, 0, 0, 0.8) !important",
    border: "2px solid rgba(0, 0, 0, 0.8) !important",
    backgroundColor: "transparent !important",
    '&:hover': {
        backgroundColor: theme.palette.secondary.main + " !important", // Primary color on hover
        color: theme.palette.secondary.contrastText + " !important", // Contrast text color
    }
}));
