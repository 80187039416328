import React, {useContext, useRef} from "react";
import {makeStyles, styled} from "@mui/styles";
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import styles from "./platformStyles";
import InputAdornment from "@mui/material/InputAdornment";
import PlatformFormContext from "./platformFormContext";
import mergeStyles from "../util/mergeStyles";
import usePlatformTextField from "../hook/usePlatformTextField";
import InputMask from 'react-input-mask';
import IconButton from "@mui/material/IconButton";
import {Add, Remove} from "@mui/icons-material";

const useStyles = makeStyles(styles);

const formatCreditCardNumber = (value) => {
    return value.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim();
};

export default function PlatformTextField(props) {
    const {
        disabled,
        onChange,
        centered,
        fullWidth,
        name,
        label,
        email,
        errorText,
        helperText,
        endAdornment,
        required,
        defaultValue,
        inverted,
        inline,
        multiline,
        startAdornment,
        creditCard,
        unbound,
        groupBy,
        inputRef,
        creditCardDate,
        onIncrementDecrementChange,
        cvv,
        min = 10,
        max = 10000,
        type,
        emailOrPhone,
        disableAutoComplete,
        autoComplete,
        ...allProps
    } = props;

    const classes = useStyles();
    const fieldRef = useRef(null); // Add a ref to the field
    const context = useContext(PlatformFormContext);
    const { value, valid, blurHandler, changeHandler, onIncrement, onDecrement } = usePlatformTextField({
        ...props,
        fieldRef,
        context,
        changeHandler: (e) => {
            let newValue = e.target.value;
            if (creditCard) {
                newValue = formatCreditCardNumber(newValue);
            }
            changeHandler(e, newValue);
        }
    });

    let inputProps = {
        "aria-label": "outlined-input",
        name,
        autocomplete: "cc-csc",
        min,
        max,
        sx: {
            "&": {
                textAlign: centered ? "center" : "inherit",
            },
        }
    };

    if (disabled) {
        inputProps.readOnly = Boolean(true);
    }

    const getMask = () => {
        if (creditCard) return "9999 9999 9999 9999";
        if (creditCardDate) return "99/99";
        if (cvv) return "999";
        return "";
    };

    const onIncrementChange = () => {
        const value = onIncrement();
        if (typeof onIncrementDecrementChange === "function") {
            onIncrementDecrementChange(value);
        }
    };

    const onDecrementChange = () => {
        const value = onDecrement();
        if (typeof onIncrementDecrementChange === "function") {
            onIncrementDecrementChange(value);
        }
    };

    return (
      <div style={{ width: fullWidth ? "100%" : "inherit" }}>
          {label &&
            <InputLabel className={classes.platformFormLabel}
                        style={mergeStyles(inverted && { color: "white" }, inline && { display: "inline-block" })}
                        htmlFor={name}>
                {`${label ? label : ""}${required ? '*' : ''}`}
            </InputLabel>
          }
          <FormControl className={classes.platformFormControl} fullWidth={fullWidth} variant="outlined">
              {multiline ? (
                <StyledOutlinedInput
                  ref={fieldRef}
                  valid={+valid}
                  autoComplete={disableAutoComplete ? "cc-acme-123" : autoComplete}
                  fullWidth={fullWidth}
                  multiline={multiline}
                  inputRef={inputRef}
                  notched={false}
                  {...allProps}
                  error={!valid}
                  value={value}
                  onBlur={blurHandler}
                  onChange={changeHandler}
                  startAdornment={
                      <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                          {startAdornment ? <span>{startAdornment}</span> : null}
                          {type === "number" && (
                            <IconButton onClick={onDecrementChange} size="small">
                                <Remove />
                            </IconButton>
                          )}
                      </InputAdornment>
                  }
                  endAdornment={
                      <InputAdornment position="end" sx={{ marginLeft: "8px" }}>
                          {endAdornment ? <span>{endAdornment}</span> : null}
                          {type === "number" && (
                            <IconButton onClick={onIncrementChange} size="small">
                                <Add />
                            </IconButton>
                          )}
                      </InputAdornment>
                  }
                  aria-describedby="outlined-helper-text"
                  inputProps={inputProps}
                />
              ) : (
                <InputMask mask={getMask()} value={value} onBlur={blurHandler} onChange={changeHandler}>
                    {(inputProps) => (
                      <StyledOutlinedInput
                        ref={fieldRef}
                        valid={+valid}
                        autoComplete={disableAutoComplete ? "cc-acme-123" : autoComplete}
                        fullWidth={fullWidth}
                        multiline={multiline}
                        inputRef={inputRef}
                        notched={false}
                        {...allProps}
                        error={!valid}
                        value={value}
                        onBlur={blurHandler}
                        onChange={changeHandler}
                        startAdornment={
                            <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                                {startAdornment ? <span>{startAdornment}</span> : null}
                                {type === "number" && (
                                  <IconButton onClick={onDecrementChange} size="small">
                                      <Remove />
                                  </IconButton>
                                )}
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end" sx={{ marginLeft: "8px" }}>
                                {endAdornment ? <span>{endAdornment}</span> : null}
                                {type === "number" && (
                                  <IconButton onClick={onIncrementChange} size="small">
                                      <Add />
                                  </IconButton>
                                )}
                            </InputAdornment>
                        }
                        aria-describedby="outlined-helper-text"
                        inputProps={inputProps}
                      />
                    )}
                </InputMask>
              )}
              {(valid && helperText) && <FormHelperText style={mergeStyles(inverted && { color: "white" })}
                                                        id="outlined-helper-text">{helperText}</FormHelperText>}
              {(!valid && errorText) && <FormHelperText style={{ color: "red" }}>{errorText}</FormHelperText>}
          </FormControl>
      </div>
    );
};

const StyledOutlinedInput = styled(OutlinedInput)(({ theme, valid = true }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: !valid ? '2px !important' : '0 !important',
    },
    '& .MuiInputAdornment-root': {
        paddingTop: 1,
        paddingLeft: 1,
        '& .MuiSvgIcon-root': {
            color: "rgb(100, 109, 117) !important",
        },
    },
}));
