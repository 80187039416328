import PublicLayout from "../../../components/layout/publicLayout";
import React from "react";
import PrivacyPolicyHome from "./privacyPolicyHome";

export default function PrivacyPolicyIndex(props) {
    return (
        <PublicLayout>
            <PrivacyPolicyHome/>
        </PublicLayout>
    );
}