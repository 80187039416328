import React from 'react';
import {Box, Button, Container, Grid, Stack, Typography} from '@mui/material';
import {isMobile} from "react-device-detect";
import {styled} from "@mui/material/styles";
import {useHistory} from "react-router-dom";
import PlatformButton from "../../components/platform/platformButton";
import PlatformSpace from "../../components/platform/platformSpace";

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    maxWidth: 325,
    marginTop: theme.spacing(1), // Small margin above the button
}));

// Shrink the image inside the container by applying 23% reduction
const StyledImage = styled('img')({
    width: '77%',        // Reduce the image size by 23% (100% - 23% = 77%)
    height: '77%',       // Maintain aspect ratio by reducing height as well
    objectFit: 'contain',  // Ensures image stays inside the container without distortion
    borderRadius: '20px',  // Border radius inside the container
});

const ImageWrapper = styled(Box)(({ theme }) => ({
    width: '100%',          // Full width of the grid item
    height: '300px',        // Set explicit height for square images
    position: 'relative',   // Required for absolute positioning inside the wrapper
    borderRadius: '20px',   // Rounded corners
    overflow: 'hidden',     // Keep the image inside the border radius
    border: `1px solid ${theme.palette.secondary.main}`,  // Border stays the same size
    display: 'flex',        // Flex to center the image
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: "rgba(183, 132, 255, 0.25) 0px 6px 25px 0px",
}));

const TrendingProduct = ({ product, clickHandler }) => (
    <Box sx={{ textAlign: 'center', margin: "0 15px 0 15px"}}>
        <ImageWrapper>
            <StyledImage src={product.imageUrl} alt={product.title}/>
        </ImageWrapper>
        <PlatformSpace height={20}/>
        <Typography variant="body0" sx={{ mt: 1 }}>
            {product.title.toUpperCase()}
        </Typography>
        <Container sx={{minHeight: 60}}>
            <Typography variant="body1" color="text.secondary">
                {product.description}
            </Typography>
        </Container>
        <PlatformButton size="lg" onClick={() => clickHandler(product.productLink)}>
            {product.buttonText}
        </PlatformButton>
    </Box>
);

export default function HomeTrendingNow() {
    const history = useHistory();

    const onClick = (link) => {
        history.push(link);
    };

    const products = [
        {
            title: 'Delicious Delta-9 Gummies',
            description: 'Euphoric relaxation for cannabis enthusiasts',
            imageUrl: `${process.env.PUBLIC_URL}/images/trendingnow/GumDrop.jpg`,
            buttonText: 'SHOP D9 GUMMIES',
            productLink: "/catalog/gummies?Cannabinoid=Delta9"
        },
        {
            title: 'THCA Liquid Diamond 1g Vapes',
            description: 'Stronger and Longer Lasting',
            imageUrl: `${process.env.PUBLIC_URL}/images/trendingnow/Vape.jpg`,
            buttonText: 'SHOP VAPE DISPOSABLES',
            productLink: "/catalog/vapes?Brand=Disposable%20Vapes"
        },
        {
            title: 'Freshly Harvested THCA Flower',
            description: 'Connoisseur-grade & Indoor Grown',
            imageUrl: `${process.env.PUBLIC_URL}/images/trendingnow/THCA.jpg`,
            buttonText: 'SHOP THCA STRAINS',
            productLink: "/catalog/flower"
        },
    ];

    return (
        <Container maxWidth="lg" disableGutters>
            <Stack justifyContent="center" alignItems="center" spacing={1}>
                <Typography variant={isMobile ? "h4" : "h3"} textAlign="center">
                    TRENDING NOW
                </Typography>
                <Typography variant="body1" textAlign="center">
                    See what all the hype is about
                </Typography>
                <PlatformSpace height="5"/>
                <Grid
                    container
                    spacing={4} // Consider reducing this if overflow persists
                    justifyContent="center"
                    sx={{
                        padding: 0, // Remove padding on the Grid container
                        margin: 0,  // Remove any default margins
                        width: '100%', // Ensure grid container doesn't exceed viewport width
                    }}
                >
                    {products.map((product, index) => (
                        <Grid
                            item
                            key={index}
                            xs={12}   // Full width on mobile (xs)
                            sm={12}   // Full width on small screens (sm)
                            md={4}    // One-third width on medium+ screens (md)
                            sx={{
                                padding: '0px !important',  // Remove internal padding of MuiGrid
                                marginBottom: 5,
                            }}
                        >
                            <TrendingProduct product={product} clickHandler={onClick} />
                        </Grid>

                    ))}
                </Grid>
            </Stack>
        </Container>
    );
}
