export default function generateVariantName(product, variant) {
    if (!product || !product.variantOptions || !variant) {
        return;
    }
    const variantOptionsMap = new Map();

    // Create a map of displayName to sortOrder from variantOptions
    product.variantOptions.forEach(option => {
        variantOptionsMap.set(option.displayName, option.sortOrder);
    });

    // Sort variantOptionValues based on the sortOrder from the map
    const sortedOptions = variant.variantOptionValues.sort((a, b) => {
        return variantOptionsMap.get(a.label) - variantOptionsMap.get(b.label);
    });

    // Concatenate the values
    const optionValues = sortedOptions.map(option => option.value);
    return optionValues.join(' ');
}