import React from "react";
import {makeStyles, ThemeProvider, withStyles} from '@mui/styles';
import {createTheme} from "@mui/material/styles";
import Button from '@mui/material/Button';
import {lightGreen} from '@mui/material/colors';
import styles from "./platformStyles";
import cx from "classnames";

const useStyles = makeStyles(styles);

const theme = createTheme({
    palette: {
        primary: lightGreen,
    },
});

export default function PlatformLink(props) {
    const { children, size, onClick } = props;
    const classes = useStyles();

    let combinedClassName = cx({
        [classes.platformButtonSmall]: size && size === "sm",
        [classes.platformButtonLarge]: size && size === "lg",
        [classes.platformButton]: true
    });

    return (
        <ThemeProvider theme={theme}>
            <ColorButton
                onClick={onClick}
                disableElevation
                color="primary"
                classes={{
                    root: classes.platformLink,
                }}>
                {children}
            </ColorButton>
        </ThemeProvider>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: "black",
        backgroundColor: "transparent",
        '&:hover': {
            backgroundColor: "transparent",
            opacity: 0.7
        },
    },
}))(Button);