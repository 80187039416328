export function clearStorage(key) {
  localStorage.setItem(key, null);
}

export function storeString(key, string) {
  localStorage.setItem(key, string);
}

export function storeObject(key, object) {
  localStorage.setItem(key, JSON.stringify(object));
}

export function retrieveObject(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function retrieveString(key) {
  return localStorage.getItem(key);
}

export function deleteObject(key) {
  localStorage.removeItem(key);
}

export function isObject(key) {
  const storedObject = localStorage.getItem(key);
  return storedObject !== null && storedObject !== undefined;
}