import PublicLayout from "../../../components/layout/publicLayout";
import React from "react";
import RefundPolicyHome from "./refundPolicyHome";

export default function RefundPolicyIndex(props) {
    return (
        <PublicLayout>
            <RefundPolicyHome/>
        </PublicLayout>
    );
}