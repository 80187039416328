import React from 'react';
import {Box, Table, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";

const StyledTable = styled(Box)(({ theme }) => ({
    border: `1px solid black`,
    borderCollapse: 'collapse',
    '& th, & td': {
        textAlign: 'left',
        borderRight: `1px solid black`,
    },
}));

const StyledIcon = styled(Box)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '& td': {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const contentArray = [
    {
        method: 'Comprehensive Testing',
        cannabinoidContent: true,
        contaminationDetection: true,
    },
    {
        method: 'Potency Testing',
        cannabinoidContent: true,
        contaminationDetection: false,
    },
];

export default function BlogOurProcessLabTesting() {
    return (
        <Box sx={{ backgroundColor: '#f3efee', paddingTop: 2, paddingBottom: 2 }}>
            <Box sx={{ px: isMobile ? 1 : 10, maxWidth: isMobile ? "100vw" : '100vw', mx: isMobile ? 1 : 'auto' }}>
                <Box sx={{ marginBottom: 4 }}>
                    <img src={`${process.env.PUBLIC_URL}/images/blog/OurProcessFullPanel.jpg`} alt="Lab Testing" style={{ width: '100%', borderRadius: 12 }} />
                </Box>
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}  gutterBottom>
                        Comprehensive, Independent Lab Testing
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ensuring safety and consistency is our top priority. That's why we send a sample from every batch to a
                        third-party, DEA-certified laboratory to provide thorough full-panel test results you can rely on.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Our test results are easily accessible through a QR code on our product packaging and on this page.
                    </Typography>
                </Box>
                <TableContainer component={StyledTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Testing Method</TableCell>
                                <TableCell align="center">Cannabinoid Analysis</TableCell>
                                <TableCell align="center">Contamination Screening</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contentArray.map((content, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell>{content.method}</TableCell>
                                    <TableCell align="center">
                                        {content.cannabinoidContent ? (
                                            <StyledIcon>
                                                <CheckCircleIcon color="success" />
                                            </StyledIcon>
                                        ) : (
                                            <StyledIcon>
                                                <CloseIcon color="error" />
                                            </StyledIcon>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {content.contaminationDetection ? (
                                            <StyledIcon>
                                                <CheckCircleIcon color="success" />
                                            </StyledIcon>
                                        ) : (
                                            <StyledIcon>
                                                <CloseIcon color="error" />
                                            </StyledIcon>
                                        )}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};
