import * as React from 'react';
import {Box, Container, Link, Typography} from "@mui/material";
import PlatformSpace from "../../../components/platform/platformSpace";
import {ORGANIZATION} from "../../../components/constants/organization";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";

export default function PrivacyPolicyHome(props) {

    return (
        <PlatformMarginBox>
            <Container sx={{margin: 1}}>
                <Typography variant="h2" gutterBottom>
                    Privacy Policy
                </Typography>

                {/* Section 1 - Information Handling */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 1 - Information Handling
                    </Typography>
                    <Typography paragraph>
                        Greenbelt Botanicals is committed to complying with federal and state laws for age verification. To ensure users are of legal age, we use a third-party system for age verification, which may require photo-ID collection. Once age is verified, this data is stored by the third-party for up to 30 days before being destroyed. We will never share or sell your information with any third party.  Your name, email, phone and address will be used solely for order-related communication and shipments.  We do not store credit card information, which is ran securely to prevent interception by third parties.  We use Google Site Stats for tracking sales made through our Google ads. Though none of your personal information is being used or recorded in any way, you may disable this cookie if you wish.
                    </Typography>
                    <Typography paragraph>
                        Your computer’s IP address is automatically received when you visit our store, aiding us in gathering data about your browser and operating system.
                    </Typography>
                </Box>

                {/* Section 2 - Consent */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 2 - Consent
                    </Typography>
                    <Typography paragraph>
                        Your consent is obtained when you provide personal information for a transaction, order placement, delivery, or return. This consent applies only to the purpose for which the information is provided. For secondary purposes like marketing, we will ask for your explicit consent or provide an option to decline. You can withdraw your consent anytime, except for information necessary for age verification compliance, by contacting us at <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link>.
                    </Typography>
                </Box>

                {/* Section 3 - Disclosure */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 3 - Disclosure
                    </Typography>
                    <Typography paragraph>
                        Your personal information may be disclosed if required by law or if you breach our Terms of Service.
                    </Typography>
                </Box>

                {/* Section 4 - Third-Party Services */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 4 - Third-Party Services
                    </Typography>
                    <Typography paragraph>
                        Our third-party service providers, including age verification processors and payment gateways, use your information to perform services for us. Their privacy policies govern their handling of your information. Be aware that different jurisdictions may apply different laws to your information, especially in international transactions.
                    </Typography>
                    <Typography paragraph>
                        Leaving our website or being redirected to a third-party site removes the protection of our Privacy Policy and Terms of Service. Links on our site may lead you to other websites, whose privacy practices we advise you to read.
                    </Typography>
                </Box>

                {/* Section 5 - Security */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 5 - Security
                    </Typography>
                    <Typography paragraph>
                        We take precautions to protect your personal information but note that no electronic storage method is 100% secure. Credit card information is encrypted with SSL and AES-256 encryption, adhering to PCI-DSS requirements and standard industry practices.
                    </Typography>
                </Box>

                {/* Section 6 - Cookies */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 6 - Cookies
                    </Typography>
                    <Typography paragraph>
                        We use various cookies on our site, such as '_session_id', '_shopify_visit', '_shopify_uniq', 'cart', '_secure_session_id', and 'storefront_digest'. These cookies help us with session management, tracking visits, customer visit counts, cart contents, and access management. Some are session-based, while others persist longer. Google sets the 'PREF' cookie for tracking purposes.
                    </Typography>
                </Box>

                {/* Section 7 - Age of Consent */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 7 - Age of Consent
                    </Typography>
                    <Typography paragraph>
                        By using our site, you represent that you are of legal smoking age.
                    </Typography>
                </Box>

                {/* Section 8 - Changes to This Privacy Policy */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 8 - Changes to This Privacy Policy
                    </Typography>
                    <Typography paragraph>
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with another company, your information may be transferred to the new owners.
                    </Typography>
                </Box>

                {/* Section 9 - Text Marketing and Notifications */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 9 - Text Marketing and Notifications
                    </Typography>
                    <Typography paragraph>
                        By entering your phone number in the checkout and initiating a purchase, subscribing via our subscription form or a keyword, you agree that we may send you text notifications (for your order, including abandoned cart reminders) and text marketing offers. Text marketing messages will not exceed 15 a month. You acknowledge that consent is not a condition for any purchase. If you wish to unsubscribe from receiving text marketing messages and notifications reply with STOP to any mobile message sent from us or use the unsubscribe link we provided you with in any of our messages. You understand and agree that alternative methods of opting out, such as using alternative words or requests will not be accounted as a reasonable means of opting out. Message and data rates may apply. For any questions please text HELP to the number you received the messages from. You can also contact us for more information. If you wish to opt out please follow the procedures above.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Section 10 - Shipping Policy
                    </Typography>
                    <Typography paragraph>
                        Most items are in stock and ready to ship.  If you order an item that is not in stock, we will notify you with an ETA, at which time you will have the option to cancel your order.
                    </Typography>
                </Box>

                {/* Questions and Contact Information */}
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Questions and Contact Information
                    </Typography>
                    <Typography paragraph>
                        If you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link>.
                    </Typography>
                </Box>
            </Container>
            <PlatformSpace height={80}/>
        </PlatformMarginBox>
    )
}