import React, {useState} from 'react';
import {Container, Stack, Typography} from '@mui/material';
import {isMobile} from "react-device-detect";
import PlatformSliderButtons from "../../components/platform/platformSliderButtons";
import BestSellerVariantIndex from "../catalog/bestSeller/bestSellerVariantIndex";
import PlatformSpace from "../../components/platform/platformSpace";

export default function HomeBestSellers() {
    const [categoryHandle, setCategoryHandle] = useState("GUMMIES");

    const onChange = (tab, idx) => {
        setCategoryHandle(tab?.handle);
    }

    const tabs = [
        { label: "Gummies", handle: "GUMMIES" },
        { label: "Vapes", handle: "VAPES" },
        { label: "Flower", handle: "FLOWER" },
        { label: "Snowcaps" },
    ];

    const displayedTabs = isMobile ? tabs.slice(0, 2) : tabs;

    return (
      <Container>
          <Stack spacing={0}>
              <Typography variant={isMobile ? "h4" : "h3"} textAlign="center">
                  BEST SELLERS
              </Typography>
              <Typography variant="body1" textAlign="center" my={3}>
                  Ignite your curiosity with this curated selection of customer favorites.
              </Typography>
          </Stack>
          <PlatformSliderButtons
            tabsWidth={540}
            tabWidth={30}
            changeHandler={onChange}
            tabs={displayedTabs}
          />
          {isMobile && <PlatformSpace height="10"/>}
          <BestSellerVariantIndex headless={true} categoryHandle={categoryHandle} />
      </Container>
    );
}
