export default function formatAddress(addressArray) {
    if (!Array.isArray(addressArray)) {
        return '';
    }

    const addressComponents = {
        street1: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    };

    addressArray.forEach(item => {
        if (addressComponents.hasOwnProperty(item.name)) {
            addressComponents[item.name] = item.value;
        }
    });

    return `${addressComponents.street1}, ${addressComponents.city}, ${addressComponents.state} ${addressComponents.zip}, ${addressComponents.country}`;
}

export function formatPhoneNumber(phoneNumber) {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Remove leading '1' if exists
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
        cleaned = cleaned.substring(1);
    }

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
}

export function formatAddressForMaps(addressObj) {
    const { street1, street2, street3, state, city, zip, country } = addressObj;

    let singleLineAddress = `${street1}`;

    if (street2) {
        singleLineAddress += `, ${street2}`;
    }

    if (street3) {
        singleLineAddress += `, ${street3}`;
    }

    singleLineAddress += `, ${city}, ${state}`;

    return singleLineAddress;
}

export function getFullStateName(abbreviation) {
    const states = {
        "AL": "Alabama",
        "AK": "Alaska",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FM": "Federated States Of Micronesia",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MH": "Marshall Islands",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PW": "Palau",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    };

    return states[abbreviation] || "Unknown";
}
