import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import PublicContext from "../../components/layout/publicContext";
import CartApi from "./cartApi";
import {clearCart, saveCart} from "../../components/util/cart";
import {useHistory} from "react-router-dom";

export const CartContext = React.createContext({});
export default CartContext;

export const CartContextProvider = ({ children }) => {
    const { setShowCart } = useContext(PublicContext);
    const [cart, setCart] = useState({});
    const [cartError, setCartError] = useState("");
    const [loading, setLoading] = useState(true);
    const [shippingAmount, setShippingAmount] = useState(null);
    const history = useHistory();

    useEffect(() => {
        initCart();
    }, []);

    // Disabled after sales expire cart bug found
    // useEffect(() => {
    //     console.log('cart provider cart')
    //     saveCart(cart);
    // }, [cart]);

    const initCart = () => {
        CartApi.getCart((fetchedCart) => {
            const sanitizedCart = sanitizeCartItems(fetchedCart?.cart);
            setCart(sanitizedCart);
            setLoading(false);
        });
    };

    const sanitizeCartItems = (cart) => {
        if (cart && Array.isArray(cart.cartItems)) {
            // Filter out null items
            cart.cartItems = cart.cartItems.filter(item => item !== null);
        }
        return cart;
    };

    const checkCartIsEmpty = (cartToCheck) => {
        if (cartToCheck && (!cartToCheck.cartItems || cartToCheck.cartItems.length === 0)) {
            history.push("/checkout/empty");
        }
    };

    const deleteCartItem = (cartItem) => {
        CartApi.removeCartItem({ cartItems: [cartItem] }, (responseCart) => {
            const sanitizedCart = sanitizeCartItems(responseCart.cart);
            setCart(sanitizedCart);
            saveCart(sanitizedCart);
            setShippingAmount(sanitizedCart.shippingAmount);
        });
    };

    const checkout = () => {
        CartApi.getCheckout((order) => {
            clearCart();
            history.push({
                pathname: "/checkout/receipt",
                order: order
            });
        }, (error) => {
            setCartError(error.data.message);
        });
    };

    const emptyCart = () => {
        CartApi.emptyCart(() => {
            clearCart();
            setShippingAmount(null);
            setCart({});
        });
    };

    const updateCart = (cartUpdate, callback) => {
        //console.log('update cart called for now reason')
        CartApi.updateCart(cartUpdate, (responseCart) => {
            const sanitizedCart = sanitizeCartItems(responseCart.cart);
            setCart(sanitizedCart);
            saveCart(sanitizedCart);
            setShippingAmount(sanitizedCart.shippingAmount);
            if (typeof callback === 'function') {
                callback(true, sanitizedCart, responseCart.checksum, responseCart.errors);
            }
        }, (data) => {
            if (typeof callback === 'function') {
                callback(false, data);
            }
        });
    };

    const addToCart = (item, callback) => {
        CartApi.addCartItem({ productId: item.productId, cartItems: [item] }, (responseCart) => {
            const sanitizedCart = sanitizeCartItems(responseCart.cart);
            setCart(sanitizedCart);
            saveCart(sanitizedCart);
            setShowCart(true);
            setShippingAmount(sanitizedCart.shippingAmount);
            callback(true, sanitizedCart);
        }, (data) => {
            callback(false, data);
        });
    };

    const buyItNow = (item, callback) => {
        CartApi.addCartItem({ productId: item.productId, cartItems: [item] }, (responseCart) => {
            const sanitizedCart = sanitizeCartItems(responseCart.cart);
            setCart(sanitizedCart);
            saveCart(sanitizedCart);
            history.push("/checkout");
        }, (data) => {
            callback(false, data);
        });
    };

    return (
        <CartContext.Provider
            value={{
                deleteCartItem,
                addToCart,
                buyItNow,
                emptyCart,
                updateCart,
                shippingAmount,
                loading,
                cart,
                cartError
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
