import React from "react";
import StateMapUSA from "./stateMapUSA";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/styles";
import PlatformSpace from "../../../../components/platform/platformSpace";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import PlatformMarginBox from "../../../../components/platform/platformMarginBox";
import {isMobile} from "react-device-detect";

export default function StateMapIndex(props) {
    return (
        <PlatformMarginBox>
            <Grid container>
                <Grid item xs={12} align="center">
                    <Typography variant="body1" gutterBottom sx={{fontSize: "1.8rem"}}>
                        WHERE WE SHIP
                    </Typography>
                    <PlatformSpace height={isMobile ? 30 : 50}/>
                </Grid>
                <Grid item xs={12} md={7}>
                    <StateMapUSA/>
                </Grid>
                <Grid item xs={12} md={5}>
                    <PaperContainer elevation={0}>
                        <Typography variant="body1">
                            Shipping Limitations
                        </Typography>
                        <Typography variant="body4">
                            High Potency THCa products can't be shipped to: Oregon, Arkansas, Minnesota, Rhode Island, Idaho
                        </Typography>
                    </PaperContainer>
                    <PlatformSpace height={15}/>
                    <PaperContainer elevation={0}>
                        <Typography variant="body1">
                            What is Hemp?
                        </Typography>
                        <Typography variant="body4">
                            Hemp is cannabis with a Delta-9 THC concentration ≤ 0.3% by dry weight.
                        </Typography>
                        <PlatformSpace height={15}/>
                        <Typography variant="body1">
                            Federal Law
                        </Typography>
                        <Typography variant="body4">
                            Consumable hemp products are federally legal and permitted to ship over state lines.
                        </Typography>
                        <PlatformSpace height={15}/>
                        <Typography variant="body1">
                            State Law
                        </Typography>
                        <Typography variant="body4">
                            Delta-9 (≤ 0.3%) from hemp is legal in all 50 states, while a few states have banned Delta-8.
                        </Typography>
                    </PaperContainer>
                    {/*<CatalogItemInfoBox*/}
                    {/*    alwaysExpanded*/}
                    {/*    topRow*/}
                    {/*    icon={Subject}*/}
                    {/*    title="Shipping Limitations"*/}
                    {/*    content={*/}
                    {/*        <div style={{padding: "15px 0 10px 10px"}}>*/}
                    {/*            High Potency THCa products can't be shipped to: Oregon, Arkansas, Minnesota, Rhode Island, Idaho*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*/>*/}
                </Grid>
                <Grid item xs={12}>
                    <PlatformSpace height={30}/>
                    <Typography variant="body4">
                        All Greenbelt Botanicals products contain a concentration equal to or less than 0.3% Delta 9 THC on a dry weight basis. Products containing hemp-derived THC at this concentration are federally legal under the 2018 Farm Bill. Check with your local laws before purchasing. Drug tests specifically detect metabolites of Delta-9 THC. You should not use this product if you have concerns regarding passing a drug test. By purchasing any Greenbelt Botanicals product, you assume full responsibility for all parts pertaining to your purchase.
                        <br/><br/>
                        <strong>WARNING: Consult with your physician before use. Do not operate a vehicle or heavy machinery when taking this product. Do not use if pregnant, nursing, or if you have any diagnosed or undiagnosed health conditions. Greenbelt Botanicals is not responsible for the actions of individuals who take this product.</strong>
                    </Typography>
                </Grid>
            </Grid>
        </PlatformMarginBox>
    );
}

const PaperContainer = styled(Paper)(({ theme }) => ({
    padding: 20,
    borderRadius: "12px !important",
    border: "1px solid rgba(59,42,84, 0.3)",
    backgroundColor: "transparent !important"
}));

const MapContainer = styled('div')(({ theme }) => ({
    margin: theme.custom.pageMargins,
}));

const TitleText = styled('div')(({ theme }) => ({
    fontWeight: 800,
    fontSize: "2.8rem",
    lineHeight: "2rem",
    color: theme.custom.primaryTextColor,
}));