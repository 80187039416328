import {makeStyles} from "@mui/styles";
import React, {useContext} from "react";
import styles from "./favoritesStyles";
import {Grid} from "@mui/material";
import FavoritesGridItem from "./favoritesGridItem";
import FavoritesContext from "./favoritesContext";

const useStyles = makeStyles(styles);

export default function FavoritesGrid(props) {
    const classes = useStyles();
    const {favorites} = props;
    const context = useContext(FavoritesContext);

    return (
        <Grid container>
            {favorites.map((favorite, idx) => (
                <FavoritesGridItem key={`${favorite.id}${idx}`} data={favorite}/>
            ))}
        </Grid>
    );
}