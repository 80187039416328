import * as React from 'react';
import {useContext} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import Radio from "@mui/material/Radio";
import CheckoutBillingAddressFormContext from "./checkoutBillingAddressFormContext";

export default function CheckoutBillingAddressContainer(props) {
    const {topRow, bottomRow, header, content, name, scrollBy, ...allProps} = props;
    const {optionType, setOptionType} = useContext(CheckoutBillingAddressFormContext);

    // useEffect(() => {
    //     if ((name === optionType) && scrollBy) {
    //         window.scrollBy({
    //             top: scrollBy,
    //             left: 0,
    //             behavior: "smooth",
    //         });
    //     }
    // }, [optionType])

    const onChange = (event, expanded) => {
        setOptionType(name);
    }

    const accordionProps = {
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: {enter: 0, exit: 0}
    }

    const expanded = (name === optionType);

    let topRadius = null;
    let bottomRadius = null;

    if (topRow) {
        topRadius = "12px 12px 0 0 !important";
    } if (!topRow && !bottomRow) {
        topRadius = "0 !important";
    } else if (bottomRow) {
        bottomRadius = "0 0 12px 12px";
        if (expanded) {
            topRadius = "0 !important";
        } else {
            topRadius = "0 0 12px 12px !important";
        }
    }

    return (
        <Accordion
            {...allProps}
            onChange={onChange}
            expanded={expanded}
            defaultExpanded={expanded}
            TransitionProps={accordionProps}
            sx={{
                "&.Mui-expanded": {
                    margin: 0
                },
            }}>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    borderRadius: topRadius,
                    border: "1px solid rgba(59,42,84, 0.80)",
                    borderTop: "1px solid rgba(59,42,84, 0.80)",
                    height: 65
                }}>
                <Stack justifyContent="flex-start" direction="row" alignItems="center" spacing={0}>
                    <Radio checked={expanded}/>
                    <div>{header}</div>
                </Stack>
            </AccordionSummary>
            {content && <AccordionDetails
                sx={{
                    border: "1px solid rgba(59,42,84, 0.80)",
                    backgroundColor: "rgba(179, 113, 224,.05)",
                    borderRadius: bottomRadius,
                    borderTop: "none",
                    color: "white",
                    fontWeight: "400 !important",
                    fontSize: "1rem !important",
                    padding: "20px 20px"
                }}>
                {content}
            </AccordionDetails>}
        </Accordion>
    )
}