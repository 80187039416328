import React from "react";
import {RelatedVariantContextProvider} from "./relatedVariantContext";
import RelatedVariantHome from "./relatedVariantHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";

export default function RelatedVariantIndex(props) {
    return (
        <PlatformFormContextProvider name="relatedVariantForm">
            <RelatedVariantContextProvider {...props}>
                <RelatedVariantHome/>
            </RelatedVariantContextProvider>
        </PlatformFormContextProvider>
    );
}