import formatToUSD from "./moneyUtil";
import {filterVariantOptions, VARIANT_OPTIONS} from "../constants/variantOptions";

export function filterOptionGroups(products, constraints = {}, groupNames = VARIANT_OPTIONS) {
    const filteredProducts = filterProducts(products, constraints);
    return createOptionGroups(products, filteredProducts, groupNames);
}

export default function createOptionGroups(products, filteredProducts = [], groupNames = VARIANT_OPTIONS) {
    if (!products) {
        console.error("Products are not defined");
        return [];
    }

    const optionGroups = groupNames.reduce((obj, groupName) => {
        obj[groupName] = {};
        return obj;
    }, {});

    const availableOptions = {};

    if (filteredProducts && filteredProducts.length > 0) {
        filteredProducts.forEach(product => {
            if (!product || !product.variants) {
                console.error("Product or product variants are not defined");
                return; // Skip this iteration if product or product.variants is undefined
            }
            product.variants.forEach(variant => {
                variant.variantOptionValues.forEach(optionValue => {
                    const label = optionValue.label;
                    const value = optionValue.value;
                    availableOptions[label] = (availableOptions[label] || {});
                    availableOptions[label][value] = true;
                });
            });
        });
    }

    products.forEach((product) => {
        if (!product || !product.variants) {
            console.error("Product or product variants are not defined");
            return; // Skip this iteration if product or product.variants is undefined
        }

        // Product name
        const productName = product.name;
        const variantCount = product.variants.length;
        const productCount = variantCount > 0 ? variantCount : 1;

        // Sorting the variants by sortOrder
        const sortedVariants = product.variants.sort((a, b) => a.sortOrder - b.sortOrder);

        if (optionGroups.Product) {
            optionGroups.Product[productName] = (optionGroups.Product[productName] || 0) + productCount;
        }
        // Count filter
        if (optionGroups.ProductCount) {
            optionGroups.ProductCount[productCount] = (optionGroups.ProductCount[productCount] || 0) + 1;
        }

        // Flavor, Dosage & Strain
        sortedVariants.forEach((variant) => {
            const sortedVariantOptionValues = variant.variantOptionValues.sort((a, b) => a.sortOrder - b.sortOrder);

            sortedVariantOptionValues.forEach((optionValue) => {
                const label = optionValue.label;

                if (label === 'Flavor' && optionGroups.Flavor) {
                    const flavor = optionValue.value;
                    optionGroups.Flavor[flavor] = (optionGroups.Flavor[flavor] || 0) + 1;
                } else if (label === 'Dosage' && optionGroups.Dosage) {
                    const dosage = optionValue.value;
                    optionGroups.Dosage[dosage] = (optionGroups.Dosage[dosage] || 0) + 1;
                } else if (label === 'Strain' && optionGroups.Strain) {
                    const strain = optionValue.value;
                    optionGroups.Strain[strain] = (optionGroups.Strain[strain] || 0) + 1;
                } else if (label === 'Tier' && optionGroups.Tier) {
                    const tier = optionValue.value;
                    optionGroups.Tier[tier] = (optionGroups.Tier[tier] || 0) + 1;
                } else if (label === 'Count' && optionGroups.Count) {
                    const count = optionValue.value;
                    optionGroups.Count[count] = (optionGroups.Count[count] || 0) + 1;
                } else if (label === 'Cannabinoid' && optionGroups.Cannabinoid) {
                    const cannabinoid = optionValue.value;
                    optionGroups.Cannabinoid[cannabinoid] = (optionGroups.Cannabinoid[cannabinoid] || 0) + 1;
                }
            });
        });
    });

    //console.log('optionGroups', optionGroups, 'availableOptions', availableOptions)

    return Object.keys(optionGroups)
        .filter((key) => Object.keys(optionGroups[key]).length > 0)
        .map((key) => {
            return {
                title: key,
                values: Object.entries(optionGroups[key]).map(([name, value]) => ({
                    name: name,
                    //count: value.count || value,
                    //disabled: groupNames.includes(key) && (!availableOptions[key] || !availableOptions[key][name]),
                })),
            };
        });
}

export function mergeOptionGroups(a, b) {
    a.forEach((optionA, index) => {
        const optionB = b[index];
        if (optionA.title === optionB.title) {
            optionA.values.forEach((valueA, valueIndex) => {
                const valueB = optionB.values[valueIndex];
                valueA.available = valueA.name === valueB.name && !valueB.disabled;
            });
        }
    });
    return a;
}

export function getVariantsByFlavor(product, flavor) {
    return product.variants.filter(variant => {
        return variant.variantOptionValues.some(option => {
            return option.label === "Flavor" && option.value === flavor;
        });
    });
}

export function getFlavorOfVariant(variant) {
    const flavorOption = variant.variantOptionValues.find(option => option.label === "Flavor");
    return flavorOption ? flavorOption.value : null;
}

export function getCountOfVariant(variant) {
    const countOption = variant.variantOptionValues.find(option => option.label === "Count");
    return countOption ? countOption.value : null;
}

export function getVariantByConstraints(product, constraints) {
    return product.variants.find(variant => {
        return Object.keys(constraints).every(constraintKey => {
            return variant.variantOptionValues.some(option => {
                return option.label === constraintKey && option.value === constraints[constraintKey];
            });
        });
    });
}

export function getVariantBySlug(products, slug) {
    if (!products || products.length === 0) {
        return null;
    }

    for (const product of products) {
        if (!product.variants || product.variants.length === 0) {
            continue;
        }

        const variant = product.variants.find(variant => variant.slug === slug);
        if (variant) {
            return variant;
        }
    }

    return null;
}

export const deriveConstraintsFromVariant = (variant) => {
    const defaultConstraints = {};
    if (variant && variant.variantOptionValues) {
        variant.variantOptionValues.forEach(optionValue => {
            const label = optionValue.label;
            defaultConstraints[label] = optionValue.value;
        });
    }
    return defaultConstraints;
};

export const filterConstraints = (products, constraints, title, name) => {
    const updatedConstraints = { ...constraints };
    updatedConstraints[title] = name;
    let perms = getPermutationsOfConstraints(updatedConstraints)
    let labelsToRemove = [];
    perms.forEach((c) => {
        const filterTemp = filterProducts(products, c)
        const optionGroupTemp = createOptionGroups(products, filterTemp, filterVariantOptions('Product'))
        Object.keys(updatedConstraints).forEach(label => {
            const constraintValue = updatedConstraints[label];
            if (constraintValue) {
                let disabled = getDisabledValue(optionGroupTemp, label, constraintValue);
                if (disabled === true) {
                    labelsToRemove.push(label);
                }
                //console.log('label', label, 'value', constraintValue, 'disabled', disabled)
            }
        });
    })
    if (labelsToRemove.length > 0) {
        //console.log('labelsToRemove', labelsToRemove)
        labelsToRemove.forEach((c) => {
            delete updatedConstraints[c];
        })
    }
    updatedConstraints[title] = name;
    return updatedConstraints
}

export function filterProducts(products, constraints) {
    // Check if constraints is an empty object
    if (Object.keys(constraints).length === 0) {
        console.warn("No constraints provided, returning all products");
        return products;
    }

    return products.map(product => {
        const filteredVariants = product.variants.filter(variant => {
            return Object.keys(constraints).every(label => {
                const constraintValue = constraints[label];
                if (constraintValue) {
                    return variant.variantOptionValues.some(optionValue => {
                        return optionValue.value === constraintValue;
                    });
                }
                return false;
            });
        });

        return { ...product, variants: filteredVariants };
    });
}

export function getDisabledValue(optionGroups, title, name) {
    const group = optionGroups.find(group => group.title === title);
    if (group) {
        const value = group.values.find(value => value.name === name);
        if (value) {
            return value.disabled;
        }
    }
    return undefined; // Return undefined if the title or name is not found
}

export function getPermutationsOfConstraints(constraints) {
    const keys = Object.keys(constraints);
    const permutationsSet = new Set();

    // Iterate through the size of permutations
    for (let size = 1; size <= keys.length; size++) {
        const combinations = getCombinations(keys, size);

        // Iterate through combinations to create permutations
        combinations.forEach(combination => {
            const permutation = {};
            combination.forEach(key => {
                permutation[key] = constraints[key];
            });

            if (!isEmptyObject(permutation)) {
                // Convert the permutation object to a string and add it to the Set
                permutationsSet.add(JSON.stringify(permutation));
            }
        });
    }

    return [...permutationsSet].map(item => JSON.parse(item));
}

export function findVariantsWithOneNonMatchingConstraint(product, constraints) {
    if (!product || !product.variants) {
        console.error("Product or product variants are not defined");
        return; // Skip this iteration if product or product.variants is undefined
    }

    const permutations = getPermutationsOfConstraints(constraints);
    const variants = product.variants;
    const matchingVariants = [];

    variants.forEach((variant) => {
        let matchingCount = 0;
        let nonMatchingCount = 0;

        variant.variantOptionValues.forEach((option) => {
            const label = option.label;
            const value = option.value;
            permutations.forEach((permutation) => {
                if (permutation[label] === value) {
                    matchingCount++;
                }
            });
        });

        nonMatchingCount = Object.keys(constraints).length - matchingCount;
        if (nonMatchingCount <= 1) {
            matchingVariants.push(variant);
        }
    });

    return matchingVariants;
}

function getCombinations(arr, size) {
    if (size === 1) return arr.map(key => [key]);

    const result = [];
    const f = (active, rest, a) => {
        if (active.length === size) {
            result.push([...a, ...active]);
            return;
        }
        if (!active.length && !rest.length) return;
        if (!rest.length) {
            result.push([...a]);
            return;
        }
        if (!active.length) {
            f([rest.shift()], rest.slice(), a);
        } else {
            f([...active, rest.shift()], rest.slice(), a.concat(active));
            f(active.slice(), rest.slice(), a);
        }
    };
    f([], arr, []);
    return result;
}

function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// export function applyProductFilter(products, categoryNames, valueDataConstraints) {
//     return products
//         .filter(product => categoryNames.includes(product.categoryName))
//         .map(product => {
//             product.variants = product.variants.filter(variant => {
//                 return Object.keys(valueDataConstraints).every(label => {
//                     return variant.variantOptionValues.some(optionValue => {
//                         const valueDataKey = Object.keys(optionValue.valueData)[0];
//                         return optionValue.label === label && optionValue.valueData[valueDataKey] === valueDataConstraints[label];
//                     });
//                 });
//             });
//             return product;
//         })
//         .filter(product => product.variants.length > 0);
// }

export const reduceVariants = (products, considerDosage = true) => {
    products.forEach(product => {
        const uniqueVariants = [];
        const seenCombinations = new Set();

        product.variants.forEach(variant => {
            const flavorOption = variant.variantOptionValues.find(option => option.label === "Flavor");
            const flavor = flavorOption ? flavorOption.value : ""; // Check for existence of flavorOption

            let dosage = "";
            if (considerDosage) {
                const dosageOption = variant.variantOptionValues.find(option => option.label === "Dosage");
                dosage = dosageOption ? dosageOption.value : ""; // Check for existence of dosageOption
            }

            const combination = `${flavor}_${dosage}`;
            if (!seenCombinations.has(combination)) {
                seenCombinations.add(combination);

                const prices = product.variants
                    .filter(v => {
                        const vFlavorOption = v.variantOptionValues.find(option => option.label === "Flavor");
                        const vFlavor = vFlavorOption ? vFlavorOption.value : "";
                        const vDosageOption = considerDosage ? v.variantOptionValues.find(option => option.label === "Dosage") : null;
                        const vDosage = vDosageOption ? vDosageOption.value : "";
                        return vFlavor === flavor && vDosage === dosage;
                    })
                    .map(v => v.price);

                const minPrice = Math.min(...prices);
                const maxPrice = Math.max(...prices);

                variant.price = minPrice === maxPrice ? formatToUSD(minPrice) : `${formatToUSD(minPrice)} - ${formatToUSD(maxPrice)}`;

                // Modify the variant name based on the considerDosage flag
                if (!considerDosage) {
                    variant.name = flavor;
                    variant.alwaysShow = true;
                } else if (dosage) {
                    variant.name = `${flavor} ${dosage}`;
                }

                uniqueVariants.push(variant);
            }
        });

        product.variants = uniqueVariants;
    });
};