import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import PlatformFormContext from "./platformFormContext";
import _ from "lodash";

const PlatformForm = forwardRef((props, ref) => {
    let {name, children, onChange, onReady, dirtyHandler, debug} = props;
    const [fields, setFields] = useState([]);
    const [fieldsDirty, setFieldsDirty] = useState(false);
    const [form, setForm] = useState({});
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (Object.keys(form).length > 0) {
            if (typeof onChange === "function") {
                onChange(form);
            }
            onDirty(form);
        }
    }, [form])

    useEffect(() => {
        if (mounted.current === true) {
            if (typeof onReady === "function") {
                setTimeout(() => {
                    onReady();
                }, 200)
            }
        }
    }, [mounted.current])

    const start = () => {
        // setTimeout(() => {
        //     if (fields) {
        //         if (debug) {
        //             console.log(`onFormReady name: ${name} readyCount: ${readyCount} fieldCount: ${fields.length}`)
        //         }
        //         if (fields.length === readyCount) {
        //             onReady();
        //         } else {
        //             start();
        //         }
        //     }
        // }, 50)
    }

    useImperativeHandle(ref, () => ({
        checkReadiness() {
            start();
        },

        resetFields() {
            setFields([]);
        },

        resetForm() {
            setFields([]);
            setForm({});
        },

        onChange(name, value, checked, groupBy) {
            if (debug) {
                console.log('name', name)
                console.log('value', value)
                console.log('groupBy', groupBy)
            }
            if (!groupBy) {
                setForm({ ...form, [name]: value || checked });

                if (Object.keys(form).length > 0) {
                    if (typeof onChange === "function") {
                        onChange(form, fieldsDirty);
                    }
                    onDirty(form);
                }
            } else {
                let collection = form[groupBy.name];
                if (collection) {
                    let field = _.find(collection, (c) => {
                        return c === groupBy.value;
                    })
                    if (field) {
                        field[name] = value;
                        setForm(form);

                        if (Object.keys(form).length > 0) {
                            if (typeof onChange === "function") {
                                onChange(form, fieldsDirty);
                            }
                            onDirty(form);
                        }
                    }
                }
            }
        },

        onBlur(name, event) {
            fields.forEach((field) => {
                if (field.name === name && form[name]) {
                    field.validate();
                }
            });
        },

        setValue(name, value, groupBy) {
            //console.log('name', name)
            //console.log('value', value)

            if (!groupBy) {
                form[name] = value; // TODO why
                setForm({...form, [name]: value});
                for (const fieldKey of Object.keys(fields)) {
                    const field = fields[fieldKey];
                    for (const formKey of Object.keys(form)) {
                        if (name === field.name) {
                            if (typeof field.setValue === "function") {
                                field.setValue(value, true);
                            } else {
                                console.log('Platform component missing setValue', field)
                            }
                        }
                    }
                }
            } else {
                let collection = form[groupBy.name];
                if (collection) {
                    let field = _.find(collection, (c) => {
                        return c === groupBy.value;
                    })
                    if (field) {
                        field[name] = value;
                        setForm(form);

                        if (Object.keys(form).length > 0) {
                            if (typeof onChange === "function") {
                                onChange(form, fieldsDirty);
                            }
                        }
                    }
                }
            }
        },

        getValue(name) {
            if (form[name] === 0) {
                return 0;
            }

            if (form[name] === 0.0) {
                return 0.0;
            }

            if (!form || !form[name]) {
                return null;
            }

            return form[name];
        },

        // Not working
        removeFrom(name, predicate) {
            const collection = this.getValue(name);
            this.setValue(collection, collection.filter(p => predicate(p)));
        },

        setDirty(dirty) {
            setFieldsDirty(dirty);
        },

        setForm(form) {
            for (const fieldKey of Object.keys(fields)) {
                const field = fields[fieldKey];
                for (const formKey of Object.keys(form)) {
                    if (formKey === field.name) {
                        if (typeof field.setValue === "function") {
                            field.setValue(form[formKey]);
                        } else {
                            console.log('Platform component missing setValue', field)
                        }
                    }
                }
            }
            if (debug) {
                console.log('form', form)
            }
            setForm(form);
        },

        getForm() {
            if (debug) {
                console.log('form', form)
            }
            let retVal = _.clone(form);
            for (const fieldKey of Object.keys(fields)) {
                const field = fields[fieldKey];
                if (field.unbound) {
                    retVal[field.name] = null;
                    delete retVal[field.name];
                }
            }
            return retVal;
        },

        validate() {
            let valid = true;
            fields.forEach((field) => {
                let result = field.validate();
                if (!result) {
                    console.log('Failed validation ', field);
                    valid = false;
                }
            });
            return valid;
        }
    }));

    const addField = (fieldName, field) => {
        if (debug) {
            console.log('formName', name)
            console.log('fieldName', fieldName)
        }

        setFields(currentFields => [...currentFields, field]);
    }

    const removeField = (name) => {
        const field = fields.find((field) => field.name === name);
        if (field) {
            setFields(fields.filter(field => field.name !== name));
            setForm({ ...form, [name]: null});
        }
    }

    const onDirty = (form) => {
        let currentForm = _.clone(form);

        for (const fieldKey of Object.keys(fields)) {
            const field = fields[fieldKey];
            if (field.unbound) {
                currentForm[field.name] = null;
                delete currentForm[field.name];
            }
        }

        let saveMode = false;

        for (const field in form) {
            if (Array.isArray(form[field])) {
                if (form[field].length > 0) {
                    //console.log('form[field]', field)
                    saveMode = true;
                }
            } else if (typeof form[field] === "object") {
                if (Object.keys(currentForm).length > 0) {
                    //console.log('form[field]', field)
                    saveMode = true;
                }
            } else {
                if (form[field] !== undefined) {
                    //console.log('form[field]', field)
                    saveMode = true;
                }
            }
        }

        if (typeof dirtyHandler === "function") {
            dirtyHandler(saveMode);
        }
    }

    return (
        <div>
            <PlatformFormContext.Provider value={{
                addField: addField,
                removeField: removeField,
                form: ref
            }}>
                {children}
            </PlatformFormContext.Provider>
        </div>
    );
})

export default PlatformForm