import {useEffect, useState} from 'react';

export default function usePlatformCheckbox({ checked, defaultValue, grouped, name, context, disabled, clickHandler }) {
    const [toggled, setToggled] = useState(checked === undefined ? false : checked);

    useEffect(() => {
        if (!grouped && context && context.addField) {
            context.addField(name, {
                name,
                setDefaultValue,
                validate: onValidate,
                setValue: setFormValue,
                changeHandler,
            });
            if (defaultValue) {
                setDefaultValue(defaultValue);
            }
        }
    }, []);

    useEffect(() => {
        if (disabled && !defaultValue) {
            setToggled(false);
            context.removeField(name);
        }
    }, [disabled]);

    const setFormValue = (value) => {
        setToggled(Boolean(value));
    }

    const setDefaultValue = (defaultValue) => {
        if (!grouped && context != null) {
            context.form.current.setValue(name, defaultValue);
        }
        setToggled(Boolean(defaultValue) || '');
    }

    const onValidate = () => true;

    const changeHandler = (event) => {
        const newToggle = event.target.checked;
        setToggled(newToggle);

        if (typeof clickHandler === "function") {
            clickHandler(newToggle, value, event);
        }

        if (!grouped && context != null) {
            context.form.current.onChange(name, newToggle);
        }
    };

    const onClick = (event) => {
        const newToggle = !toggled;

        setToggled(newToggle);

        if (typeof clickHandler === "function") {
            clickHandler(newToggle, value, event);
        }

        if (!grouped && context != null) {
            context.form.current.onChange(name, newToggle);
        }
    };

    return { toggled, changeHandler, onClick };
}
