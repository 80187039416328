import React, {useContext, useMemo} from "react";
import {Stack, useTheme} from "@mui/material";
import {styled} from "@mui/styles";
import {ShoppingCartOutlined} from "@mui/icons-material";
import PlatformSearchField2 from "../../components/platform/platformSearchField2";
import IconButton from "@mui/material/IconButton";
import {useHistory} from "react-router-dom";
import PublicContext from "./publicContext";
import PublicMegaMenu from "./megaMenu/publicMegaMenu";
import {PublicMegaMenuContextProvider} from "./megaMenu/publicMegaMenuContext";
import PlatformSpace from "../platform/platformSpace";
import PublicHeaderTabs from "./publicHeaderTabs";
import PublicStoreLocator from "./publicStoreLocator";
import PublicMenuButton from "./publicMenuButton";
import PublicLearnMenu from "./publicLearnMenu";
import PublicAnnouncement from "./publicAnnouncement";
import Badge from "@mui/material/Badge";
import CartContext from "../../views/cart/cartContext";
import {calculateTotalCartItems} from "../util/cart";
import PublicShopSimpleMenu from "./publicShopSimpleMenu";

export default function PublicHeaderDesktop(props) {
    const {offset} = props;
    const theme = useTheme();
    const history = useHistory();
    const {cart} = useContext(CartContext);
    const {setShowCart, fetchProducts, onDetail, setShowContact} = useContext(PublicContext);

    const onShowCart = () => {
        setShowCart(true);
        setShowContact(false);
    }

    const onHome = () => {
        history.push("/");
    }

    const totalCartItems = useMemo(() => calculateTotalCartItems(cart), [cart]);

    return (
        <HeaderContainer>
            <Header scrolled={+(offset > 15)}>
                <PublicMegaMenuContextProvider>
                    <PublicAnnouncement/>
                    <Stack
                        direction="row"
                        spacing={{ xs: 2, md: 4 }} // Use spacing 2 on small screens and 4 on medium and up
                        sx={{height: 65, margin: "0 35px"}}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <PlatformSearchField2 placeholder="SEARCH"
                                              width={225}
                                              clickHandler={onDetail}
                                              changeHandler={fetchProducts}
                        />
                        <PublicMegaMenu title="Shop THC" width={130}>
                            <PublicShopSimpleMenu/>
                        </PublicMegaMenu>
                        <PublicMegaMenu title="Learn" width={90}>
                            <PublicLearnMenu/>
                        </PublicMegaMenu>
                        <Logo onClick={onHome} src={`${process.env.PUBLIC_URL}/images/logo_zs_h.png`}/>
                        <PublicMenuButton width={130} href="/about-us">
                            About Us
                        </PublicMenuButton>
                        <PublicMenuButton width={90} href="/contact">
                            Contact
                        </PublicMenuButton>
                        <PublicMegaMenu title="Our Stores" width={150}>
                            <PublicStoreLocator/>
                        </PublicMegaMenu>
                        {/*<IconButton sx={{height: "95%"}}>*/}
                        {/*    <AccountCircleOutlined fontSize="large" />*/}
                        {/*</IconButton>*/}
                        <div style={{width: 50, textAlign: "center"}}>
                            <Badge badgeContent={totalCartItems} color="primary">
                                <IconButton onClick={onShowCart}>
                                    <ShoppingCartOutlined
                                        fontSize="large"
                                        sx={{ color: theme.palette.secondary.main }} // Apply custom color using sx prop
                                    />
                                </IconButton>

                            </Badge>
                        </div>
                    </Stack>
                    <PlatformSpace height={20}/>
                    <PublicHeaderTabs/>
                </PublicMegaMenuContextProvider>
            </Header>
            <PlatformSpace height={120}/>
        </HeaderContainer>
    )
}

const Logo = styled('img')(({ selected }) => ({
    cursor: "pointer",
    height: 35,
    paddingRight: 20
}));

const HeaderContainer = styled("div")(({ theme }) => ({
    padding: theme.custom.pageMargins,
    paddingTop: 40
}));

const Header = styled('div')(({ scrolled, theme }) => ({
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    width: "auto",
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    transition: "background .25s ease-out",
    zIndex: 5000,
    //paddingTop: 15,
    paddingBottom: 15,
    ...(scrolled && {
        //backgroundColor: "rgb(158,243,158)",
        //backgroundColor: "#f7f6f2"
        backgroundColor: "white",
        //backgroundColor: "rgb(142,243,142)", // minty and nice best so far
        //backgroundColor: "rgb(125,245,125)", really nice electric green
    }),
}));