import React, {useContext} from "react";
import {Drawer} from "@mui/material";
import {styled, ThemeProvider} from "@mui/styles";
import PublicContext from "./publicContext";
import {createTheme} from "@mui/material/styles";
import PlatformSpace from "../platform/platformSpace";
import PlatformSearchField2 from "../platform/platformSearchField2";
import useScrollToTop from "../hook/useScrollToTop";
import {useLockBodyScroll} from "../hook/useLockBodyScroll";

export default function PublicSearchMobile(props) {
    const {mobileSearchOpen, setMobileSearchOpen, fetchProducts, onDetail, setBlurBackground} = useContext(PublicContext);

    useLockBodyScroll(mobileSearchOpen);
    useScrollToTop(mobileSearchOpen);

    const onMenuClose = () => {
        setMobileSearchOpen(false);
    }

    const customDrawerTheme = createTheme({
        transitions: {
            duration: {
                enteringScreen: 300, // Faster transition when entering
                leavingScreen: 100,  // Keep the original transition when leaving or adjust as necessary
            }
        }
    });

    return (
        <ThemeProvider theme={customDrawerTheme}>
            <FastTransitionDrawer
                anchor="top"
                open={mobileSearchOpen}
                onClose={onMenuClose}
            >
                <FilterContainer>
                    <PlatformSpace height={25}/>
                    <PlatformSearchField2 placeholder="Search products, cannabinoids, and flavors"
                                          fullWidth
                                          clickHandler={onDetail}
                                          changeHandler={fetchProducts}
                                          autoFocus={true}
                                          height="100vh"
                    />
                    <PlatformSpace height={30}/>
                </FilterContainer>
            </FastTransitionDrawer>
        </ThemeProvider>
    );
}

const FilterContainer = styled('div')(({ fullWidth }) => ({
    overflowY: "scroll"
}));

const FastTransitionDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        paddingTop: 10,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for entering
        }),
    },
    '& .MuiDrawer-paperAnchorLeft:not(.MuiDrawer-paperAnchorDockedLeft)': {
        transform: 'translateX(-100%)',
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
        borderRight: 'none',
        transition: theme.transitions.create('border', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for leaving
        }),
    },
}));