import React from "react";
import {Box, Container} from "@mui/material";
import {isMobile} from "react-device-detect";

export default function PlatformMarginBox({maxWidth = "lg", children}) {

    return (
        <Container maxWidth={maxWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 2 : 0,
                    gap: 10,
                }}
            >
                {children}
            </Box>
        </Container>
    );
}