import React from 'react';
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";
import AddressRecommendationHome from "./addressRecommendationHome";

export default function AddressRecommendationIndex(props) {

    return (
      <PlatformFormContextProvider name="addressRecommendationForm">
          <AddressRecommendationHome {...props}/>
      </PlatformFormContextProvider>
    );
}