import React, {useContext, useEffect} from 'react';
import {styled, ThemeProvider} from '@mui/styles';
import CatalogContext from "./catalogContext";
import PlatformSpace from "../../components/platform/platformSpace";
import Toolbar from "@mui/material/Toolbar";
import {Drawer} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import CatalogFilterDesktop from "./catalogFilterDesktop";
import useScrollToTop from "../../components/hook/useScrollToTop";
import Button from "@mui/material/Button";

export default function CatalogFilterMobile(props) {
    const {mobileFilterOpen, setMobileFilterOpen} = useContext(CatalogContext);

    useEffect(() => {
        if (mobileFilterOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => { // cleanup function to reset when component unmounts
            document.body.style.overflow = 'auto';
        };
    }, [mobileFilterOpen]);

    useScrollToTop(mobileFilterOpen);

    const onMenuClose = () => {
        setMobileFilterOpen(false);
    }

    const customDrawerTheme = createTheme({
        transitions: {
            duration: {
                enteringScreen: 300, // Faster transition when entering
                leavingScreen: 100,  // Keep the original transition when leaving or adjust as necessary
            }
        }
    });

    return (
        <ThemeProvider theme={customDrawerTheme}>
            <FastTransitionDrawer
                anchor="bottom"
                open={mobileFilterOpen}
                onClose={onMenuClose}
            >
                <Toolbar>
                    <SaveButton variant="contained" onClick={onMenuClose}>
                        Save & close
                    </SaveButton>
                </Toolbar>
                <FilterContainer>
                    <CatalogFilterDesktop handleClose={onMenuClose}/>
                    <PlatformSpace height={15}/>
                </FilterContainer>
            </FastTransitionDrawer>
        </ThemeProvider>
    );
}

const FilterContainer = styled('div')(({ fullWidth }) => ({
    overflowY: "scroll"
}));

const SaveButton = styled(Button)(({ theme }) => ({
    height: 35,
    width: "45vw !important",
    backgroundImage: "none !important",
    color: "rgba(0, 0, 0, 0.8) !important",
    border: "2px solid rgba(0, 0, 0, 0.8) !important", // Adds a blue outline
    backgroundColor: "rgba(0, 0, 0, 0.075) !important" // Removes the colored background
}));

const FastTransitionDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        paddingTop: 10,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for entering
        }),
    },
    '& .MuiDrawer-paperAnchorLeft:not(.MuiDrawer-paperAnchorDockedLeft)': {
        transform: 'translateX(-100%)',
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
        borderRight: 'none',
        transition: theme.transitions.create('border', {
            easing: theme.transitions.easing.sharp,
            duration: "100ms !important",  // Use the theme's duration for leaving
        }),
    },
}));