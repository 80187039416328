import * as React from 'react';
import {useContext} from 'react';
import {Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import PlatformSpace from "components/platform/platformSpace";
import PlatformTextField from "components/platform/platformTextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import CatalogContext from "./catalogContext";
import {truncateAfterNCharacters} from "../../components/util/stringUtil";

export default function CatalogCheckboxGroupFilter({disableSearch, category}) {
    const {filters, toggleFilter, filterOptionsAll, filterOptionsSelected} = useContext(CatalogContext);

    const handleToggle = (value) => {
        toggleFilter(category, value);
    };

    const values = Object.values(filterOptionsAll[category]);

    return (
        <React.Fragment>
            {!disableSearch &&
                <React.Fragment>
                    <PlatformSpace height={10}/>
                    <PlatformTextField
                        startAdornment={<SearchIcon/>}
                        placeholder="Search"
                    />
                </React.Fragment>
            }
            <Stack sx={{display: 'flex', flexDirection: 'column', ml: 0}}>
                {values.map((value, idx) => {
                    const isChecked = filters[category]?.includes(value) || false;
                    const isAvailable = filterOptionsSelected[category]?.includes(value) || false;
                    // if (!isAvailable && !isChecked) {
                    //     return <React.Fragment/>
                    // }
                    return (
                        <FormControlLabel
                            key={idx}
                            disabled={!isAvailable && !isChecked}
                            label={
                                <Typography variant="body4">
                                    {truncateAfterNCharacters(value.toUpperCase(), 20)}
                                </Typography>
                            }
                            sx={{
                                maxWidth: 175,
                                marginRight: 0
                            }}
                            control={<CheckboxControl checked={isChecked} onChange={() => handleToggle(value)} />}
                        />

                    );
                })}
            </Stack>
        </React.Fragment>
    )
}

const CheckboxControl = styled(Checkbox)(({ theme }) => ({
    color: "white !important", // Sets the color to white
    '&.Mui-checked': {
        color: "green !important", // Change the color when checked
    },
    'svg': {
        fill: "red !important", // Change the fill color of any SVG elements within the checkbox
    },
    '&.MuiCheckbox-root': {
        padding: '3px', // Add padding to the root element
    },
}));