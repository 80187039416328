import {isBrowser} from "react-device-detect";

const platformStyles = {
    platformLink: {
        fontSize: 16,
        height: 40,
        fontWeight: 500,
        textTransform: "none",
        borderRadius: 10
    },
    platformBox: {
        textAlign: "center"
    },
    platformSpinnerButton: {
        position: "absolute",
        width: 20,
        opacity: 0.6,
        cursor: "pointer"
    },
    platformButtonCapsule: {
        borderRadius: 5,
        width: isBrowser ? 500 : "90vw",
        height: 75,
        display: "table",
        marginBottom: 15,
        cursor: "pointer"
    },
    platformButtonCapsuleLabel: {
        fontSize: 17,
        fontWeight: 700,
        marginBottom: 10
    },
    platformButtonCapsuleSelected: {
        border: "2px solid lightGreen"
    },
    platformButtonCapsuleDeselected: {
        border: "1px solid rgba(0, 0, 0, .3)",
        marginLeft: 1
    },
    platformButtonCapsuleHover: {
        border: "1px solid black",
        marginLeft: 1
    },
    platformButtonCapsuleItem: {
        display: "table-cell",
        verticalAlign: "middle",
        padding: 15
    },
    platformButtonCapsuleItemText: {
        fontWeight: 500,
        fontSize: 17
    },
    platformButtonCapsuleItemAdornment: {
        textAlign: "right",
        fontSize: 17
    },
    platformInput: {
        width: 500,
        borderRadius: 5
    },
    platformInputFullWidth: {
        width: "100%",
    },
    platformSelect: {
        flexGrow: 1,
        width: 500,
        //width: "100%",
    },
    platformFormControl: {
        width: "100%"
    },
    platformFormLabel: {
        margin: "10px 0 10px 0",
        fontFamily: "Poppins !important",
        fontSize: "1.1rem !important",
        color: "rgb(112, 122, 131) !important",
        fontWeight: "600 !important",
        opacity: 1,
        flexGrow: 1,
        letterSpacing: "0 !important"
    },
    platformFormContainer: {
        position: "relative"
        //margin: "20px 0 30px 0"
    },
    platformListItemRoot: {
        marginTop: 0,
        marginBottom: 0
    },
    platformListItemPrimary: {
        fontSize: 14,
        color: "rgb(51, 51, 51)"
    },
    platformImage: {
        opacity: 0,
    },
    platformImageLoaded: {
        opacity: 1
    }
};

export default platformStyles;