import React from "react";
import {Stack} from "@mui/material";
import {styled} from "@mui/styles";
import {useHistory} from "react-router-dom";
import PlatformButton from "../../../components/platform/platformButton";
import {isMobile} from "react-device-detect";

export default function OrderStatusControls(props) {
    const history = useHistory();

    const onContinueShopping = () => {
        history.push("/");
    }

    const onContactUs = () => {
        history.push("/contact");
    }

    return (
        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{width: "100%"}}>
            <Stack justifyContent="flex-start" direction={isMobile ? "column" : "row"} alignItems="center" spacing={1}>
                <CartLink onClick={null}>
                    <div>Need Help?</div>
                </CartLink>
                <ContactText onClick={onContactUs}>
                    Contact us
                </ContactText>
            </Stack>
            <PlatformButton sx={{width: isMobile ? "65%" : "50%"}} size="lg" onClick={onContinueShopping}>
                Continue shopping
            </PlatformButton>
        </Stack>
    );
}

const CartLink = styled("div")(({ theme }) => ({
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    opacity: 0.7
}));

const ContactText = styled("div")(({ theme }) => ({
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    cursor: "pointer"
}));