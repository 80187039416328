import * as React from 'react';
import {useContext} from 'react';
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import PlatformSelect from "../../components/platform/platformSelect";
import {Stack} from "@mui/material";
import {isMobile} from "react-device-detect";
import CheckoutBillingAddressFormContext from "./checkoutBillingAddressFormContext";

export default function CheckoutBillingAddressForm({ validateOn, groupBy, nested }) {
    const { optionType } = useContext(CheckoutBillingAddressFormContext);
    const isUnbound = validateOn && optionType !== validateOn ? "true" : "false";

    return (
        <React.Fragment>
            <Stack justifyContent="space-between" direction={isMobile ? "column" : "row"} alignItems="center" spacing={2}>
                <PlatformTextField
                    name="firstNameBilling"
                    placeholder="First name"
                    errorText="First name is required"
                    groupBy={groupBy}
                    unbound={isUnbound}
                    required
                    fullWidth
                    autoComplete="given-name"
                />
                <PlatformTextField
                    name="lastNameBilling"
                    placeholder="Last name"
                    errorText="Last name is required"
                    groupBy={groupBy}
                    unbound={isUnbound}
                    required
                    fullWidth
                    autoComplete="family-name"
                />
            </Stack>
            <PlatformSpace height={15} />
            <PlatformTextField
                name="street1Billing"
                placeholder="Address"
                errorText="Street address is required"
                groupBy={groupBy}
                unbound={isUnbound}
                required
                fullWidth
                autoComplete="address-line1"
            />
            <PlatformSpace height={15} />
            <PlatformTextField
                name="street2Billing"
                placeholder="Apartment, suite, etc. (optional)"
                groupBy={groupBy}
                unbound={isUnbound}
                fullWidth
                autoComplete="address-line2"
            />
            <PlatformSpace height={15} />
            <Stack justifyContent="space-between" direction={isMobile ? "column" : "row"} alignItems="center" spacing={2}>
                <PlatformTextField
                    name="cityBilling"
                    placeholder="City"
                    errorText="City is required"
                    groupBy={groupBy}
                    unbound={isUnbound}
                    required
                    fullWidth
                    autoComplete="address-level2"
                />
                <PlatformSelect
                    name="stateBilling"
                    autoComplete="address-level1"
                    errorText="State is required"
                    groupBy={groupBy}
                    required
                    unbound={isUnbound}
                    width={isMobile ? (nested ? "78vw !important" : "88vw !important") : "200px !important"}
                    options={[
                        { label: '', value: null },
                        { label: 'Alabama', value: 'AL' },
                        { label: 'Alaska', value: 'AK' },
                        { label: 'Arizona', value: 'AZ' },
                        { label: 'Arkansas', value: 'AR' },
                        { label: 'California', value: 'CA' },
                        { label: 'Colorado', value: 'CO' },
                        { label: 'Connecticut', value: 'CT' },
                        { label: 'Delaware', value: 'DE' },
                        { label: 'Florida', value: 'FL' },
                        { label: 'Georgia', value: 'GA' },
                        { label: 'Hawaii', value: 'HI' },
                        { label: 'Idaho', value: 'ID' },
                        { label: 'Illinois', value: 'IL' },
                        { label: 'Indiana', value: 'IN' },
                        { label: 'Iowa', value: 'IA' },
                        { label: 'Kansas', value: 'KS' },
                        { label: 'Kentucky', value: 'KY' },
                        { label: 'Louisiana', value: 'LA' },
                        { label: 'Maine', value: 'ME' },
                        { label: 'Maryland', value: 'MD' },
                        { label: 'Massachusetts', value: 'MA' },
                        { label: 'Michigan', value: 'MI' },
                        { label: 'Minnesota', value: 'MN' },
                        { label: 'Mississippi', value: 'MS' },
                        { label: 'Missouri', value: 'MO' },
                        { label: 'Montana', value: 'MT' },
                        { label: 'Nebraska', value: 'NE' },
                        { label: 'Nevada', value: 'NV' },
                        { label: 'New Hampshire', value: 'NH' },
                        { label: 'New Jersey', value: 'NJ' },
                        { label: 'New Mexico', value: 'NM' },
                        { label: 'New York', value: 'NY' },
                        { label: 'North Carolina', value: 'NC' },
                        { label: 'North Dakota', value: 'ND' },
                        { label: 'Ohio', value: 'OH' },
                        { label: 'Oklahoma', value: 'OK' },
                        { label: 'Oregon', value: 'OR' },
                        { label: 'Pennsylvania', value: 'PA' },
                        { label: 'Rhode Island', value: 'RI' },
                        { label: 'South Carolina', value: 'SC' },
                        { label: 'South Dakota', value: 'SD' },
                        { label: 'Tennessee', value: 'TN' },
                        { label: 'Texas', value: 'TX' },
                        { label: 'Utah', value: 'UT' },
                        { label: 'Vermont', value: 'VT' },
                        { label: 'Virginia', value: 'VA' },
                        { label: 'Washington', value: 'WA' },
                        { label: 'West Virginia', value: 'WV' },
                        { label: 'Wisconsin', value: 'WI' },
                        { label: 'Wyoming', value: 'WY' },
                    ]}
                />
                <PlatformTextField
                    name="zipBilling"
                    placeholder="Zip code"
                    errorText="Zip code is required"
                    groupBy={groupBy}
                    unbound={isUnbound}
                    required
                    fullWidth
                    autoComplete="postal-code"
                />
            </Stack>
            <PlatformSpace height={15} />
            <PlatformTextField
                name="phoneBilling"
                placeholder="Mobile phone"
                errorText="Mobile phone is required"
                groupBy={groupBy}
                unbound={isUnbound}
                required
                fullWidth
                autoComplete="tel"
            />
        </React.Fragment>
    )
}
