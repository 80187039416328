import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {CatalogItemContextProvider} from "./catalogItemContext";
import CatalogItemHome from "./catalogItemHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";

export default function CatalogItemIndex(props) {
    return (
        <PublicLayout>
            <PlatformFormContextProvider name="catalogItemForm">
                <CatalogItemContextProvider>
                    <CatalogItemHome/>
                </CatalogItemContextProvider>
            </PlatformFormContextProvider>
        </PublicLayout>
    );
}