import React, {useEffect, useState} from 'react';
import {Box, Modal, Typography} from '@mui/material';
import Button from "@mui/material/Button";
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";
import useScrollBlock from "../../../components/hook/useScrollBlock";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformButton from "../../../components/platform/platformButton";

export default function AgeGateHome() {
    const [open, setOpen] = useState(!Boolean(localStorage.getItem("ageGate")));
    const [blockScroll, allowScroll] = useScrollBlock();

    useEffect(() => {
        if (open) {
            blockScroll();  // Block scroll when the modal is open
        } else {
            allowScroll();  // Allow scroll when modal is closed
        }

        // Cleanup: ensure scrolling is allowed when component unmounts or modal closes
        return () => {
            allowScroll();
        };
    }, [open, blockScroll, allowScroll]);

    const handleClose = () => {
        setOpen(false);
        allowScroll(); // Re-enable scrolling when modal closes
        localStorage.setItem("ageGate", "true");
        window.scrollTo(0, 0);
    };

    const handleMinor = () => {
        location.href = "https://www.google.com";
    }

    return (
        <Modal
            sx={{zIndex: 5001}}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
                <Container>
                    <Logo src={`${process.env.PUBLIC_URL}/images/agegate/GB_Logo_Pixelated.png`} alt="Logo" />
                    <PlatformSpace height={90}/>
                    <Text variant={isMobile ? 'h3' : 'h1'}>HOLD UP</Text>
                    <Text variant={isMobile ? 'h3' : 'h1'}>GOTTA BE THIS</Text>
                    <Text variant={isMobile ? 'h3' : 'h1'}>HIGH TO RIDE</Text>
                    <PlatformSpace height={15}/>
                    <SubText variant="body3">
                        YOU MUST BE 21+ TO ENTER
                    </SubText>
                    <PlatformSpace height={15}/>
                    <ButtonContainer>
                        <PlatformButton size={isMobile ? 'sm' : 'md'} onClick={handleClose}>
                            I'M 21+
                        </PlatformButton>
                        <PlatformButton size={isMobile ? 'sm' : 'md'} variant="outlined" onClick={handleMinor}>
                            I'M NOT 21
                        </PlatformButton>
                    </ButtonContainer>
                </Container>
        </Modal>
    );
}

const Container = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/agegate/Pixelated_BG.jpg)`,
    top: 0,
    left: 0,
    p: 4,
}));

const Logo = styled('img')(({ theme }) => ({
    width: 200,
    [theme.breakpoints.up('md')]: {
        width: 300,
    },
}));

const Text = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.primary.main, // Purple color from the reference
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
        fontSize: '5rem', // Larger size for bigger screens
    },
}));

const SubText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '1rem',
    fontWeight: "bold !important",
    color: `${theme.palette.secondary.main} !important`
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: '20px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    '&:hover': {
        backgroundColor: '#5B21B6',
    },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
     color: theme.palette.primary.main,
    borderColor: '#6D28D9',
    borderRadius: '50px',
    padding: '12px 24px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#F3E8FF',
    },
}));