import * as React from 'react';
import {Box, Container, Link, Typography} from '@mui/material';
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import {ORGANIZATION} from "../../../components/constants/organization";

export default function RefundPolicyHome(props) {

    return (
        <PlatformMarginBox>
            <Container sx={{margin: 1}}>
                <Typography variant="h2" gutterBottom>
                    Return and Refund Policy
                </Typography>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Policy Summary
                    </Typography>
                    <Typography paragraph>
                        Greenbelt Botanicals offers a 30-day return and refund policy. If it has been over 30 days since your purchase, unfortunately, we are unable to provide a full refund or exchange. Your item must be unused and in its original condition and packaging to qualify for a return. Certain items, especially perishable goods, are not eligible for return. A receipt or proof of purchase is required to process your return. Please refrain from sending your purchase directly back to the manufacturer. In some cases, only partial refunds are issued.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Refunds
                    </Typography>
                    <Typography paragraph>
                        After we receive and inspect your returned item, you will be notified via email regarding the status of your refund. Approved refunds will be processed, and a credit will be automatically applied to your original payment method within a specified number of days. We accept returns in sellable condition in all original packaging within 30 days of purchase for a full refund minus all shipping costs.  All returns must be authorized first by phone or email.
                        Custom-made and special-order items are not returnable.
                        We cannot guarantee that a product's color will match exactly what you see on your computer screen, as all screens are different.  If you are unsure of how a product will look in real life, we encourage you to contact us for more information before placing your order.
                        In the rare event that an item arrives damaged or defective, the customer is responsible for letting us know via phone or email within 24 hours of receipt.  The customer is to save all packaging and shipping materials until notified otherwise.
                        Customers may cancel an order at any point prior to shipment.  If an item has already been processed for shipment, cancellation is no longer an option.  Unfortunately, we are unable to cancel custom-made and special-order items.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Delayed or Missing Refunds
                    </Typography>
                    <Typography paragraph>
                        If you have yet to receive a refund, recheck your bank account first. Then, reach out to your credit card company; it may take some time for your refund to be officially recorded. Subsequently, contact your bank. Refund processing often requires some time. If after these steps you still have not received your refund, please get in touch with us at <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link>.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Sale Items
                    </Typography>
                    <Typography paragraph>
                        Only items purchased at regular price can be refunded; sale items are not eligible for a refund.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Exchanges
                    </Typography>
                    <Typography paragraph>
                        We replace items only if they are defective or damaged. For exchanges of the same item, please email <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link> for instructions.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Gifts
                    </Typography>
                    <Typography paragraph>
                        If the item was identified as a gift upon purchase and sent directly to you, you will receive a gift credit equal to the value of your return. Upon receipt of the returned item, a gift certificate will be mailed to you. If the item wasn’t identified as a gift, or the purchaser sent it to themselves to give to you later, we will refund the purchaser, and they will be informed about your return.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Returning Products
                    </Typography>
                    <Typography paragraph>
                        For product returns, contact <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link> for mailing instructions. You are responsible for your shipping costs when returning an item. These costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund. The time it takes for your exchanged product to reach you may vary depending on your location. For higher-value items, consider using a trackable shipping service or purchasing shipping insurance. We cannot guarantee the receipt of your returned item.
                    </Typography>
                </Box>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Need Help?
                    </Typography>
                    <Typography paragraph>
                        For inquiries regarding refunds and returns, please reach out to us at <Link href={`mailto:${ORGANIZATION.supportEmail}`}>{ORGANIZATION.supportEmail}</Link>.
                    </Typography>
                </Box>
            </Container>
            <PlatformSpace height={80}/>
        </PlatformMarginBox>
    );
}
