import React, {useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import styles from "./passwordStyles";
import PlatformButton from "components/platform/platformButton";
import PlatformSpace from "components/platform/platformSpace";
import {Box, Container, Grid} from "@mui/material";
import PlatformTextField from "components/platform/platformTextField";
import PlatformForm from "components/platform/platformForm";
import PasswordApi from "./passwordApi";

const useStyles = makeStyles(styles);

export default function PasswordRequestForm() {
    const classes = useStyles();
    const formRef = useRef(null);
    const [formCompleted, setFormCompleted] = useState(false);

    const onResetPassword = () => {
        let isValid = formRef.current.validate();
        if (isValid) {
            PasswordApi.createPasswordReset({
                email: formRef.current.getValue("email")
            }, (response) => {
                setFormCompleted(true);
            })
        }
    }

    return (
        <div style={{height: "100vh"}}>
            <PlatformSpace height={20}/>
            <Grid container>
                <Grid item container justifyContent="center" xs={12}>
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center">
                        <Typography variant="h4" style={{textTransform: "none", color: "black"}}>
                            Reset your password
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <PlatformSpace height={40}/>
            <Container maxWidth="xs" style={{backgroundColor: "white", borderRadius: 5, padding: 20}}>
                <PlatformForm ref={formRef}>
                    {!formCompleted &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PlatformTextField
                                    defaultValue=""
                                    required
                                    email
                                    fullWidth
                                    placeholder="Email"
                                    name="email"
                                    label="Email"
                                    errorText="Email is required"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PlatformButton onClick={onResetPassword} color="inherit" size="lg" fullWidth>Submit</PlatformButton>
                            </Grid>
                        </Grid>}
                    {formCompleted &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    If an account exists for <span style={{fontWeight: "bold"}}>
                                    {formRef.current.getValue("email")}</span>, a confirmation email has been sent.
                                    It will have a link to reset your password.
                                </div>
                            </Grid>
                        </Grid>}
                </PlatformForm>
            </Container>
        </div>
    );
}