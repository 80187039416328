import React, {useEffect, useState} from "react";
import CatalogShopAllApi from "./catalogShopAllApi";

export const CatalogShopAllContext = React.createContext({});
export default CatalogShopAllContext;

export const CatalogShopAllContextProvider = ({ categoryHandle, variantId, children }) => {
    const [variantsWithParent, setVariantsWithParent] = useState([]);

    useEffect(() => {
        // Check if categoryHandle or variantId is null or undefined
        if (!categoryHandle || !variantId) {
            console.log('Missing required parameters: categoryHandle or variantId');
            return;
        }

        CatalogShopAllApi.getRandomCatalogShopAlls(categoryHandle, variantId, (data) => {
            setVariantsWithParent(data);
        }, (error) => {
            console.error('Failed to fetch data:', error);
        });
    }, [categoryHandle, variantId]); // Depend on categoryHandle and variantId

    return (
      <CatalogShopAllContext.Provider value={{
          variantsWithParent,
      }}>
          {children}
      </CatalogShopAllContext.Provider>
    );
}
