import React, {useContext} from 'react';
import {Box, Link, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import PlatformSpace from "../../../components/platform/platformSpace";
import HomeFAQ from "../homeFAQ";
import {styled} from "@mui/styles";
import BlogWithFullMoodBanner from "../../../components/shared/blog/blogWithFullMoodBanner";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import {ORGANIZATION} from "../../../components/constants/organization";
import PublicContext from "../../../components/layout/publicContext";

const StyledIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '64px',
    minHeight: '64px',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
    marginRight: '1rem',
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

export default function ContactHome() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {onContact} = useContext(PublicContext);

    return (
      <React.Fragment>
          <BlogWithFullMoodBanner
            title="Our cannabis experts are standing by."
            subtitle="Contact"
            image={`${process.env.PUBLIC_URL}/images/home/AssHat.jpg`}
          />
          <PlatformSpace height={isMobile ? 30 : 15} />
          <PlatformMarginBox>
              <Box flex={1}>
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        padding: '16px',
                    }}
                  >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 0, height: '5vh', flexGrow: 1 }}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/PhoneIcon.jpg`}
                              alt="Call Icon"
                              width="50"
                              height="50"
                          />
                          <Box>
                              <Typography variant="body1">
                                  CALL US TOLL FREE
                              </Typography>
                              <Typography variant="body2">
                                  {ORGANIZATION.supportHours}<br />
                                  {ORGANIZATION.supportPhone}
                              </Typography>
                          </Box>
                      </Box>
                      {isMobile && <PlatformSpace height={15}/>}
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 0, height: '5vh', flexGrow: 1 }}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/EmailIcon.jpg`}
                              alt="Email Icon"
                              width="50"
                              height="50"
                          />
                          <Box>
                              <Typography variant="body1">
                                  EMAIL
                              </Typography>
                              <Typography variant="body2">
                                  <Link href={`mailto:${ORGANIZATION.supportEmail}`} color="inherit" underline="none">
                                      {ORGANIZATION.supportEmail}
                                  </Link>
                              </Typography>
                          </Box>
                      </Box>
                      {isMobile && <PlatformSpace height={15}/>}
                      <Box sx={{ display: 'flex', alignItems: 'center', height: '5vh', flexGrow: 1 }}>
                          <StyledImage
                              src={`${process.env.PUBLIC_URL}/images/itemdetail/ChatIcon.jpg`}
                              alt="Chat Icon"
                              width="55"
                              height="55"
                          />
                          <Box>
                              <Typography variant="body1">
                                  SEND A MESSAGE
                              </Typography>
                              <Typography variant="body2">
                                  <Link href="#" color="inherit" onClick={onContact}>
                                      Click here
                                  </Link>
                              </Typography>
                          </Box>
                      </Box>
                  </Box>
                  <PlatformSpace height={isMobile ? 30 : 15} />
                  <HomeFAQ />
              </Box>
          </PlatformMarginBox>
          <PlatformSpace height={isMobile ? 30 : 60} />
      </React.Fragment>
    );
}

// Define a styled component for the images, bounding them by width or height without distortion
const StyledImage = styled('img')({
    marginRight: '2rem', // Space between image and text
    objectFit: 'contain',
});