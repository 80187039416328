import React, {useContext} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import CatalogItemContext from "./catalogItemContext";
import {styled, useTheme} from "@mui/material/styles";
import {Link, useMediaQuery} from "@mui/material";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import PlatformSpace from "../../../components/platform/platformSpace";

export default function CatalogItemProductFAQ() {
    const {
        variant,
        coas,
        category,
        onDownloadCOA
    } = useContext(CatalogItemContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onDownload = (row) => {
        const pdf = getPdfFromRow(row);
        if (pdf) {
            onDownloadCOA(pdf);
        }
    }

    const getPdfFromRow = (row) => {
        return row.labDocuments && row.labDocuments.length > 0 ? row.labDocuments[0]?.document : null;
    }

    const faqs = [
        {
            question: "Product Details",
            answer: variant.description
        },
        {
            question: "Discreet Delivery",
            answer: "THC can remain in your system for varying periods depending on usage. It is possible for our products containing THC to show up on a drug test."
        },
        {
            question: "100-Day Guarantee",
            answer: "The experience can vary greatly depending on the strain and your personal biochemistry. Generally, it can range from feeling relaxed and euphoric to energized and creative."
        },
    ];

    if (coas && coas.length > 0) {
        faqs.splice(1, 0, {
            question: "Certificate of Analysis",
            answer: "All our products undergo rigorous lab testing to ensure purity and potency. We test for cannabinoid content, terpenes, pesticides, and contaminants.",
            buttonText: "Certificate of Analysis",
            clickHandler: () => onDownload(coas[0])
        });
    }

    return (
        <PlatformMarginBox>
            <Box flex={1} position="relative">
                <img
                    src={category?.tileImage?.mediaUrl}
                    alt="Cannabis leaves"
                    style={{width: '100%', height: 'auto', borderRadius: '20px'}}
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={2}
                    sx={{borderRadius: '20px'}}
                >
                    <Typography variant="h5" sx={{color: "white"}}>

                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                {faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body1" sx={{fontSize: "1.8rem"}}>
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                {faq.answer}
                            </Typography>
                            <PlatformSpace height={10} />
                            {faq.buttonText &&
                                <LinkButton component="a" onClick={faq.clickHandler}>
                                    {faq.buttonText}
                                </LinkButton>
                            }
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </PlatformMarginBox>
    );
};

const LinkButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '1rem',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main
}));
