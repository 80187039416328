import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {Handshake, LocalHospital, Opacity, Science} from '@mui/icons-material';
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";

const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    padding: '2rem',
}));

const StyledIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '64px',
    minHeight: '64px',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
    marginRight: '1rem',
    '& .MuiSvgIcon-root': {
        fontSize: '2rem',
    },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: '0.5rem',
}));

const StyledDescription = styled(Typography)(({ theme }) => ({}));

const craftsData = [
    {
        icon: <Science />,
        title: 'Infusion',
        description: 'We infuse our edibles whenever possible for a more even distribution of cannabinoids and a smoother, palatable taste.',
    },
    {
        icon: <Opacity />,
        title: 'MCT Oil Emulsification',
        description: 'For our gummies, we emulsify our distillate in MCT oil. This practice helps your body absorb more THC.',
    },
    {
        icon: <Handshake />,
        title: 'Selected Partners',
        description: 'We collaborate with carefully chosen gourmet confectioners to create our caramels and taffy products.',
    },
    {
        icon: <LocalHospital />,
        title: 'Health Inspections',
        description: 'Similar to other consumable product facilities, ours undergoes random health inspections.',
    },
];

export default function BlogOurProcessCraftingAndIngredients() {
    return (
        <StyledBox sx={{ px: isMobile ? 1 : 10, maxWidth: isMobile ? "100vw" : '100vw', mx: isMobile ? 1 : 'auto' }}>
            <Typography variant="h5" sx={{fontFamily: "Poppins !important", fontWeight: "bold", textTransform: "uppercase"}}  gutterBottom>
                Crafting & Ingredients
            </Typography>
            <Typography variant="body1">
                We use restaurant-grade equipment in a CGMP-certified facility to produce most of our products. Through proprietary methods and top-quality ingredients, we ensure superior consistency and flavor.
            </Typography>
            <PlatformSpace height={20} />
            <Grid container spacing={4}>
                {craftsData.map((craft, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Box display="flex" alignItems="center">
                            <StyledIcon>{craft.icon}</StyledIcon>
                            <Box>
                                <StyledTitle variant="body1">{craft.title}</StyledTitle>
                                <StyledDescription variant="body3">{craft.description}</StyledDescription>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </StyledBox>
    );
};
