import React from "react";
import NumberFormat from "react-number-format";

export default function PlatformNumberFormat(props) {
    const {value} = props;

    return (
        <NumberFormat value={value}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'} />
    );
}