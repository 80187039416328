import * as React from 'react';
import {Box, Container, Typography} from "@mui/material";
import PlatformSpace from "../../../components/platform/platformSpace";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";

export default function FdaDisclaimerHome(props) {

    return (
        <PlatformMarginBox>
            <Container sx={{margin: 1}}>
                <Typography variant="h2" gutterBottom>
                    FDA Disclaimer
                </Typography>
                <Typography variant="h4" gutterBottom>
                    These statements have not been evaluated by the FDA. The products offered for sale on this site are not intended to diagnose, treat, cure, mitigate or prevent any disease and/or affect any structure or function of the human body.
                </Typography>

                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        State Legality
                    </Typography>
                    <Typography paragraph>
                        Regulations surrounding the products offered on this site are constantly changing. We ship our products into states where they are lawful.                    </Typography>
                    <Typography paragraph>
                        Additionally, we reserve the right to amend, alter, or change the list of states we do not ship to at our discretion and without notice to users of this site. Any purchaser of products offered for sale on this site assumes all risk and liability associated with the purchase, possession, and use of these products.
                    </Typography>
                </Box>
            </Container>
            <PlatformSpace height={80}/>
        </PlatformMarginBox>
    )
}