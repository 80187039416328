function getImageUrl(asset, idx) {
    if (asset && asset.images && asset.images.length > idx) {
        let mediaUrl = asset.images[idx].mediaUrl;
        // Check if the URL is from the product-image bucket
        if (mediaUrl.includes('canna-prod-product-image.s3.us-west-2.amazonaws.com')) {
            mediaUrl = mediaUrl.replace('canna-prod-product-image.s3.us-west-2.amazonaws.com', 'd1yunjbpzd5bi8.cloudfront.net/product-image');
        }
        // Check if the URL is from the cms-image bucket
        else if (mediaUrl.includes('canna-prod-cms-image.s3.us-west-2.amazonaws.com')) {
            mediaUrl = mediaUrl.replace('canna-prod-cms-image.s3.us-west-2.amazonaws.com', 'd1yunjbpzd5bi8.cloudfront.net/cms-image');
        }
        return mediaUrl;
    } else {
        return `${process.env.PUBLIC_URL}/images/comingsoon.jpg`;
    }
}

export default getImageUrl;