import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import styles from "./favoritesStyles";
import PublicLayout from "components/layout/publicLayout";
import {retrieveObject} from "components/shared/util/storageUtil";
import FavoritesContext from "./favoritesContext";
import FavoritesApi from "./favoritesApi";
import FavoritesGrid from "./favoritesGrid";
import FavoritesEmpty from "./favoritesEmpty";

const useStyles = makeStyles(styles);

export default function FavoritesHome(props) {
    const classes = useStyles();
    const [favorites, setFavorites] = useState([]);
    const user = retrieveObject("user");
    const [favoritesEmpty, setFavoritesEmpty] = useState(false);

    useEffect(() => {
        getFavorites();
    }, [])

    const getFavorites = () => {
        FavoritesApi.getFavorites((favorites) => {
            setFavorites(favorites);
            setFavoritesEmpty(!(favorites && favorites.length > 0))
        })
    }

    const onDeleteFavorite = (productId) => {
        FavoritesApi.deleteFavorite(productId, () => {
            getFavorites();
        })
    }

    return (
        <PublicLayout>
            <FavoritesContext.Provider
                value={{
                    onDeleteFavorite: onDeleteFavorite
                }}>
                {!favoritesEmpty && <FavoritesGrid favorites={favorites}/>}
                {favoritesEmpty && <FavoritesEmpty/>}
            </FavoritesContext.Provider>
        </PublicLayout>
    );
}