import * as React from 'react';
import {useContext} from 'react';
import Typography from "@mui/material/Typography";
import CheckoutContext from "./checkoutContext";

export default function CheckoutCrumbs(props) {
    const {setWizardStep} = useContext(CheckoutContext);

    return (
        <Typography variant="body2">
            Cart > Information > Shipping > Payment
        </Typography>
    )
}