import React, {useEffect, useState} from 'react';
import {CircularProgress, Container, Fade, Stack} from '@mui/material';
import CatalogShopAllApi from "./catalogShopAllApi";
import GridTilePager from "../shared/gridTilePager/gridTilePager";
import PlatformSpace from "../../../components/platform/platformSpace";
import {isMobile} from 'react-device-detect';
import {Skeleton} from "@mui/lab";

export default function CatalogShopAllTable() {
    const [allProducts, setAllProducts] = useState([]);
    const [visibleCount, setVisibleCount] = useState(2);
    const [fadeIn, setFadeIn] = useState(new Array(2).fill(true));
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        // Simulate a network delay or use actual API delay
        setTimeout(() => {
            CatalogShopAllApi.getAllVariantsWithParent((data) => {
                const filteredData = data.filter(item => item.variants && item.variants.length > 0);
                setAllProducts(filteredData);
                setFadeIn(new Array(filteredData.length).fill(false));
                setTimeout(() => {
                    setFadeIn(new Array(filteredData.length).fill(true));
                    setIsLoading(false); // Turn off loading after skeletons have been shown
                    setInitialLoad(false); // Mark initial load as complete
                }, 500); // Adjust this time to ensure skeletons are visible
            });
        }, 500); // Simulated network delay

        window.addEventListener('scroll', loadMoreItems);
        return () => window.removeEventListener('scroll', loadMoreItems);
    }, []);

    const getPreloadOffset = () => isMobile ? 2500 : window.innerHeight / 0.5;

    const loadMoreItems = () => {
        const preloadOffset = getPreloadOffset();
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - preloadOffset) {
            setVisibleCount(prevCount => {
                const newCount = prevCount + 2;
                setFadeIn(fade => [...fade, true, true]);
                return newCount;
            });
        }
    };

    return (
        <Container maxWidth="lg">
            {isLoading ? (
                // Render Skeletons while data is loading
                <Fade in={true} timeout={0}>
                    <div style={{padding: 10}}>
                        <Skeleton variant="text" width="40%" height={50} style={{ marginBottom: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={300} />
                        <PlatformSpace height={30} />
                        <Skeleton variant="text" width="40%" height={50} style={{ marginBottom: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={300} />
                        <PlatformSpace height={30} />
                        <Skeleton variant="text" width="40%" height={50} style={{ marginBottom: '2rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={300} />
                        <PlatformSpace height={30} />
                    </div>
                </Fade>
            ) : (
                allProducts.slice(0, visibleCount).map((variantsWithParents, idx) => (
                    <Fade in={fadeIn[idx]} timeout={initialLoad ? 0 : 750} key={`${idx}-${variantsWithParents.categoryHandle}`}>
                        <div>
                            <GridTilePager
                                mode="row"
                                headerVariant={isMobile ? 'h3' : 'h2'}
                                isLoading={isLoading}
                                title={variantsWithParents.categoryName}
                                products={variantsWithParents.variants}
                            />
                            <PlatformSpace height={100}/>
                        </div>
                    </Fade>
                ))
            )}
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
                <CircularProgress color="primary" size="5rem"/>
            </Stack>
            <PlatformSpace height={100}/>
        </Container>
    );
}