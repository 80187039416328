import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/styles";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";

export default function PublicHeaderTab(props) {
    const {text, selected, ...allProps} = props;
    const [hover, setHover] = useState(false);
    const history = useHistory();
    const onHoverIn = () => setHover(true);
    const onHoverOut = () => setHover(false);

    return (
        <div
            onClick={() => history.push("/catalog")}
            style={{display: "inline-block"}}
            onMouseOver={onHoverIn}
            onMouseOut={onHoverOut}
            {...allProps}
        >
            <TabButton selected={selected || hover}>
                <TabText variant="button" hover={hover} sx={{fontWeight: 700}}>
                    {text}
                </TabText>
            </TabButton>
        </div>
    );
}

const TabText = styled(Typography)(({ theme, hover }) => ({
    fontSize: isMobile ? "0.9rem" : "inherit",
    fontFamily: "Poppins, Arial, sans-serif !important",
    textTransform: "uppercase !important",
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
    "&.MuiTypography-root": {
        fontFamily: "Poppins !important",
    },
    ...(hover && {
        color: `${theme.palette.primary.main} !important`, // Change color on hover
    }),
}));

const TabButton = styled('div')(({ theme, selected }) => ({
    padding: "0 10px 0 10px",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    borderRadius: 10,
    transition: "background 0.2s ease-out, transform 0.2s ease-out",
    ...(selected && {
        background: "rgba(255, 255, 255, 0.4)"
    }),
}));
