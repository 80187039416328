import API from "api";

export default {

    getCategories(successCallback, errorCallback) {
        API.GET({
            url: `/categories`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getStore(successCallback, errorCallback) {
        API.GET({
            url: `/stores/me`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}
