import PublicLayout from "components/layout/publicLayout";
import React from "react";
import {OrderStatusContextProvider} from "./orderStatusContext";
import OrderStatusHome from "./orderStatusHome";
import {PlatformFormContextProvider} from "../../../components/platform/platformFormContext";

export default function OrderStatusIndex(props) {
    return (
        <PublicLayout>
            <PlatformFormContextProvider name="orderStatusForm">
                <OrderStatusContextProvider>
                    <OrderStatusHome/>
                </OrderStatusContextProvider>
            </PlatformFormContextProvider>
        </PublicLayout>
    );
}