import * as React from 'react';
import {isDesktop, isMobile} from "react-device-detect";
import CheckoutHomeMobile from "./checkoutHomeMobile";
import CheckoutHomeDesktop from "./checkoutHomeDesktop";

export default function CheckoutHome(props) {

    return (
        <React.Fragment>
            {isMobile && <CheckoutHomeMobile/>}
            {isDesktop && <CheckoutHomeDesktop/>}
        </React.Fragment>
    )
}