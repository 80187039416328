import React, {useContext, useState} from "react";
import PublicContext from "../publicContext";

const PublicMegaMenuContext = React.createContext();
export default PublicMegaMenuContext;

export const PublicMegaMenuContextProvider = ({children}) => {
    const [anchors, setAnchors] = useState([]);
    const {setBlurBackground} = useContext(PublicContext);

    const addAnchor = id => {
        if (!getAnchor(id)) {
            setAnchors(existingAnchors => {
                return [...existingAnchors, {id: id, anchorEl: false}]
            })
        }
    }

    const hideAnchor = (id) => {
        setBlurBackground(false);

        setAnchors(existingAnchors => {
            const index = existingAnchors.findIndex(item => item.id === id)
            return [
                ...existingAnchors.slice(0, index),
                existingAnchors[index] = {id: id, anchorEl: null},
                ...existingAnchors.slice(index + 1),
            ]
        })
    }

    const showAnchor = (id, target) => {
        setBlurBackground(true);

        setAnchors(existingAnchors => {
            const index = existingAnchors.findIndex(item => item.id === id)
            existingAnchors?.forEach(a => a.anchorEl = null);
            return [
                ...existingAnchors.slice(0, index),
                existingAnchors[index] = {id: id, anchorEl: target},
                ...existingAnchors.slice(index + 1),
            ]
        })
    }

    const getAnchor = (id) => {
        return anchors.find(item => item.id === id)?.anchorEl;
    }

    return (
        <PublicMegaMenuContext.Provider
            value={{
                addAnchor,
                showAnchor,
                hideAnchor,
                getAnchor,
            }}
        >
            <>{children}</>
        </PublicMegaMenuContext.Provider>
    );
};