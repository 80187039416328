import React, {forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import {Close, Search} from "@mui/icons-material";
import {
    Chip,
    ClickAwayListener,
    List,
    ListItemButton,
    ListSubheader,
    Paper,
    Popper,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import PlatformSpace from "./platformSpace";
import {styled} from "@mui/styles";
import {debounce} from "lodash";
import PublicContext from "../layout/publicContext";
import {isMobile} from "react-device-detect";
import getImageUrl from "../util/getImageUrl";
import {calculatePrices} from "../util/moneyUtil";
import InputAdornment from "@mui/material/InputAdornment";
import useScrollBlock from "../hook/useScrollBlock";

const PlatformSearchField2 = forwardRef(({ clickHandler, changeHandler, placeholder, fullWidth, width, height, ...allProps }, ref) => {
    const [hover, setHover] = useState(false);
    const [focused, setFocused] = useState(false);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const textFieldRef = useRef(null);
    const [textFieldWidth, setTextFieldWidth] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [maxHeight, setMaxHeight] = useState(null);
    const { setBlurBackground } = useContext(PublicContext);
    const [blockScroll, allowScroll] = useScrollBlock();
    const theme = useTheme();

    useEffect(() => {
        if (open) {
            blockScroll();  // Block scroll when the modal is open
        } else {
            allowScroll();  // Allow scroll when modal is closed
        }

        // Cleanup: ensure scrolling is allowed when component unmounts or modal closes
        return () => {
            allowScroll();
        };
    }, [open, blockScroll, allowScroll]);

    useImperativeHandle(ref, () => ({
        clearTextField: () => {
            setInputValue('');
            setOpen(false);
        }
    }));

    useLayoutEffect(() => {
        if (textFieldRef.current) {
            setTextFieldWidth(textFieldRef.current.offsetWidth);
        }
    }, []);

    useLayoutEffect(() => {
        if (anchorEl) {
            const spaceToBottom = window.innerHeight - anchorEl.getBoundingClientRect().bottom;
            setMaxHeight(`${spaceToBottom / 2}px`);
        }
    }, [anchorEl]);

    useEffect(() => {
        setBlurBackground(open);
    }, [open]);

    const onMouseOver = () => setHover(true);

    const onMouseOut = () => {
        if (!focused) {
            setHover(false);
        }
    };

    const onBlur = () => {
        setHover(false);
        setFocused(false);
    };

    const onFocus = () => setFocused(true);

    const debouncedChangeHandler = useRef(debounce((value, callback) => {
        if (typeof changeHandler === "function") {
            changeHandler(value, callback);
        }
    }, 300)).current;

    const onChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (!value.trim()) {
            setOpen(false);
            return;
        }

        debouncedChangeHandler(value, (filteredSuggestions) => {
            setSuggestions(filteredSuggestions);
            setOpen(!!filteredSuggestions.length);
        });

        setAnchorEl(textFieldRef.current);
    };

    const onClick = (product, variant) => {
        if (typeof clickHandler === "function") {
            clickHandler(product, variant);
        }

        onReset();
    };

    const onReset = () => {
        setOpen(false);
        setInputValue("");
        allowScroll();

        if (typeof changeHandler === "function") {
            changeHandler("");
        }
    };

    return (
        <div style={{ width: fullWidth ? "100%" : width }}>
            <StyledTextField
                {...allProps}
                ref={textFieldRef}
                placeholder={placeholder}
                onFocus={onFocus}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onBlur={onBlur}
                onChange={onChange}
                variant="outlined"
                value={inputValue}
                fullWidth={fullWidth}
                InputProps={{
                    startAdornment: (
                        <InputAdornmentStyled position="start">
                            <Search />
                        </InputAdornmentStyled>
                    ),
                    endAdornment: (
                        <InputAdornmentStyledEnd position="end">
                            {inputValue?.length > 0 && <Close onClick={onReset} />}
                        </InputAdornmentStyledEnd>
                    ),
                }}
            />
            <ClickAwayListener onClickAway={() => onReset()}>
                <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 9999, overflowY: 'auto', height: height || maxHeight || "inherit" }}>
                    <Paper style={{
                        width: textFieldWidth,
                        //backgroundColor: theme.palette.grey[200],
                        minWidth: 500,
                        borderRadius: isMobile ? 0 : 12,
                        marginTop: isMobile ? 30 : 15,
                        padding: 5
                    }}>
                        <PlatformSpace height={10} />
                        <List>
                            {suggestions.map((product, productIndex) => {
                                if (product.variants.length !== 0) {
                                    return (
                                        <React.Fragment key={productIndex}>
                                            <ListSubheaderStyled component="div">
                                                <Typography variant="body2">{product.name}</Typography>
                                            </ListSubheaderStyled>
                                            <PlatformSpace height={5} />
                                            {product.variants.length === 0 ? (
                                                <StyledListItemButton>No variants available</StyledListItemButton>
                                            ) : (
                                                product.variants.map((variant, variantIndex) => {
                                                    const imageUrl = getImageUrl(variant, 0);
                                                    const { percentOff } = calculatePrices(variant, 1);

                                                    return (
                                                        <StyledListItemButton onClick={() => onClick(product, variant)} key={variantIndex}>
                                                            <Image src={imageUrl} />
                                                            <div style={{ marginLeft: 7 }}>
                                                                <VariantInfo>
                                                                    <Typography variant="body4" sx={{ fontWeight: 600 }}>
                                                                        {variant.name}{percentOff && <SquareChip label={`${percentOff}% OFF`} />}
                                                                    </Typography>
                                                                </VariantInfo>
                                                                {variant.variantOptionValues && variant.variantOptionValues[0]?.value && (
                                                                    <Typography variant="caption" sx={{ color: "text.secondary" }}>
                                                                        {variant.variantOptionValues[0].value}
                                                                    </Typography>
                                                                )}
                                                            </div>
                                                        </StyledListItemButton>
                                                    );
                                                })
                                            )}
                                            {productIndex !== suggestions.length - 1 && <PlatformSpace height={20} />}
                                        </React.Fragment>
                                    );
                                }
                            })}
                            {isMobile && <PlatformSpace height={150} />}
                        </List>
                    </Paper>
                </Popper>
            </ClickAwayListener>
        </div>
    );
});

export default PlatformSearchField2;

// Styled Components
const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        padding: 0,
        background: theme.palette.grey[200], // Light grey background
        borderRadius: '20px', // Rounded corners
        width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '0 !important',
    },
    '& .MuiOutlinedInput-input': {
        padding: '16.5px 14px',
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        borderRadius: '20px', // To match the outer border radius
    },
    '& .MuiSvgIcon-root': {
        color: 'rgba(9,45,33,0.9) !important',
    }
}));

// Add extra margin for the Search icon on the left side
const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
    marginLeft: theme.spacing(1.5), // Increase margin to create more space on the left side
}));

// Add extra margin for the Close (X) icon on the right side
const InputAdornmentStyledEnd = styled(InputAdornment)(({ theme }) => ({
    marginRight: theme.spacing(1.5), // Increase margin to create more space on the right side
}));

const ListSubheaderStyled = styled(ListSubheader)({
    opacity: 0.7,
    textTransform: "uppercase",
    fontSize: "0.8rem",
    marginLeft: 20
});

const StyledListItemButton = styled(ListItemButton)({
    padding: "4px 16px",
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
});

const VariantInfo = styled('div')({
    marginBottom: "-10px",
});

const SquareChip = styled(Chip)({
    backgroundColor: "rgba(142,243,142,0.4) !important",
    borderRadius: 3,
    padding: 0,
    marginLeft: 5,
    height: '20px !important',
    '& .MuiChip-label': {
        padding: '0px 8px',
    },
});

const Image = styled('img')(({ theme }) => ({
    opacity: 0.99,
    objectFit: "cover",
    borderRadius: 4,
    pointerEvents: "none",
    width: 30,
    height: 30,
    marginRight: 7,
}));
