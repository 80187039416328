import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Box, Container, Grid} from "@mui/material";
import Typography from '@mui/material/Typography';
import styles from "./signupStyles";
import {useHistory} from "react-router-dom";
import PlatformSpace from "components/platform/platformSpace";
import {Alert} from "@mui/lab";
import SignupApi from "./signupApi";
import SignupFormStep1 from "./signupFormStep1";
import SignupContext from "./signupContext";

const useStyles = makeStyles(styles);

export default function SignupForm(props) {
    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1);
    const [form1, setForm1] = useState({});
    const [form2, setForm2] = useState({});
    const formRef1 = useRef();
    const formRef2 = useRef();

    useEffect(() => {
        setStep(1);
    },[props])

    const onNextStep = () => {
        if (formRef1.current.validate()) {
            setStep(2);
        }
    }

    const onSignup = () => {
        if (formRef1.current.validate()) {
            SignupApi.signup({
                firstName: form1.firstName,
                lastName: form1.lastName,
                email: form1.email,
                password: form1.password,
                // phone: form1.phone,
                // companyName: form2.companyName,
                // metrc: form2.metrc,
                // jobTitle: form2.jobTitle,
                // state: form2.state
            }, () => {
                history.push({
                    pathname: "/signin",
                })
            }, (error) => {
                console.log('error', error)
            })
        }
    }

    const setFormStep1 = (form1) => {
        setForm1(form1);
    }

    const setFormStep2 = (form2) => {
        setForm2(form2);
    }

    return (
        <div style={{height: "100vh"}}>
            {error && <Alert severity="error">{error}</Alert>}
            <PlatformSpace height={20}/>
            <Grid container>
                <Grid item container justifyContent="center" xs={12}>
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center">
                        <Typography variant="h4" style={{textTransform: "none", color: "black"}}>
                            Create your account
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <PlatformSpace height={40}/>
            <Container maxWidth="xs" style={{backgroundColor: "white", borderRadius: 5, padding: 20}}>
                <SignupContext.Provider value={{
                    onSignup: onSignup,
                    onNextStep: onNextStep,
                    setFormStep1: setFormStep1,
                    setFormStep2: setFormStep2
                }}>
                    {step === 1 && <SignupFormStep1 ref={formRef1}/>}
                    {/*{step === 2 && <SignupFormStep2 ref={formRef2}/>}*/}
                </SignupContext.Provider>
            </Container>
        </div>
    );
}